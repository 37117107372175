import { axiosClient } from "../axios"; // Ensure the path is correct

export const getAllCustomers = async ({ params }) => {
  try {
    const { data } = await axiosClient.get(`customers`, {
      params,
    }); // Append serialized authParams to the URL
    return data;
  } catch (error) {
    console.log("Error fetching customers: ", error); // Debugging log for errors
    throw error.response ? error.response.data : new Error("Network Error");
  }
};
// /wp-json/wc/v3/customers/<id>
export const updateCustomers = async ({ data }) => {
  const { id } = data;
  try {
    const response = await axiosClient.put(`customers/${id}`, data);
    return response.data;
  } catch (error) {
    console.log("Error updating customers: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const addCustomer = async ({ data }) => {
  try {
    const response = await axiosClient.post("customers", data);
    return response.data;
  } catch (error) {
    console.log("Error add customers: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const deleteCustomer = async ({ id }) => {
  try {
    const response = await axiosClient.delete(`customers/${id}`, {
      params: { force: true },
    });
    return response.data;
  } catch (error) {
    console.log("Error deleting customer: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const deleteBatchCustomers = async ({ ids }) => {
  try {
    const response = await axiosClient.post(`customers/batch`, { delete: ids });
    return response.data;
  } catch (error) {
    console.log("Error deleting batch customers: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getCustomer = async ({ id }) => {
  try {
    const { data } = await axiosClient.get(`/customers/${id}`);
    return data;
  } catch (error) {
    console.error("Error fetching customer: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const CUSTOMER_API = {
  GET_ALL_CUSTOMERS: {
    KEY: "GET_ALL_CUSTOMERS",
    FN: getAllCustomers,
  },
  UPDATE_CUSTOMERS: {
    KEY: "UPDATE_CUSTOMERS",
    FN: updateCustomers,
  },
  ADD_CUSTOMERS: {
    KEY: "ADD_CUSTOMERS",
    FN: addCustomer,
  },
  DELETE_CUSTOMERS: {
    KEY: "DELETE_CUSTOMERS",
    FN: deleteCustomer,
  },
  DELETE_BATCH_CUSTOMERS: {
    KEY: "DELETE_BATCH_CUSTOMERS",
    FN: deleteBatchCustomers,
  },
  GET_CUSTOMER: {
    KEY: "GET_CUSTOMER",
    FN: getCustomer,
  },
};