import { RadioGroup, RadioGroupItem } from "components/ui/radio-group";

const Radio = ({ options, value, onChange }) => {
  // Check if options is an object instead of an array
  const optionsArray = Array.isArray(options)
    ? options
    : Object.entries(options).map(([value, label]) => ({ value, label }));

  return (
    <div className="space-y-3">
      <RadioGroup defaultValue={value} onValueChange={onChange}>
        {optionsArray.map((option) => (
          <div key={option.value} className="flex items-center space-x-2">
            <RadioGroupItem value={option.value} id={option.value} />
            <label htmlFor={option.value}>{option.label}</label>
          </div>
        ))}
      </RadioGroup>
    </div>
  );
};

export default Radio;
