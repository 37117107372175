import React from 'react';
import { CardHeader, CardTitle } from "components/ui/card";
import { MoveRight, Tag, ExternalLink } from 'lucide-react';
import OrderActionsDropdown from './OrderActionsDropdown';
import { BASE_URL } from 'api/axios';

const OrderHeader = ({ order, isEdit, deleteMutate, deleteLoading }) => {
    if (!order) return null;

    const wooCommerceOrderUrl = `${BASE_URL}/wp-admin/admin.php?page=wc-orders&action=edit&id=${order.id}`;

    return (
        <CardHeader className='mt-0 pt-0 pl-0 pr-4'>
            <div className="flex justify-between items-start">
                <div className="flex items-center gap-2">
                    <CardTitle className="text-2xl font-bold">Order from {order.billing.first_name}</CardTitle>
                    <a 
                        href={wooCommerceOrderUrl} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="text-gray-900 hover:text-gray-700"
                        title="Open in WooCommerce"
                    >
                        <ExternalLink className="h-5 w-5" />
                    </a>
                </div>
                <div className="text-sm text-right">
                    <p>Ordered on {new Date(order.date_created).toLocaleDateString()}</p>
                    {order.shipping
                        ?  <p>Ship to {order.shipping.city}, {order.shipping.country}</p>
                        : <p>Virtual order</p>
                    }
                </div>
            </div>
            <div className="text-sm flex flex-col pb-2">
                <p>n°{order.id} via {order.payment_method || 'Not specified'}</p>
                <p className='flex items-center gap-2'>
                    <span>{order.line_items.length} item(s)</span>
                    <MoveRight className='h-4 w-4' />
                    <span>{order.currency_symbol}{parseFloat(order.total).toFixed(2)}</span>
                </p>
                {order.coupon_code && <p className='flex items-center'><Tag className='h-4 w-4 mr-2' /> {order.coupon_code}</p>}
            </div>
            <div className="flex gap-2 mt-4">
                <OrderActionsDropdown 
                    order={order} 
                    isEdit={isEdit} 
                    deleteMutate={deleteMutate} 
                    deleteLoading={deleteLoading} 
                />
            </div>
        </CardHeader>
    );
};

export default OrderHeader;