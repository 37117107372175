import * as React from "react";
import { components } from "react-select";
import { CaretSortIcon } from "@radix-ui/react-icons";

export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretSortIcon className={"h-4 w-4 opacity-50"} />
    </components.DropdownIndicator>
  );
};
