import React from "react";
import { Tabs, TabsList, TabsTrigger } from "components/ui/tabs";

export function ReusableTabs({ value, onChange }) {
  return (
    <Tabs
      value={value === true ? true : false}
      onValueChange={onChange}
      className="w-[200px] flex-shrink-0"
    >
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger value={false}>No</TabsTrigger>
        <TabsTrigger value={true}>Yes</TabsTrigger>
      </TabsList>
    </Tabs>
  );
}
