import React from "react";
import { Avatar, AvatarFallback } from "components/ui/avatar";
import { format } from "date-fns";
import { countriesOptions } from "constant";
import GenericTable from "components/generic-table";

export default function CustomerTable({
  data,
  isLoading,
  isError,
  error,
  sorting,
  setSorting,
  columnFilters,
  setColumnFilters,
  columnVisibility,
  setColumnVisibility,
  rowSelection,
  setRowSelection,
  page,
  per_page,
  handleEditClick,
  selectedRowIds,
  setSelectedRowIds,
  setPage,
  total,
}) {
  const columns = [
    {
      accessorKey: "customer",
      header: "Customer",
      cell: ({ row }) => (
        <div className="inline-flex flex-row gap-2 items-center">
          <div className=" flex place-content-center items-center justify-center ">
            <Avatar>
              <AvatarFallback>
                {row.original.first_name.charAt(0)}
              </AvatarFallback>
            </Avatar>

            <span className="uppercase text-sm">
              {row.getValue("customer")}
            </span>
          </div>
          <div>
            <div>
              {row.original.first_name} {row.original.last_name}
            </div>
            <div>{row.original.username}</div>
          </div>
        </div>
      ),
    },
    {
      accessorKey: "date_created",
      header: "Last seen",
      cell: ({ row }) => {
        const date = new Date(row.getValue("date_created"));
        return (
          <div className="text-zinc-500 text-sm">
            {format(date, "MMM d, h:mm a")}
          </div>
        );
      },
    },

    {
      accessorKey: "email",
      header: "Email",
      cell: ({ row }) => <div>{row.getValue("email")}</div>,
    },
    {
      accessorKey: "billing.country",
      header: "Country",
      cell: ({ row }) => (
        <div>
          {
            countriesOptions.find(
              (country) => country.value === row.original.billing.country
            )?.label
          }
        </div>
      ),
    },
  ];

  return (
    <GenericTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isError={isError}
      error={error}
      sorting={sorting}
      setSorting={setSorting}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
      columnVisibility={columnVisibility}
      setColumnVisibility={setColumnVisibility}
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
      page={page}
      per_page={per_page}
      handleEditClick={handleEditClick}
      selectedRowIds={selectedRowIds}
      setSelectedRowIds={setSelectedRowIds}
      setPage={setPage}
      total={total}
    />
  );
}
