import React from "react";
import { Button } from "components/ui/button";
import SearchInput from "components/search-input";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import FilterDropdown from "./filterDropdown";
import { ORDER_API } from "api/orders";

export default function SearchAndActions({
  selectedRowIds,
  loading,
  setSearch,
  dropdownState,
  filters,
  isLoading,
  mutate,
  setFilters,
}) {
  const cache = useQueryClient();

  const handleInputChange = (value) => {
    //console.log("Input changed:", value);
    setSearch(value);
  };

  const handleInputFocus = () => {
    //console.log("Input focused");
  };

  const handleInputBlur = () => {
   // console.log("Input blurred");
  };

  const deleteBatchMutation = useMutation({
    mutationFn: ORDER_API.DELETE_BATCH_ORDERS.FN,
    onSuccess: () => {
      cache.invalidateQueries([ORDER_API.DELETE_BATCH_ORDERS.KEY]);
    },
  });

  return (
    <div className="flex items-center py-2 px-4">
      <SearchInput
        columnName="code"
        placeholder="Search orders"
        onInputChange={handleInputChange}
        onInputFocus={handleInputFocus}
        onInputBlur={handleInputBlur}
		className="border border-zinc-200 rounded-md"
      />
      <div className="ml-auto flex gap-3">
        <FilterDropdown
          dropdownState={dropdownState}
          filters={filters}
          setFilters={setFilters}
        />
        {selectedRowIds.length > 0 && (
          <Popover>
            <PopoverTrigger>
              <Button variant="outline">
                <span>Bulk options</span>
              </Button>
            </PopoverTrigger>
            <PopoverContent className="flex justify-center items-center">
              <Button
                variant="destructive"
                onClick={() => {
                  deleteBatchMutation.mutate({ ids: selectedRowIds });
                }}
              >
                {deleteBatchMutation.isLoading ? "Deleting..." : "Delete"}
              </Button>
            </PopoverContent>
          </Popover>
        )}
        <Button
          onClick={() => {
            mutate({});
          }}
          className={loading ? "cursor-not-allowed" : ""}
          disabled={loading}
        >
          {isLoading ? "Adding..." : "Add Order"}
        </Button>
      </div>
    </div>
  );
}
