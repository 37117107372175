import { axiosClient } from 'api/axios';

export const getShippingZones = async () => {
	try {
		const { data } = await axiosClient.get('/shipping/zones');
		return data;
	} catch (error) {
		console.error('Error fetching shipping zones:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const getZoneLocations = async (zoneId) => {
	try {
		const { data } = await axiosClient.get(
			`/shipping/zones/${zoneId}/locations`
		);
		return data;
	} catch (error) {
		console.error('Error fetching zone locations:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const getZoneMethods = async (zoneId) => {
	try {
		const { data } = await axiosClient.get(
			`/shipping/zones/${zoneId}/methods`
		);
		return data;
	} catch (error) {
		console.error('Error fetching zone methods:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const createShippingZone = async (zoneData) => {
	try {
		const { data } = await axiosClient.post('/shipping/zones', zoneData);
		return data;
	} catch (error) {
		console.error('Error creating shipping zone:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const updateShippingZone = async (zoneId, zoneData) => {
	try {
		const { data } = await axiosClient.put(
			`/shipping/zones/${zoneId}`,
			zoneData
		);
		return data;
	} catch (error) {
		console.error('Error updating shipping zone:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const deleteShippingZone = async (zoneId) => {
	try {
		const { data } = await axiosClient.delete(`/shipping/zones/${zoneId}`, {
			params: { force: true },
		});
		return data;
	} catch (error) {
		console.error('Error deleting shipping zone:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const updateZoneLocations = async (zoneId, locations) => {
	try {
		const { data } = await axiosClient.put(
			`/shipping/zones/${zoneId}/locations`,
			locations
		);
		return data;
	} catch (error) {
		console.error('Error updating zone locations:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const createShippingMethod = async (zoneId, methodData) => {
	try {
		const { data } = await axiosClient.post(
			`/shipping/zones/${zoneId}/methods`,
			methodData
		);
		return data;
	} catch (error) {
		console.error('Error creating shipping method:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const updateShippingMethod = async (zoneId, methodId, methodData) => {
	try {
		const { data } = await axiosClient.put(
			`/shipping/zones/${zoneId}/methods/${methodId}`,
			methodData
		);
		return data;
	} catch (error) {
		console.error('Error updating shipping method:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const deleteShippingMethod = async (zoneId, methodId) => {
	try {
		const { data } = await axiosClient.delete(
			`/shipping/zones/${zoneId}/methods/${methodId}`,
			{
				params: { force: true },
			}
		);
		return data;
	} catch (error) {
		console.error('Error deleting shipping method:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const SHIPPING_API = {
	GET_SHIPPING_ZONES: {
		KEY: 'GET_SHIPPING_ZONES',
		FN: getShippingZones,
	},
	GET_ZONE_LOCATIONS: {
		KEY: 'GET_ZONE_LOCATIONS',
		FN: getZoneLocations,
	},
	GET_ZONE_METHODS: {
		KEY: 'GET_ZONE_METHODS',
		FN: getZoneMethods,
	},
	CREATE_SHIPPING_ZONE: {
		KEY: 'CREATE_SHIPPING_ZONE',
		FN: createShippingZone,
	},
	UPDATE_SHIPPING_ZONE: {
		KEY: 'UPDATE_SHIPPING_ZONE',
		FN: updateShippingZone,
	},
	DELETE_SHIPPING_ZONE: {
		KEY: 'DELETE_SHIPPING_ZONE',
		FN: deleteShippingZone,
	},
	UPDATE_ZONE_LOCATIONS: {
		KEY: 'UPDATE_ZONE_LOCATIONS',
		FN: updateZoneLocations,
	},
	CREATE_SHIPPING_METHOD: {
		KEY: 'CREATE_SHIPPING_METHOD',
		FN: createShippingMethod,
	},
	UPDATE_SHIPPING_METHOD: {
		KEY: 'UPDATE_SHIPPING_METHOD',
		FN: updateShippingMethod,
	},
	DELETE_SHIPPING_METHOD: {
		KEY: 'DELETE_SHIPPING_METHOD',
		FN: deleteShippingMethod,
	},
};
