import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { CATEGORIE_API } from 'api/categories';
import { useToast } from 'components/ui/use-toast';
import { ScrollArea } from 'components/ui/scroll-area';
import { Trash2, Edit, CornerDownRight } from 'lucide-react';
import CategoryDialog from './CategoryDialog';
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogCancel, AlertDialogAction } from 'components/ui/alert-dialog';

const ManageCategoriesDialog = ({ isOpen, onClose }) => {
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const [categories, setCategories] = useState([]);
  const [editingCategory, setEditingCategory] = useState(null);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  const { data, isLoading } = useQuery({
    queryKey: [CATEGORIE_API.GET_ALL_CATEGORIES.KEY],
    queryFn: () => CATEGORIE_API.GET_ALL_CATEGORIES.FN({ params: { per_page: 100 } }),
    staleTime: 0, // This ensures the query always refetches when the component mounts
  });

  useEffect(() => {
    if (data) {
      setCategories(buildCategoryTree(data));
    }
  }, [data]);

  const deleteCategoryMutation = useMutation({
    mutationFn: CATEGORIE_API.DELETE_CATEGORIES.FN,
    onSuccess: () => {
      queryClient.invalidateQueries([CATEGORIE_API.GET_ALL_CATEGORIES.KEY]);
      toast({
        title: 'Success',
        description: 'Category deleted successfully.',
      });
    },
    onError: (error) => {
      toast({
        title: 'Error',
        description: 'Failed to delete category.',
        variant: 'destructive',
      });
    },
  });

  const buildCategoryTree = (categories) => {
    const categoryMap = {};
    categories.forEach(category => {
      categoryMap[category.id] = { ...category, children: [] };
    });

    const rootCategories = [];
    categories.forEach(category => {
      if (category.parent === 0) {
        rootCategories.push(categoryMap[category.id]);
      } else {
        categoryMap[category.parent].children.push(categoryMap[category.id]);
      }
    });

    return rootCategories;
  };

  const handleDelete = (category) => {
    setCategoryToDelete(category);
  };

  const confirmDelete = () => {
    if (categoryToDelete) {
      deleteCategoryMutation.mutate({ id: categoryToDelete.id });
      setCategoryToDelete(null);
    }
  };

  const renderCategoryTree = (categories, depth = 0) => {
    return categories.map((category) => (
      <li key={category.id} className="mb-2">
        <div className="flex items-center space-x-2 p-2 rounded-md hover:bg-gray-100" style={{ marginLeft: `${depth * 20}px` }}>
          {depth > 0 && <CornerDownRight className="h-4 w-4 text-gray-400" />}
          <span className="flex-grow">
            {category.name} 
            <span className="text-sm text-gray-500 ml-2">({category.count} products)</span>
          </span>
          <Button
            onClick={() => setEditingCategory(category)}
            variant="ghost"
            size="icon"
            className="h-8 w-8"
          >
            <Edit className="h-4 w-4" />
          </Button>
          <Button
            onClick={() => handleDelete(category)}
            variant="ghost"
            size="icon"
            className="h-8 w-8"
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </div>
        {category.children.length > 0 && (
          <ul>{renderCategoryTree(category.children, depth + 1)}</ul>
        )}
      </li>
    ));
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-xl">
        <DialogHeader>
          <DialogTitle>Manage Categories</DialogTitle>
          <DialogDescription>
            Categories are used to group products. You can edit or delete them.
          </DialogDescription>
        </DialogHeader>
        {isLoading ? (
          <div>Loading categories...</div>
        ) : (
          <ScrollArea className="h-[400px] pr-4">
            <ul>{renderCategoryTree(categories)}</ul>
          </ScrollArea>
        )}
      </DialogContent>
      {editingCategory && (
        <CategoryDialog
          isOpen={!!editingCategory}
          onClose={() => setEditingCategory(null)}
          category={editingCategory}
          allCategories={data || []}
        />
      )}
      {categoryToDelete && (
        <AlertDialog open={!!categoryToDelete} onOpenChange={() => setCategoryToDelete(null)}>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>Confirm Category Deletion?</AlertDialogTitle>
              <AlertDialogDescription>
                Deleting the "{categoryToDelete.name}"? category cannot be undone. This will permanently delete the category and remove it from the store.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel onClick={() => setCategoryToDelete(null)}>Cancel</AlertDialogCancel>
              <AlertDialogAction onClick={confirmDelete}>Delete</AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      )}
    </Dialog>
  );
};

export default ManageCategoriesDialog;