import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { CATEGORIE_API } from "api/categories";
import { useDisclosure } from "hooks/useDisclosure";
import SearchAndActions from "./_ui/searchAction";
import CategorieTable from "./_ui/categorieTable";
import CategorieSheet from "./_ui/categorieSheet";
import { addDays } from "date-fns";

export default function Categories() {
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [loading, setLoading] = useState(false); // eslint-disable-line

  const [page, setPage] = useState(1);
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [selectedCategorie, setSelectedCategorie] = useState(null);
  const [filters, setFilters] = useState({
    orderBy: "name",
    order: "asc",
    per_page: 10,
    dateRange: {
      from: new Date(),
      to: addDays(new Date(), 30),
    },
  });

  const [search, setSearch] = useState("");

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const newCategorieState = useDisclosure();
  const dropdownState = useDisclosure();

  const dateRange = `${filters.dateRange.from.toISOString()},${filters.dateRange.to.toISOString()}`;

  const categorieQuery = useQuery({
    queryFn: () =>
      CATEGORIE_API.GET_ALL_CATEGORIES.FN({
        params: { page, search, ...filters, dateRange },
      }),
    queryKey: [CATEGORIE_API.GET_ALL_CATEGORIES.KEY, page, search, filters],
  });
  //console.log("selectedCategories", selectedCategorie);

  const handleEditClick = (categorie) => {
    setSelectedCategorie(categorie);
    setIsSheetOpen(true);
  };

  const handleSheetClose = () => {
    setIsSheetOpen(false);
    setSelectedCategorie(null);
  };

  return (
    <div className="w-full">
      <SearchAndActions
        newCategorieState={newCategorieState}
        loading={loading}
        selectedRowIds={selectedRowIds}
        setSearch={setSearch}
        dropdownState={dropdownState}
        setFilters={setFilters}
        filters={filters}
      />

      <CategorieTable
        data={categorieQuery.data || []}
        isLoading={categorieQuery.isLoading}
        isError={categorieQuery.isError}
        error={categorieQuery.error}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        page={page}
        per_page={filters.per_page}
        handleEditClick={handleEditClick}
        setSelectedRowIds={setSelectedRowIds}
        selectedRowIds={selectedRowIds}
        setPage={setPage}
        total={categorieQuery.data?.length || 0}
      />
      <CategorieSheet
        isOpen={isSheetOpen}
        onClose={handleSheetClose}
        category={selectedCategorie}
        isEdit={true}
        page={page}
      />
      <CategorieSheet
        isOpen={newCategorieState.isOpen}
        onClose={newCategorieState.onClose}
        isEdit={false}
        page={page}
      />
    </div>
  );
}
