import * as Yup from "yup";

import { Input } from "components/ui/input";
import { countriesOptions } from "constant";

export const CustomerSchema = Yup.object().shape({
  first_name: Yup.string().required("Required"),
  last_name: Yup.string().required("Required"),
  username: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  billing: Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    company: Yup.string(),
    address_1: Yup.string().required("Required"),
    address_2: Yup.string(),
    city: Yup.string().required("Required"),
    postcode: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email").required("Required"),
    phone: Yup.string().required("Required"),
  }),
  shipping: Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    company: Yup.string(),
    address_1: Yup.string().required("Required"),
    address_2: Yup.string(),
    city: Yup.string().required("Required"),
    postcode: Yup.string().required("Required"),
    country: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    phone: Yup.string(),
  }),
});

export const sections = [
  {
    legend: "Billing Information",
    fields: [
      {
        name: "billing.first_name",
        label: "First Name",
        type: "text",
      },
      {
        name: "billing.last_name",
        label: "Last Name",
        type: "text",
      },
      {
        name: "billing.company",
        label: "Company",
        type: "text",
      },
      {
        name: "billing.address_1",
        label: "Address 1",
        type: "text",
      },
      {
        name: "billing.address_2",
        label: "Address 2",
        type: "text",
      },
      { name: "billing.city", label: "City", type: "text", component: Input },
      {
        name: "billing.postcode",
        label: "Postcode",
        type: "text",
      },
      {
        name: "billing.country",
        label: "Country",
        type: "select",
        options: countriesOptions,
      },
      { name: "billing.state", label: "State", type: "text", component: Input },
      {
        name: "billing.email",
        label: "Email",
        type: "email",
      },
      { name: "billing.phone", label: "Phone", type: "tel", component: Input },
    ],
  },
  {
    legend: "Shipping Information",
    fields: [
      {
        name: "shipping.first_name",
        label: "First Name",
        type: "text",
      },
      {
        name: "shipping.last_name",
        label: "Last Name",
        type: "text",
      },
      {
        name: "shipping.company",
        label: "Company",
        type: "text",
      },
      {
        name: "shipping.address_1",
        label: "Address 1",
        type: "text",
      },
      {
        name: "shipping.address_2",
        label: "Address 2",
        type: "text",
      },
      { name: "shipping.city", label: "City", type: "text", component: Input },
      {
        name: "shipping.postcode",
        label: "Postcode",
        type: "text",
      },
      {
        name: "shipping.country",
        label: "Country",
        type: "select",
        options: countriesOptions,
      },
      {
        name: "shipping.state",
        label: "State",
        type: "text",
      },
      { name: "shipping.phone", label: "Phone", type: "tel", component: Input },
    ],
  },
];

export const userFields = [
  {
    name: "first_name",
    label: "First Name",
    type: "text",
  },
  {
    name: "last_name",
    label: "Last Name",
    type: "text",
  },
  {
    name: "username",
    label: "Username",
    type: "text",
  },
  {
    name: "email",
    label: "Email",
    type: "email",
  },
];
