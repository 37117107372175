import { axiosClient } from "../axios"; // Ensure the path is correct

export const getAllOrders = async ({ params }) => {
  try {
    const { data } = await axiosClient.get(`orders`, { params }); // Append serialized authParams to the URL
    return data;
  } catch (error) {
    console.log("Error fetching orders: ", error); // Debugging log for errors
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getRecentOrders = async ({ params }) => {
  try {
    const { data } = await axiosClient.get(`orders`,
		{ params: {
			status: "processing",
			per_page: 5,
			page: 1,
			orderby: "date",
			order: "desc",
		 } });
    return data;
  } catch (error) {
    console.log("Error fetching recent orders: ", error); // Debugging log for errors
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const addOrder = async ({ data }) => {
  try {
    const response = await axiosClient.post("orders", data);
    return response.data;
  } catch (error) {
    console.log("Error add orders: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getOrderById = async ({ id }) => {
  try {
    const { data } = await axiosClient.get(`orders/${id}`); // Append serialized authParams to the URL
    return data;
  } catch (error) {
    console.log("Error fetching order: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const updateOrderById = async ({ id, updateData }) => {
  try {
    const { data } = await axiosClient.patch(`orders/${id}`, updateData);
    return data;
  } catch (error) {
    console.log("Error updating order: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const deleteOrder = async ({ id }) => {
  try {
    const response = await axiosClient.delete(`orders/${id}`, {
      params: { force: true },
    });
    return response.data;
  } catch (error) {
    console.log("Error deleting order: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const deleteBatchOrders = async ({ ids }) => {
  try {
    const response = await axiosClient.post(`orders/batch`, { delete: ids });
    return response.data;
  } catch (error) {
    console.log("Error deleting batch orders: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};
export const createRefund = async ({ orderId, refundData }) => {
  try {
    const response = await axiosClient.post(
      `orders/${orderId}/refunds`,
      refundData
    );
    return response.data;
  } catch (error) {
    console.log("Error creating refund: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};
export const getAllOrderNotes = async ({ orderId }) => {
  try {
    const { data } = await axiosClient.get(`orders/${orderId}/notes`);
    return data;
  } catch (error) {
    console.log("Error fetching order notes: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const addOrderNote = async ({ orderId, noteData }) => {
  try {
    const response = await axiosClient.post(
      `orders/${orderId}/notes`,
      noteData
    );
    return response.data;
  } catch (error) {
    console.log("Error adding order note: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const deleteOrderNote = async ({ orderId, noteId }) => {
  try {
    const response = await axiosClient.delete(
      `orders/${orderId}/notes/${noteId}`,
      {
        params: { force: true },
      }
    );
    return response.data;
  } catch (error) {
    console.log("Error deleting order note: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getProcessingOrdersCount = async () => {
	try {
	  let page = 1;
	  let totalCount = 0;
	  let hasMore = true;
  
	  while (hasMore) {
		const response = await axiosClient.get(`orders`, {
		  params: {
			status: "processing",
			per_page: 100,
			page: page,
		  },
		});
  
		const orders = response.data;
		totalCount += orders.length;
  
		const totalPages = parseInt(response.headers['x-wp-totalpages'], 10);
		hasMore = page < totalPages;
		page++;
	  }
  
	  return totalCount;
	} catch (error) {
	  console.log("Error fetching pending orders: ", error);
	  throw error.response ? error.response.data : new Error("Network Error");
	}
  };

export const ORDER_API = {
  GET_ALL_ORDERS: {
    KEY: "GET_ALL_ORDERS",
    FN: getAllOrders,
  },
  GET_RECENT_ORDERS: {
    KEY: "GET_RECENT_ORDERS",
    FN: getRecentOrders,
  },
  GET_ORDER_BY_ID: {
    KEY: "GET_ORDER_BY_ID",
    FN: getOrderById,
  },
  ADD_ORDER: {
    KEY: "ADD_ORDER",
    FN: addOrder,
  },
  UPDATE_ORDER_BY_ID: {
    KEY: "UPDATE_ORDER_BY_ID",
    FN: updateOrderById,
  },

  DELETE_ORDER: {
    KEY: "DELETE_ORDER",
    FN: deleteOrder,
  },
  DELETE_BATCH_ORDERS: {
    KEY: "DELETE_BATCH_ORDERS",
    FN: deleteBatchOrders,
  },
  CREATE_REFUND: {
    KEY: "CREATE_REFUND",
    FN: createRefund,
  },
  GET_ALL_ORDER_NOTES: {
    KEY: "GET_ALL_ORDER_NOTES",
    FN: getAllOrderNotes,
  },
  ADD_ORDER_NOTE: {
    KEY: "ADD_ORDER_NOTE",
    FN: addOrderNote,
  },
  DELETE_ORDER_NOTE: {
    KEY: "DELETE_ORDER_NOTE",
    FN: deleteOrderNote,
  },
  GET_PROCESSING_ORDERS_COUNT: {
    KEY: "GET_PROCESSING_ORDERS_COUNT",
    FN: getProcessingOrdersCount,
  },
};
