import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { useDisclosure } from "hooks/useDisclosure";
import SearchAndActions from "./_ui/searchAction";
import ProductTable from "./_ui/productTable";
import { PRODUCT_API } from "api/product";
import { useNavigate } from "react-router-dom";

export default function Products() {
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [rowSelection, setRowSelection] = useState({});
  const [loading] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const [filters, setFilters] = useState({
    orderBy: undefined,
    order: undefined,
    per_page: 10,
    dateRange: {
      from: undefined,
      to: undefined,
    },
    status: undefined,
  });

  const dateRange = filters.dateRange?.from
    ? `${filters.dateRange.from.toISOString()},${filters.dateRange.to.toISOString()}`
    : undefined;

  const newProductState = useDisclosure();
  const dropdownState = useDisclosure();

  const productsQuery = useQuery({
    queryFn: () =>
      PRODUCT_API.GET_ALL_PRODUCTS.FN({
        params: { page, search, ...filters, dateRange },
      }),
    queryKey: [PRODUCT_API.GET_ALL_PRODUCTS.KEY, page, search, filters],
  });

  const handleEditClick = (product) => {
    navigate(`/products/update/${product.id}`);
  };

  return (
    <div className="w-full">
      <SearchAndActions
        newProductState={newProductState}
        loading={loading}
        selectedRowIds={selectedRowIds}
        setSearch={setSearch}
        dropdownState={dropdownState}
        filters={filters}
        setFilters={setFilters}
		refetchProducts={productsQuery.refetch}
      />

      <ProductTable
        data={productsQuery.data || []}
        refetchProducts={productsQuery.refetch}
        isLoading={productsQuery.isLoading}
        isError={productsQuery.isError}
        error={productsQuery.error}
        sorting={sorting}
        setSorting={setSorting}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        page={page}
        per_page={filters.per_page}
        handleEditClick={handleEditClick}
        setSelectedRowIds={setSelectedRowIds}
        selectedRowIds={selectedRowIds}
        setPage={setPage}
        total={productsQuery.data?.length || 0}
      />
    </div>
  );
}
