import React, { useState, useEffect } from "react";
import useDebounce from "./ui/useDebounce";
import { Search, X } from "lucide-react";

const SearchInput = ({
  table,
  columnName = "number",
  placeholder = "Search",
  onInputChange,
  onInputFocus,
  onInputBlur,
  className
}) => {
  const [filterValue, setFilterValue] = useState(''); 
  const [isFocused, setIsFocused] = useState(false); // eslint-disable-line

  const debouncedFilterValue = useDebounce(filterValue, 300);

  useEffect(() => {
    if (table && table.getColumn) {
      const column = table.getColumn(columnName);
      if (column && column.setFilterValue) {
        column.setFilterValue(debouncedFilterValue);
      }
    }
    if (onInputChange) {
      onInputChange(debouncedFilterValue);
    }
  }, [debouncedFilterValue, table, columnName, onInputChange]);

  const handleChange = (event) => {
    setFilterValue(event.target.value);
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (onInputFocus) {
      onInputFocus();
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (onInputBlur) {
      onInputBlur();
    }
  };

  return (
    <div className={`relative max-w-[50%] ${className}`}>
      <Search
        className="text-zinc-400 absolute left-4 top-1/2 translate-y-[-50%] text-md"
        style={{
          height: "1em",
          width: "1em",
          minHeight: "1em",
          minWidth: "1em",
        }}
      />
      <input
        type="text"
        className="py-2 px-0 pl-16 bg-transparent border border-transparent w-full items-center transition-all outline-none focus:outline-none"
        placeholder={placeholder}
        value={filterValue}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {filterValue && (
		<button
			onClick={() => setFilterValue("")}
			className="p-1 rounded-lg border border-zinc-200 dark:border-zinc-700 absolute right-2 top-1/2 translate-y-[-50%]"
		>
			<X className="text-zinc-400 h-4 w-4" />
		</button>
      )}
    </div>
  );
};

export default SearchInput;
