import * as React from 'react';
import { Field } from 'formik';
import { Label } from 'components/ui/label';
import { Textarea } from 'components/ui/textarea';

const FormTextarea = ({ label, name }) => {
	return (
		<Field name={name}>
			{({ field, meta }) => {
				return (
					<div>
						<div className='flex  flex-row gap-3 items-center'>
							{label && (
								<Label className='w-[300px]'>{label}</Label>
							)}
							<Textarea {...field} />
						</div>
						{meta.touched && meta.error ? (
							<div className='text-red-500 text-sm'>
								{meta.error}
							</div>
						) : null}
					</div>
				);
			}}
		</Field>
	);
};
export default FormTextarea;
