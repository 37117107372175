import { Formik, Form } from 'formik';
import { FormDateRangePicker } from 'components/form/form-date-range-picker';
import { subDays, subMonths, startOfDay } from 'date-fns';
import { useState } from 'react';
import { StatsOverview } from './_ui/StatsOverview';
import { SalesPerformanceChart } from './_ui/SalesPerformanceChart';
import SalesTableCard from './_ui/SalesTableCard';
import { useQuery } from '@tanstack/react-query';
import { DASHBOARD_API } from 'api/dashboard';
import { Button } from 'components/ui/button';
import { useToast } from 'components/ui/use-toast';
import { Tabs, TabsList, TabsTrigger } from 'components/ui/tabs';
import { LoaderCircle } from 'lucide-react';
import NetworkError from 'components/ui/networkError';

const Dashboard = () => {
	const { toast } = useToast();
	const [interval, setInterval] = useState('day');
	const [activeTab, setActiveTab] = useState('30d');

	const predefinedRanges = {
		'12m': { from: subMonths(new Date(), 12), to: new Date() },
		'30d': { from: subDays(new Date(), 30), to: new Date() },
		'7d': { from: subDays(new Date(), 7), to: new Date() },
		'24h': { from: subDays(new Date(), 1), to: new Date() },
	};

	const initialDateRange = predefinedRanges['30d'];
	const [dateRange, setDateRange] = useState(initialDateRange);

	const { data, isLoading, error } = useQuery({
		queryKey: [
			DASHBOARD_API.GET_STATS.KEY,
			{
				after: startOfDay(dateRange.from).toISOString(),
				before: dateRange.to.toISOString(),
				interval,
			},
		],
		queryFn: DASHBOARD_API.GET_STATS.FN,
	});

	const {
		data: leaderboardsData,
		isLoading: isLeaderboardsLoading,
		error: leaderboardsError,
	} = useQuery({
		queryKey: [
			DASHBOARD_API.GET_LEADERBOARDS.KEY,
			{
				after: startOfDay(dateRange.from).toISOString(),
				before: dateRange.to.toISOString(),
			},
		],
		queryFn: DASHBOARD_API.GET_LEADERBOARDS.FN,
	});

	const handleTabChange = (value) => {
		setActiveTab(value);
		if (value !== 'custom') {
			setDateRange(predefinedRanges[value]);
		}
	};

	if (error || leaderboardsError) {
		return (
			<NetworkError
				message={ error || leaderboardsError.message }
			/>
		);
	}

	return (
		<div className='p-4'>
			<div className='flex justify-start items-center gap-0'>
				<Tabs
					value={activeTab}
					onValueChange={handleTabChange}
					className='w-[400px]'>
					<TabsList>
						<TabsTrigger value='12m'>12 months</TabsTrigger>
						<TabsTrigger value='30d'>30 days</TabsTrigger>
						<TabsTrigger value='7d'>7 days</TabsTrigger>
						<TabsTrigger value='24h'>24 hours</TabsTrigger>
						<TabsTrigger value='custom'>Custom</TabsTrigger>
					</TabsList>
				</Tabs>
				{activeTab === 'custom' && (
					<Formik
						initialValues={{ filter: dateRange }}
						enableReinitialize
						onSubmit={(values) => {
							if (values.filter.from && values.filter.to) {
								setDateRange(values.filter);
							} else {
								toast({
									title: 'Error',
									description:
										'Please select a valid date range',
									variant: 'destructive',
								});
							}
						}}>
						{({ values, setFieldValue }) => (
							<Form className='flex gap-2 items-start'>
								<FormDateRangePicker
									name='filter'
									value={values.filter}
									onChange={(value) => {
										setFieldValue('filter', value);
										setActiveTab('custom');
									}}
								/>
								<Button
									type='submit'
									disabled={isLoading}
									className='w-28'>
									{isLoading ? (
										<LoaderCircle className='h-4 w-4 animate-spin' />
									) : (
										'Apply'
									)}
								</Button>
							</Form>
						)}
					</Formik>
				)}
			</div>
			<div className='mt-4'>
				<StatsOverview totals={data?.totals} loading={isLoading} />
			</div>
			<div className='mt-4'>
				<SalesPerformanceChart
					intervals={data?.intervals}
					interval={interval}
					setInterval={setInterval}
					loading={isLoading}
				/>
			</div>
			<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mt-4'>
				{leaderboardsData?.map((tableInfo) => (
					<SalesTableCard
						key={tableInfo.id}
						tableData={tableInfo}
						loading={isLeaderboardsLoading}
					/>
				))}
			</div>
		</div>
	);
};

export default Dashboard;
