import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { CUSTOMER_API } from 'api/customers';
import { useDisclosure } from 'hooks/useDisclosure';
import SearchAndActions from './_ui/searchAction';
import CustomerTable from './_ui/customerTable';
import CustomerSheet from './_ui/customerSheet';
import { addDays } from 'date-fns';

export default function Customers() {
	const [sorting, setSorting] = useState([]);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnVisibility, setColumnVisibility] = useState({});
	const [rowSelection, setRowSelection] = useState({});
	const [loading, setLoading] = useState(false); // eslint-disable-line
	const [page, setPage] = useState(1);
	const [isSheetOpen, setIsSheetOpen] = useState(false);
	const [selectedCustomer, setSelectedCustomer] = useState(null);
	const [search, setSearch] = useState('');
	const [filters, setFilters] = useState({
		orderBy: 'name',
		order: 'asc',
		per_page: 10,
		dateRange: {
			from: new Date(),
			to: addDays(new Date(), 30),
		},
	});

	const dateRange = `${filters.dateRange.from.toISOString()},${filters.dateRange.to.toISOString()}`;

	const [selectedRowIds, setSelectedRowIds] = useState([]);

	const newCustomerState = useDisclosure();
	const dropdownState = useDisclosure();

	const customerQuery = useQuery({
		queryFn: () =>
			CUSTOMER_API.GET_ALL_CUSTOMERS.FN({
				params: { page, search, ...filters, dateRange },
			}),
		queryKey: [CUSTOMER_API.GET_ALL_CUSTOMERS.KEY, page, search, filters],
	});

	const handleEditClick = (customer) => {
		setSelectedCustomer(customer);
		setIsSheetOpen(true);
	};

	const handleSheetClose = () => {
		setIsSheetOpen(false);
		setSelectedCustomer(null);
	};

	return (
		<div className='w-full'>
			<SearchAndActions
				newCustomerState={newCustomerState}
				loading={loading}
				selectedRowIds={selectedRowIds}
				setSearch={setSearch}
				dropdownState={dropdownState}
				filters={filters}
				setFilters={setFilters}
			/>

			<CustomerTable
				data={customerQuery.data || []}
				isLoading={customerQuery.isLoading}
				isError={customerQuery.isError}
				error={customerQuery.error}
				statusFilter={filters.status}
				sorting={sorting}
				setSorting={setSorting}
				columnFilters={columnFilters}
				setColumnFilters={setColumnFilters}
				columnVisibility={columnVisibility}
				setColumnVisibility={setColumnVisibility}
				rowSelection={rowSelection}
				setRowSelection={setRowSelection}
				page={page}
				per_page={filters.per_page}
				handleEditClick={handleEditClick}
				setSelectedRowIds={setSelectedRowIds}
				selectedRowIds={selectedRowIds}
				setPage={setPage}
				total={customerQuery.data?.length || 0}
			/>

			<CustomerSheet
				isOpen={isSheetOpen}
				onClose={handleSheetClose}
				customer={selectedCustomer}
				isEdit={true}
			/>
			<CustomerSheet
				isOpen={newCustomerState.isOpen}
				onClose={newCustomerState.onClose}
				isEdit={false}
			/>
		</div>
	);
}
