import React, { useState, useEffect } from 'react';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { ScrollArea } from 'components/ui/scroll-area';
import { wpApiClient } from 'api/axios';
import MediaUpload from 'components/ui/imageUploader';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from 'components/ui/alert-dialog';
import { Trash } from 'lucide-react';

const MediaLibraryDialog = ({ isOpen, onClose, onSelect }) => {
	const [mediaItems, setMediaItems] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
	const [itemToDelete, setItemToDelete] = useState(null);

	useEffect(() => {
		if (isOpen) {
			fetchMediaItems();
		}
	}, [isOpen]);

	const fetchMediaItems = async () => {
		setIsLoading(true);
		setError(null);
		try {
			const response = await wpApiClient.get('/wp/v2/media', {
				params: { per_page: 50 },
			});
			setMediaItems(response.data);
		} catch (error) {
			console.error('Error fetching media items:', error);
			setError('Failed to fetch media items. Please try again.');
		}
		setIsLoading(false);
	};

	const handleSelect = (imageUrl) => {
		onSelect(imageUrl);
		onClose();
	};

	const handleDeleteClick = (item) => {
		setItemToDelete(item);
		setDeleteConfirmOpen(true);
	};

	const handleDeleteConfirm = async () => {
		if (itemToDelete) {
			try {
				await wpApiClient.delete(`wp/v2/media/${itemToDelete.id}`, { 
					params: {
						force: true
					}
				});
				setMediaItems(mediaItems.filter(item => item.id !== itemToDelete.id));
			} catch (error) {
				console.error('Error deleting media item:', error);
				setError('Failed to delete media item. Please try again.');
			}
		}
		setDeleteConfirmOpen(false);
		setItemToDelete(null);
	};

	return (
		<>
			<Dialog open={isOpen} onOpenChange={onClose}>
				<DialogContent className="sm:max-w-[800px] sm:h-[80vh] flex flex-col">
					<DialogHeader>
						<DialogTitle>Media Library</DialogTitle>
					</DialogHeader>
					{error && <p className="text-red-500">{error}</p>}
					<ScrollArea className="flex-grow">
						<div className="grid grid-cols-3 gap-6 p-4">
							{isLoading ? (
								<p>Loading...</p>
							) : (
								<>
									{mediaItems.map((item) => (
										<div key={item.id} className="relative">
											<img
												src={item.source_url}
												alt={item.alt_text}
												className="w-full h-48 rounded-lg object-cover cursor-pointer shadow-md border-2 border-transparent hover:opacity-80 hover:scale-105 hover:shadow-lg hover:border-2 hover:border-primary transition-all duration-300 z-10"
												onClick={() => handleSelect(item.source_url)}
											/>
											<div className="mt-2 text-sm truncate">{item.title.rendered}</div>
											<Button
												variant="outline"
												size="sm"
												className="absolute top-0 right-0 m-1"
												onClick={() => handleDeleteClick(item)}
											>
												<Trash className="w-4 h-4 text-red-500" />
											</Button>
										</div>
									))}
								</>
							)}
						</div>
					</ScrollArea>
					<div className="mt-4 p-4 border-t">
						<MediaUpload onUploadSuccess={(imageUrl) => handleSelect(imageUrl)} />
					</div>
				</DialogContent>
			</Dialog>

			<AlertDialog open={deleteConfirmOpen} onOpenChange={setDeleteConfirmOpen}>
				<AlertDialogContent>
						<AlertDialogHeader>
							<AlertDialogTitle>Are you sure you want to delete this image?</AlertDialogTitle>
							<AlertDialogDescription>
								This action cannot be undone. The image will be permanently deleted from your media library.
							</AlertDialogDescription>
						</AlertDialogHeader>
						<AlertDialogFooter>
							<AlertDialogCancel>Cancel</AlertDialogCancel>
							<AlertDialogAction onClick={handleDeleteConfirm}>Delete</AlertDialogAction>
						</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</>
	);
};

export default MediaLibraryDialog;
