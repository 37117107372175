import React from 'react';

import renderField from 'lib/renderField';
import { userFields } from '../customerSchema';
const AddUserProfile = ({ errors, touched, values, setFieldValue }) => {
	return (
		<div className='flex flex-col gap-4'>
			{userFields.map((field) => (
				<div key={field.name} className='space-y-2'>
					{renderField(field, errors, touched, values, setFieldValue)}
				</div>
			))}
		</div>
	);
};

export default AddUserProfile;
