import SimpleSelect from "components/simple-select";
import { Label } from "components/ui/label";
import { Field } from "formik";
import React from "react";

const FormSelect = ({ options, name, label }) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        return (
          <div>
            <div className="flex flex-row gap-3 items-center">
              {label && <Label className="w-[300px]">{label}</Label>}
              <div className="w-full">
                <SimpleSelect
                  options={options}
                  value={options.find(
                    (option) =>
                      option?.value?.toString() === field?.value?.toString()
                  )}
                  onChange={(selectedOption) => {
                    console.log("selectedOption", selectedOption);
                    form.setFieldValue(
                      name,
                      selectedOption
                        ? {
                            value: selectedOption.value,
                            label: selectedOption.label,
                          }
                        : null
                    );
                  }}
                  isMulti={false}
                />
              </div>
            </div>
            {meta.touched && meta.error ? (
              <div className="text-red-500 text-sm">{meta.error}</div>
            ) : null}
          </div>
        );
      }}
    </Field>
  );
};

export default FormSelect;
