import React from "react";

import { Dot } from "lucide-react";

import GenericTable from "components/generic-table";

export default function DiscountTable({
  data,
  isLoading,
  isError,
  error,
  sorting,
  setSorting,
  columnFilters,
  setColumnFilters,
  columnVisibility,
  setColumnVisibility,
  rowSelection,
  setRowSelection,
  page,
  per_page,
  handleEditClick,
  selectedRowIds,
  setSelectedRowIds,
  total,
  setPage,
}) {
  function getDotColor(status) {
    switch (status) {
      case "pending":
        return "bg-zinc-500 ";
      case "publish":
        return "bg-green-600";
      case "failed":
        return "bg-red-500 ";
      case "processing":
        return "bg-zinc-500 ";
      case "on-hold":
        return "bg-orange-600 ";
      case "draft":
        return "bg-yellow-600";
      default:
        return "bg-zinc-500";
    }
  }

  const columns = [
    {
      accessorKey: "code",
      header: "Code",
      cell: ({ row }) => (
        <div className="flex flex-row gap-2 items-center text-zinc-500 text-sm font-medium">
          <Dot
            className={`${getDotColor(
              row.original.status
            )} h-2 w-2 rounded-full`}
          />
          {row.getValue("code")}
        </div>
      ),
    },
    {
      accessorKey: "discount_type",
      header: "Type",
      cell: ({ row }) => (
        <div className="text-zinc-500 text-sm">
          {row.getValue("discount_type")}
        </div>
      ),
    },
    {
      accessorKey: "amount",
      header: "Amount",
      cell: ({ row }) => (
        <div className="text-zinc-500 text-sm">{row.getValue("amount")}</div>
      ),
    },
    {
      accessorKey: "usage_count",
      header: "Usage",
      cell: ({ row }) => <div>{row.getValue("usage_count")}/ ∞</div>,
    },
    {
      accessorKey: "date_expires",
      header: "Expires",
      cell: ({ row }) => (
        <div>
          {row.getValue("date_expires")
            ? new Date(row.getValue("date_expires")).toLocaleDateString()
            : "No expiration"}
        </div>
      ),
    },
  ];

  return (
    <GenericTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isError={isError}
      error={error}
      sorting={sorting}
      setSorting={setSorting}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
      columnVisibility={columnVisibility}
      setColumnVisibility={setColumnVisibility}
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
      page={page}
      per_page={per_page}
      handleEditClick={handleEditClick}
      selectedRowIds={selectedRowIds}
      setSelectedRowIds={setSelectedRowIds}
      setPage={setPage}
      total={total}
    />
  );
}
