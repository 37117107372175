import React from "react";

import GenericTable from "components/generic-table";
import { useQuery } from "@tanstack/react-query";
import { CATEGORIE_API } from "api/categories";
import { Badge } from "components/ui/badge";

export default function DiscountTable({
  data,
  isLoading,
  isError,
  error,
  sorting,
  setSorting,
  columnFilters,
  setColumnFilters,
  columnVisibility,
  setColumnVisibility,
  rowSelection,
  setRowSelection,
  page,
  per_page,
  handleEditClick,
  selectedRowIds,
  setSelectedRowIds,
  total,
  setPage,
}) {
  const categoriesQuery = useQuery({
    queryFn: () =>
      CATEGORIE_API.GET_ALL_CATEGORIES.FN({
        params: { per_page: 100 },
      }),
    queryKey: [CATEGORIE_API.GET_ALL_CATEGORIES.KEY],
  });

  const categories = categoriesQuery.data || [];

  const columns = [
    {
      accessorKey: "image",
      header: "Image",
      cell: ({ row }) => {
        const imageData = row.original.image;
        const categoryName = row.original.name;
        return imageData && imageData.src ? (
          <img
            alt={imageData.alt || "Category image"}
            className="aspect-square rounded-md object-cover"
            height={64}
            src={imageData.src}
            width={64}
          />
        ) : (
          <div className="w-16 h-16 bg-gray-200 rounded-md flex items-center justify-center text-gray-500 font-medium">
            {categoryName.charAt(0)}
          </div>
        );
      },
    },

    {
      accessorKey: "name",
      header: "Name",
      cell: ({ row }) => {
        const parentName = categories.find(
          (category) => category.id === row.original.parent
        )?.name;

        return (
          <div className="flex items-center gap-4">
            <p className="text-md font-bold">{row.getValue("name")}</p>
            {parentName && (
              <div className="mt-1">
                <Badge className="text-xs">{parentName}</Badge>
              </div>
            )}
          </div>
        );
      },
    },
    {
      accessorKey: "slug",
      header: "Slug",
      cell: ({ row }) => <div>{row.getValue("slug")}</div>,
    },
    {
      accessorKey: "count",
      header: "Count",
      cell: ({ row }) => <div>{row.getValue("count")}</div>,
    },
  ];

  return (
    <GenericTable
      data={data}
      columns={columns}
      isLoading={isLoading}
      isError={isError}
      error={error}
      sorting={sorting}
      setSorting={setSorting}
      columnFilters={columnFilters}
      setColumnFilters={setColumnFilters}
      columnVisibility={columnVisibility}
      setColumnVisibility={setColumnVisibility}
      rowSelection={rowSelection}
      setRowSelection={setRowSelection}
      page={page}
      per_page={per_page}
      handleEditClick={handleEditClick}
      selectedRowIds={selectedRowIds}
      setSelectedRowIds={setSelectedRowIds}
      setPage={setPage}
      total={total}
    />
  );
}
