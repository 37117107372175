import React, { useState, useEffect } from 'react';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { TAG_API } from 'api/tags';
import { useToast } from 'components/ui/use-toast';
import { Form, Field, Formik } from 'formik';

const TagDialog = ({ isOpen, onClose, tag, allTags, onTagCreated }) => {
	const { toast } = useToast();
	const queryClient = useQueryClient();
	const [isReady, setIsReady] = useState(false);

	const isEditMode = !!tag;

	useEffect(() => {
		if (isOpen) {
			setIsReady(true);
		} else {
			setIsReady(false);
		}
	}, [isOpen]);

	const mutation = useMutation({
		mutationFn: isEditMode ? TAG_API.UPDATE_TAGS.FN : TAG_API.ADD_TAGS.FN,
		onSuccess: (data) => {
			queryClient.invalidateQueries([TAG_API.GET_ALL_TAGS.KEY]);
			toast({
				title: 'Success',
				description: `Tag ${
					isEditMode ? 'updated' : 'created'
				} successfully.`,
			});
			if (!isEditMode) {
				onTagCreated(data);
			}
			onClose();
		},
		onError: (error) => {
			toast({
				title: 'Error',
				description: `Failed to ${
					isEditMode ? 'update' : 'create'
				} tag.`,
				variant: 'destructive',
			});
		},
	});

	const handleSubmit = (values) => {
		const data = {
			name: values.name,
		};

		if (values.description) {
			data.description = values.description;
		}

		if (values.slug) {
			data.slug = values.slug;
		}

		if (isEditMode) {
			mutation.mutate({ id: tag.id, data });
		} else {
			mutation.mutate({ data });
		}
	};

	const initialValues = {
		name: tag?.name || '',
		slug: tag?.slug || '',
		description: tag?.description || '',
	};

	if (!isReady) return null;

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						{isEditMode ? 'Edit Tag' : 'Create New Tag'}
					</DialogTitle>
					<DialogDescription>
						{isEditMode
							? 'Editing a tag allows you to update its name, slug, and description.'
							: 'Creating a new tag allows you to add a new tag to the store.'}
					</DialogDescription>
				</DialogHeader>
				<Formik
					initialValues={initialValues}
					onSubmit={handleSubmit}
					enableReinitialize>
					{({ values, setFieldValue }) => (
						<Form className='space-y-4'>
							<div className='space-y-2'>
								<Label htmlFor='name'>Name</Label>
								<Field name='name' as={Input} />
							</div>
							<div className='space-y-2'>
								<Label htmlFor='slug'>Slug (optional)</Label>
								<Field name='slug' as={Input} />
							</div>
							<div className='space-y-2'>
								<Label htmlFor='description'>Description</Label>
								<Field
									name='description'
									as='textarea'
									className='w-full h-24 p-2 border rounded'
								/>
							</div>
							<div className='flex justify-end space-x-2'>
								<Button
									type='button'
									variant='outline'
									onClick={onClose}>
									Cancel
								</Button>
								<Button
									type='submit'
									disabled={mutation.isPending}>
									{mutation.isPending
										? 'Saving...'
										: isEditMode
										? 'Save Changes'
										: 'Create Tag'}
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</DialogContent>
		</Dialog>
	);
};

export default TagDialog;
