import React from "react";
import { useFormikContext, FieldArray } from "formik";
import { SaveProductButton } from "./SaveProductButton";
import FormInput from "components/form/form-input";
import { Button } from "components/ui/button";
import { Trash } from "lucide-react";

const ProductDownloads = () => {
  const { values, setFieldValue } = useFormikContext();

  const handleFileChoose = (index) => {
    // Create a hidden file input
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.style.display = "none";

    fileInput.onchange = (e) => {
      const file = e.target.files[0];
      if (file) {
        setFieldValue(`files.${index}.name`, file.name);
        // You might want to handle the actual file upload here
        // and set the URL after successful upload
        // For now, we'll just set a placeholder URL
        setFieldValue(`files.${index}.url`, "file://" + file.name);
      }
    };

    // Trigger the file input click
    fileInput.click();
  };

  return (
    <div className="flex flex-col gap-8 p-4">
      <FieldArray
        name="files"
        render={(arrayHelpers) => (
          <div>
            <label className="block mb-2 font-bold">Files</label>
            {values.files && values.files.length > 0
              ? values.files.map((file, index) => (
                  <div key={index} className="flex items-center gap-2 mb-2">
                    <FormInput
                      type="text"
                      name={`files.${index}.url`}
                      label="File URL"
                    />
                    <FormInput
                      type="text"
                      name={`files.${index}.name`}
                      label="File name"
                    />
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={() => handleFileChoose(index)}
                    >
                      Choose file
                    </Button>
                    <Button
                      type="button"
                    variant="ghost"
                      onClick={() => arrayHelpers.remove(index)}
                    >
                      <Trash className="w-4 h-4" />
                    </Button>
                  </div>
                ))
              : null}
            <Button
              type="button"
              onClick={() => arrayHelpers.push({ url: "", name: "" })}
              className="mt-2"
            >
              Add new file
            </Button>
          </div>
        )}
      />

      <FormInput type="number" name="download_limit" label="Download limit" />

      <FormInput type="number" name="download_expiry" label="Expiry (days)" />

      <SaveProductButton />
    </div>
  );
};

export default ProductDownloads;
