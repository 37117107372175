import * as React from "react";
import Select, { components } from "react-select";
import { defaultClassNames, defaultStyles } from "./helper";
import { DropdownIndicator } from "./ui/dropdown-indicator";
import { ClearIndicator } from "./ui/clear-indicator";
import { MultiValueRemove } from "./ui/multi-value-remove";
import { CheckIcon } from "lucide-react";

// Rename the custom Option component to CustomOption
const CustomOption = (props) => {
  return (
    <components.Option {...props}>
      <div className="flex items-center justify-between">
        <div>{props.data.label}</div>
        {props.isSelected && <CheckIcon />}
      </div>
    </components.Option>
  );
};

const SimpleSelect = React.forwardRef((props, ref) => {
  const {
    value,
    onChange,
    options = [],
    styles = defaultStyles,
    classNames = defaultClassNames,
    components = {},
    ...rest
  } = props;

  return (
    <Select
      ref={ref}
      value={value}
      onChange={onChange}
      options={options}
      unstyled
      components={{
        DropdownIndicator,
        ClearIndicator,
        MultiValueRemove,
        Option: CustomOption, // Use the renamed CustomOption here
        ...components,
      }}
      styles={styles}
      classNames={classNames}
      {...rest}
    />
  );
});

export default SimpleSelect;
