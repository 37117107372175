import React from "react";
import { Field } from "formik";
import { Label } from "components/ui/label";
import { ReusableTabs } from "components/reusable-tabs";

const FormTabs = ({ name, label }) => (
  <Field name={name}>
    {({ field, meta, form }) => {
      return (
        <div>
          <div className="flex flex-row gap-3 items-center ">
            {label && <Label className="w-[300px]">{label}</Label>}
            <div className="w-full">
              <ReusableTabs
                value={field.value}
                onChange={(value) => form.setFieldValue(name, value)}
              />
            </div>
          </div>
          {meta.touched && meta.error ? (
            <div className="text-red-500 text-sm">{meta.error}</div>
          ) : null}
        </div>
      );
    }}
  </Field>
);

export default FormTabs;
