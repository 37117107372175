import { axiosClient } from "api/axios";

export const getSetting = async () => {
  try {
    const { data } = await axiosClient.get(`/settings`); // Append serialized authParams to the URL
    return data;
  } catch (error) {
    console.log("Error fetching settings: ", error); // Debugging log for errors
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getPage = async () => {
  try {
    const { data } = await axiosClient.get(`/settings/page/id`); // Append serialized authParams to the URL
    return data;
  } catch (error) {
    console.log("Error fetching pages: ", error); // Debugging log for errors
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const SETTING_API = {
  GET_SETTING: {
    KEY: "GET_SETTING",
    FN: getSetting,
  },
  GET_PAGE: {
    KEY: "GET_PAGE",
    FN: getPage,
  },
};
