import { useQuery } from '@tanstack/react-query';

import { Button } from 'components/ui/button';
import { Field, Form, Formik } from 'formik';
import React from 'react';

import { ReusableTabs } from 'components/reusable-tabs';
import ReusableTextarea from 'components/reusable-textarea';
import InputWrapper from 'pages/setup/_ui/InputWrapper';
import { SETTING_API } from 'api/settings';
import { initialValuesGenerator } from 'pages/setup/_ui/render-fields';

const Setting = () => {
	const settingQuery = useQuery({
		queryFn: () => SETTING_API.GET_SETTING.FN(),
		queryKey: [SETTING_API.GET_SETTING.KEY],
	});

	const apiData = settingQuery.data || [];

	const pageQuery = useQuery({
		queryFn: () => SETTING_API.GET_PAGE.FN(),
		queryKey: [SETTING_API.GET_PAGE.KEY],
	});

	const pageData = pageQuery.data || []; // eslint-disable-line

	return (
		<div className='p-4'>
			<Formik
				initialValues={initialValuesGenerator(apiData)}
				onSubmit={(values) => {
					//console.log(values);
					// Handle form submission
				}}>
				<Form>
					<div className='grid grid-cols-1 gap-4 p-4'>
						<InputWrapper
							label='Licence key'
							description='Activating license enables updates and support'>
							<Field name='general' as={ReusableTextarea} />
						</InputWrapper>
						<InputWrapper
							label='Show on page'
							description='Load React dashboard on a specific frontend page. Only logged in users with priviledges to manage the store will be able to visit the page.'>
							<Field name='shipping' as={ReusableTextarea} />
						</InputWrapper>
						<InputWrapper
							label='Logo'
							description='Optional logo for to be displayed in the menu'>
							<Field name='email' as={ReusableTextarea} />
						</InputWrapper>
						<InputWrapper
							label='Notification sound'
							description='Enable a subtle notification sound when new orders come in.'>
							<Field name='notification' as={ReusableTabs} />
						</InputWrapper>
						<Button type='submit' className='w-32'>
							Save
						</Button>
					</div>
				</Form>
			</Formik>
		</div>
	);
};

export default Setting;
