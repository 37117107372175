import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { DashboardLayout } from 'layout/dashboard-layout';
import Orders from 'pages/orders/orders';
import Products from 'pages/products/products';
import Discounts from 'pages/discounts/discounts';
import Customers from 'pages/customers/customers';
import Categories from 'pages/categories/categories';
import Setup from 'pages/setup/setup';
import Tools from 'pages/tools/tools';
import Setting from 'pages/settings/page';
import Dashboard from 'pages/dashboard/dashboard';
import AddProduct from 'pages/products/add-update-products/add-product';
import UpdateProduct from 'pages/products/add-update-products/update-product';
import { useAuth } from './hooks/useAuth';
import { APP_DEMO_MODE } from './config';

function App() {
	const { isAuthenticated, isLoading } = useAuth();

	if (isLoading) {
		return <div>Loading...</div>; // Or a loading spinner
	}

	if (!APP_DEMO_MODE && !isAuthenticated) {
		window.location.href = 'https://storeui.net/my-account/';
		return null;
	}

	return (
		<Router>
			<div className='storeui'>
				<DashboardLayout>
					<Routes>
						<Route path='/orders' element={<Orders />} />
						<Route path='/products' element={<Products />} />
						<Route path='/discounts' element={<Discounts />} />
						<Route path='/customers' element={<Customers />} />
						<Route path='/setup' element={<Setup />} />
						<Route path='/categories' element={<Categories />} />
							<Route path='/products' element={<Products />} />
							<Route path='/products/new' element={<AddProduct />} />
							<Route
								path='/products/update/:productId'
								element={<UpdateProduct />}
							/>
							<Route path='/tools' element={<Tools />} />
							<Route path='/settings' element={<Setting />} />
							<Route path='/' element={<Dashboard />} />
					</Routes>
				</DashboardLayout>
			</div>
		</Router>
	);
}

export default App;
