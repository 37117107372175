import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'components/ui/button';
import { Form, Formik } from 'formik';
import { format } from 'date-fns';
import { LockIcon, Trash2Icon, ChevronDownIcon, ChevronUpIcon } from 'lucide-react';
import DeleteModal from 'components/delete-model';
import { renderNormalField } from 'components/renderNormalField';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useToast } from 'components/ui/use-toast';
import { ORDER_API } from 'api/orders';
import { LoadingSpinner } from 'components/ui/loadingSpinner';

const fields = [
    {
        name: 'note',
        label: 'Order note',
        type: 'textarea',
        placeholder: 'Order note',
    },
    {
        name: 'customer_note',
        label: 'Note type',
        type: 'select',
        options: [
            { value: false, label: 'Private' },
            { value: true, label: 'Customer' },
        ],
    },
];

// Function to decode HTML entities
const decodeHTMLEntities = (text) => {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    return textArea.value;
};

const formatNote = (note) => {
    const decodedNote = decodeHTMLEntities(note);
    
    // Check if the note contains stock level changes
    if (decodedNote.includes('Stock levels reduced:')) {
        const [prefix, changes] = decodedNote.split('Stock levels reduced:');
        const items = changes.split(',').map(item => item.trim());
        return (
            <div>
                {prefix && <p>{prefix.trim()}</p>}
                <p>Stock levels reduced:</p>
                <ul className="list-disc pl-5 mt-2">
                    {items.map((item, index) => {
                        const [productName, change] = item.split(')');
                        return (
                            <li key={index}>
                                <span className="font-medium">{productName})</span>
                                <span className="text-red-600">{change}</span>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
    // For other types of notes, return as is
    return <p>{decodedNote}</p>;
};

const Note = ({ note, onDelete, isHovered, setHoveredNoteId }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const noteLength = note.note.length;
    const shouldTruncate = noteLength > 200;

    return (
        <div
            className='flex items-start min-h-14 py-2 border-b last:border-b-0 relative'
            onMouseEnter={() => setHoveredNoteId(note.id)}
            onMouseLeave={() => setHoveredNoteId(null)}>
            <div className='flex-grow'>
                <div className='flex items-center text-sm text-gray-500 mb-1'>
                    <span>
                        on {format(new Date(note.date_created), 'd MMM, h:mm a')}
                    </span>
                    <span className='ml-2'>
                        by {note.customer_note ? 'Customer' : 'WooCommerce'}
                    </span>
                </div>
                <div className={`text-sm text-gray-700 ${shouldTruncate && !isExpanded ? 'line-clamp-3' : ''}`}>
                    {formatNote(note.note)}
                </div>
                {shouldTruncate && (
                    <Button
                        variant="ghost"
                        size="sm"
                        className="mt-1 text-blue-500"
                        onClick={() => setIsExpanded(!isExpanded)}
                    >
                        {isExpanded ? (
                            <>
                                <ChevronUpIcon className="h-4 w-4 mr-1" />
                                Show less
                            </>
                        ) : (
                            <>
                                <ChevronDownIcon className="h-4 w-4 mr-1" />
                                Show more
                            </>
                        )}
                    </Button>
                )}
            </div>
            <div className='h-4'>
                {isHovered ? (
                    <Button
                        variant='ghost'
                        size='icon'
                        onClick={() => onDelete(note.id)}>
                        <Trash2Icon className='h-4 w-4' />
                    </Button>
                ) : (
                    <Button variant='ghost' size='icon'>
                        <LockIcon className='w-4 h-4' />
                    </Button>
                )}
            </div>
        </div>
    );
};

const Notes = ({ orderId }) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [noteToDelete, setNoteToDelete] = useState(null);
	const [hoveredNoteId, setHoveredNoteId] = useState(null);
	const queryClient = useQueryClient();
	const { toast } = useToast();
	const notesContainerRef = useRef(null);

	const { data: notes = [], isLoading } = useQuery({
		queryKey: [ORDER_API.GET_ALL_ORDER_NOTES.KEY, orderId],
		queryFn: () => ORDER_API.GET_ALL_ORDER_NOTES.FN({ orderId }),
	});

	const addNoteMutation = useMutation({
		mutationFn: ORDER_API.ADD_ORDER_NOTE.FN,
		onSuccess: () => {
			queryClient.invalidateQueries([
				ORDER_API.GET_ALL_ORDER_NOTES.KEY,
				orderId,
			]);
			toast({ title: 'Success', description: 'Note added successfully' });
		},
		onError: (error) => {
			toast({
				title: 'Error',
				description: 'Failed to add note',
				variant: 'destructive',
			});
		},
	});

	const deleteNoteMutation = useMutation({
		mutationFn: ORDER_API.DELETE_ORDER_NOTE.FN,
		onSuccess: () => {
			queryClient.invalidateQueries([
				ORDER_API.GET_ALL_ORDER_NOTES.KEY,
				orderId,
			]);
			toast({
				title: 'Success',
				description: 'Note deleted successfully',
			});
		},
		onError: (error) => {
			toast({
				title: 'Error',
				description: 'Failed to delete note',
				variant: 'destructive',
			});
		},
	});

	const handleAddNote = (values, { resetForm }) => {
		addNoteMutation.mutate({
			orderId,
			noteData: {
				note: values.note,
				customer_note: values.customer_note.value,
				added_by_user: !values.customer_note.value,
			},
		});
		resetForm();
	};

	const openModal = (noteId) => {
		setNoteToDelete(noteId);
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setNoteToDelete(null);
	};

	const handleDeleteNote = () => {
		if (noteToDelete) {
			deleteNoteMutation.mutate({ orderId, noteId: noteToDelete });
			closeModal();
		}
	};

	useEffect(() => {
		if (notesContainerRef.current) {
			notesContainerRef.current.scrollTop =
				notesContainerRef.current.scrollHeight;
		}
	}, [notes]);

	if (isLoading) return <LoadingSpinner />;

	return (
		<div className='w-full'>
			<div className='mb-6 space-y-2 max-h-60 overflow-y-auto'>
				{notes.map((note) => (
					<Note
						key={note.id}
						note={note}
						onDelete={openModal}
						isHovered={hoveredNoteId === note.id}
						setHoveredNoteId={setHoveredNoteId}
					/>
				))}
			</div>
			<Formik
				initialValues={{
					note: '',
					customer_note: { value: false, label: 'Private' },
				}}
				onSubmit={handleAddNote}>
				<Form className='space-y-4'>
					{fields.map((field) => renderNormalField(field))}
					<div className='flex justify-between items-center'>
						<Button type='submit' className='px-6'>
							{addNoteMutation.isPending
								? 'Adding...'
								: 'Add note'}
						</Button>
					</div>
				</Form>
			</Formik>
			<DeleteModal
				title='Delete note'
				description='Are you sure you want to delete this note?'
				isOpen={isModalOpen}
				onClose={closeModal}
				onConfirm={handleDeleteNote}
			/>
		</div>
	);
};

export default Notes;
