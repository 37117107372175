import React from 'react';

import { Badge } from 'components/ui/badge';
import { format } from 'date-fns';
import { Avatar, AvatarFallback } from 'components/ui/avatar';
import GenericTable from 'components/generic-table';

export default function OrderTable({
	data,
	isLoading,
	isError,
	error,
	sorting,
	setSorting,
	columnFilters,
	setColumnFilters,
	columnVisibility,
	setColumnVisibility,
	rowSelection,
	setRowSelection,
	page,
	per_page,
	handleEditClick,
	selectedRowIds,
	setSelectedRowIds,
	total,
	setPage,
}) {
	function getStatusColor(status) {
		switch (status) {
			case 'pending':
				return 'text-orange-700 bg-orange-50 ring-orange-600/10 hover:bg-orange-100';
			case 'processing':
				return 'text-green-700 bg-green-50 ring-green-600/20 hover:bg-green-100';
			case 'failed':
				return 'text-red-700 bg-red-50 ring-red-600/10 hover:bg-red-100';
			case 'on-hold':
				return 'text-yellow-700 bg-yellow-50 ring-yellow-600/10 hover:bg-yellow-100';
			case 'completed':
				return 'text-blue-700 bg-blue-50 ring-blue-600/10 hover:bg-blue-100';
			default:
				return 'text-zinc-700 bg-zinc-50 ring-zinc-600/10 hover:bg-zinc-100';
		}
	}

	const columns = [
		{
			accessorKey: 'number',
			header: 'ID',
			cell: ({ row }) => (
				<div className='text-zinc-500  text-sm font-semibold'>
					#{row.getValue('number')}
				</div>
			),
		},

		{
			accessorKey: 'date_created',
			header: 'Date',
			cell: ({ row }) => {
				const date = new Date(row.getValue('date_created'));
				return (
					<div className='text-zinc-500 text-sm'>
						{format(date, 'MMM d, h:mm a')}
					</div>
				);
			},
		},
		{
			accessorKey: 'status',
			header: 'Status',
			cell: ({ row }) => (
				<Badge
					className={`${getStatusColor(
						row.getValue('status')
					)} rounded-md py-1 px-2 text-xs font-medium ring-1`}>
					<div>{row.getValue('status')}</div>
				</Badge>
			),
		},
		{
			accessorKey: 'customer',
			header: 'Customer',
			cell: ({ row }) => (
				<div className='inline-flex flex-row gap-2 items-center'>
					<div className=' flex place-content-center items-center justify-center '>
						<Avatar className='border-2 border-zinc-200'>
							<AvatarFallback>
								{row.original.billing.first_name.charAt(0)}
							</AvatarFallback>
						</Avatar>

						<span className='uppercase text-sm'>
							{row.getValue('customer')}
						</span>
					</div>
					<div>
						{row.original.billing.first_name}{' '}
						{row.original.billing.last_name}
					</div>
				</div>
			),
		},

		{
			accessorKey: 'name',
			header: 'Purchased',
			cell: ({ row }) => {
				const items = row.original.line_items;
				const firstItem = items[0]?.name; // Get the first item name
				const otherCount = items.length - 1; // Count the remaining items

				return (
					<div>
						{firstItem}
						{otherCount > 0 && (
							<span className='text-gray-500'>
								{' '}
								({otherCount}{' '}
								{otherCount === 1 ? 'other' : 'others'})
							</span>
						)}
					</div>
				);
			},
		},
		{
			accessorKey: 'revenue',
			header: () => <div className='text-left'>Revenue</div>,
			cell: ({ row }) => {
				const revenue = parseFloat(row.original.total);
				const formattedRevenue = new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'EUR',
				}).format(revenue);

				return (
					<div className='text-zinc-500 text-sm'>
						{formattedRevenue}
					</div>
				);
			},
		},
	];

	return (
		<GenericTable
			data={data}
			columns={columns}
			isLoading={isLoading}
			isError={isError}
			error={error}
			sorting={sorting}
			setSorting={setSorting}
			columnFilters={columnFilters}
			setColumnFilters={setColumnFilters}
			columnVisibility={columnVisibility}
			setColumnVisibility={setColumnVisibility}
			rowSelection={rowSelection}
			setRowSelection={setRowSelection}
			page={page}
			per_page={per_page}
			handleEditClick={handleEditClick}
			selectedRowIds={selectedRowIds}
			setSelectedRowIds={setSelectedRowIds}
			setPage={setPage}
			total={total}
		/>
	);
}
