import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "components/ui/dialog";
import Notes from './Notes';

const NotesDialog = ({ orderId, isOpen, onClose }) => {
    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Order Notes</DialogTitle>
                </DialogHeader>
                <Notes orderId={orderId} />
            </DialogContent>
        </Dialog>
    );
};

export default NotesDialog;