import { axiosClient } from "../axios";

export const getAllTags = async ({ params }) => {
  try {
    const { data } = await axiosClient.get(`/products/tags`, {
      params,
    });
    return data;
  } catch (error) {
    console.log("Error fetching tags: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const updateTags = async ({ id, data }) => {
  try {
    const response = await axiosClient.put(`products/tags/${id}`, data);
    return response.data;
  } catch (error) {
    console.log("Error updating tags: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const addTag = async ({ data }) => {
	try {
		const response = await axiosClient.post("products/tags", data);
		return response.data;
	} catch (error) {
		console.log("Error adding tag: ", error);
		throw error.response ? error.response.data : new Error("Network Error");
	}
};

export const deleteTag = async ({ id }) => {
	try {
		const response = await axiosClient.delete(`products/tags/${id}`, {
			params: { force: true },
		});
	return response.data;
	} catch (error) {
		console.log("Error deleting tag: ", error);
		throw error.response ? error.response.data : new Error("Network Error");
	}
};

export const TAG_API = {
	GET_ALL_TAGS: {
		KEY: 'getAllTags',
		FN: getAllTags,
	},
	UPDATE_TAGS: {
		KEY: 'updateTags',
		FN: updateTags,
	},
	ADD_TAGS: {
		KEY: 'addTag',
	FN: addTag,
	},
	DELETE_TAGS: {
	KEY: 'deleteTag',
	FN: deleteTag,
	},
};
