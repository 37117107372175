import React, { useState, useEffect } from "react";
import { Button } from "components/ui/button";
import FormMultiSelect from "components/form/form-multi-select";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CATEGORIE_API } from "api/categories";
import CategoryDialog from "./CategoryDialog";
import ManageCategoriesDialog from "./ManageCategoriesDialog";
import { LoadingSpinner } from "components/ui/loadingSpinner";
import { Plus } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "components/ui/tooltip";
import { CircleHelp } from "lucide-react";

const CategorySelect = ({ value = [], onChange }) => {
  const [isNewCategoryDialogOpen, setIsNewCategoryDialogOpen] = useState(false);
  const [isManageCategoriesDialogOpen, setIsManageCategoriesDialogOpen] =
    useState(false);
  const [localCategories, setLocalCategories] = useState([]);
  const queryClient = useQueryClient();

  const { data: categories, isLoading } = useQuery({
    queryKey: [CATEGORIE_API.GET_ALL_CATEGORIES.KEY],
    queryFn: () =>
      CATEGORIE_API.GET_ALL_CATEGORIES.FN({ params: { per_page: 100 } }),
  });

  useEffect(() => {
    if (categories) {
      setLocalCategories(categories);
    }
  }, [categories]);

  if (isLoading) return <LoadingSpinner />;

  const categoryOptions = localCategories.map((category) => ({
    value: parseInt(category.id, 10),
    label: category.name,
  }));

  const handleNewCategory = (newCategory) => {
    const formattedNewCategory = {
      ...newCategory,
      id: parseInt(newCategory.id, 10),
    };

    setLocalCategories((prevCategories) => {
      const updatedCategories = [...prevCategories, formattedNewCategory];
      return updatedCategories;
    });

    queryClient.setQueryData(
      [CATEGORIE_API.GET_ALL_CATEGORIES.KEY],
      (oldData) => {
        if (Array.isArray(oldData)) {
          const updatedData = [...oldData, formattedNewCategory];
          return updatedData;
        }
        return oldData;
      }
    );

    const newValue = [...value, formattedNewCategory.id];
    onChange(newValue);
    setIsNewCategoryDialogOpen(false);
  };

  const handleCategoryChange = (newSelectedCategories) => {
    onChange(
      newSelectedCategories.map((id) => {
        return {
          id: parseInt(id, 10),
          name:
            localCategories.find((local) => local.id === parseInt(id, 10))
              ?.name || "",
        };
      })
    );
  };

  return (
    <div className="flex flex-col space-y-2">
      <div>
        <FormMultiSelect
          name="categories"
          options={categoryOptions}
          value={value.map((id) => ({
            value: parseInt(id, 10),
            label:
              localCategories.find((local) => local.id === parseInt(id, 10))
                ?.name || "",
          }))}
          onChange={handleCategoryChange}
          label="Categories"
          className="flex-grow"
        />
        <div className="flex flex-row gap-3 items-center mt-4">
          <div className="w-[300px]"></div>

          <div className="w-full flex flex-row gap-2">
            <Button
              variant="outline"
              type="button"
              onClick={() => setIsNewCategoryDialogOpen(true)}
            >
              <Plus className="w-4 h-4 mr-2" /> New Category
            </Button>
            <Button
              variant="ghost"
              type="button"
              onClick={() => setIsManageCategoriesDialogOpen(true)}
            >
              Manage Categories
            </Button>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <CircleHelp className="w-4 h-4 text-gray-500" />
                </TooltipTrigger>
                <TooltipContent>
                  <p>Manage existing categories and create new ones</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      </div>
      <CategoryDialog
        isOpen={isNewCategoryDialogOpen}
        onClose={() => setIsNewCategoryDialogOpen(false)}
        onCategoryCreated={handleNewCategory}
        allCategories={localCategories}
      />
      <ManageCategoriesDialog
        isOpen={isManageCategoriesDialogOpen}
        onClose={() => setIsManageCategoriesDialogOpen(false)}
        allCategories={localCategories}
      />
    </div>
  );
};

export default CategorySelect;
