import React, { useState } from 'react';
import { Button } from 'components/ui/button';
import MediaLibraryDialog from 'components/ui/mediaLibraryDialog';
import { Trash } from 'lucide-react';

const MediaLibrarySelect = ({ value, onChange }) => {
	const [isDialogOpen, setIsDialogOpen] = useState(false);

	const handleImageSelect = (imageUrl) => {
		onChange(imageUrl);
		setIsDialogOpen(false);
	};

	return (
		<div className="space-y-2">
			{value && (
				<div className="mb-2">
					<img
						src={value}
						alt='Selected'
						className="max-w-full h-auto mb-4 rounded-lg shadow-md"
					/>
				</div>
			)}
			<div className="flex items-center gap-2">
				<Button
					onClick={() => setIsDialogOpen(true)}
					type="button"
					variant="outline"
				>
					{value ? 'Replace Image' : 'Select Image'}
				</Button>
				{ value && (
					<Button
						type="button"
						variant="ghost"
						className="text-red-500"
						onClick={() => onChange('')}
					>
						<Trash size={16} />
					</Button>
				)}
			</div>
			<MediaLibraryDialog
				isOpen={isDialogOpen}
				onClose={() => setIsDialogOpen(false)}
				onSelect={handleImageSelect}
			/>
		</div>
	);
};

export default MediaLibrarySelect;
