import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SETUP_API } from 'api/setup';
import { Button } from 'components/ui/button';
import { Form, Formik } from 'formik';
import React from 'react';
import { initialValuesGenerator, renderField } from './render-fields';
import { useToast } from 'components/ui/use-toast';
import { LoadingSpinner } from 'components/ui/loadingSpinner';
import { Dialog, DialogContent, DialogTrigger } from 'components/ui/dialog';
import TaxDialog from './TaxDialog';
import NetworkError from 'components/ui/networkError';

const Tax = () => {
	const { toast } = useToast();
	const cache = useQueryClient();

	const updateTaxMutation = useMutation({
		mutationFn: SETUP_API.UPDATE_TAX.FN,
		mutationKey: SETUP_API.UPDATE_TAX.KEY,
		onError: (error) => {
			console.log('Error Updating tax: ', error);
			toast({
				title: 'Error',
				description: 'There was a problem updating the tax settings.',
				variant: 'destructive',
			});
		},
		onSuccess: (data) => {
			toast({
				title: 'Success',
				description: 'Your tax settings were updated successfully.',
			});
			cache.invalidateQueries({
				queryKey: [SETUP_API.UPDATE_TAX.KEY],
			});
		},
	});

	const handleSubmit = async (values) => {
		try {
			await updateTaxMutation.mutateAsync({
				values,
			});
		} catch (error) {
			console.log('Error updateing tax: ', error);
		}
	};

	const taxQuery = useQuery({
		queryFn: () => SETUP_API.GET_TAX.FN(),
		queryKey: [SETUP_API.GET_TAX.KEY],
	});

	const apiData = taxQuery?.data ?? [];

	if (taxQuery.isPending) {
		return <LoadingSpinner />;
	}

	if (taxQuery.error) {
		return 	(
			<NetworkError
				message={ taxQuery.error }
			/>
		)
	}

	if (!taxQuery.data) {
		return null;
	}

	return (
		<Formik
			initialValues={initialValuesGenerator(apiData)}
			onSubmit={handleSubmit}>
			{({ isSubmitting }) => (
				<Form className='flex flex-col gap-4 p-4'>
					{apiData.map((field) => (
						<div key={field.id}>{renderField(field)}</div>
					))}
					<div className='flex justify-end gap-2'>
							<Dialog>
								<DialogTrigger asChild>
									<Button type="button" variant="outline">
										Manage Tax Rates
									</Button>
								</DialogTrigger>
								<DialogContent className="max-w-4xl">
									<TaxDialog />
								</DialogContent>
							</Dialog>
						<Button
							type='submit'
							className='w-32'
							disabled={
								isSubmitting || updateTaxMutation.isPending
							}
							aria-disabled='true'>
							{isSubmitting || updateTaxMutation.isPending
								? 'Saving...'
								: 'Save'}
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default Tax;
