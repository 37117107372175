import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SETUP_API } from 'api/setup';
import { Button } from 'components/ui/button';
import { Form, Formik } from 'formik';
import { useToast } from 'components/ui/use-toast';
import { LoadingSpinner } from 'components/ui/loadingSpinner';
import { Card, CardContent, CardDescription, CardHeader, CardTitle, CardFooter } from 'components/ui/card';
import { Switch } from 'components/ui/switch';
import { Label } from 'components/ui/label';
import { ExternalLink } from 'lucide-react';
import { Input } from 'components/ui/input';
import { Textarea } from 'components/ui/textarea';
import { Checkbox } from 'components/ui/checkbox';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import NetworkError from 'components/ui/networkError';


const getInputComponent = (type) => {
    switch (type) {
        case 'text':
        case 'password':
        case 'email':
        case 'number':
            return Input;
        case 'textarea':
            return Textarea;
        case 'checkbox':
            return Checkbox;
        case 'select':
            return Select;
        default:
            return Input;
    }
};

const PaymentGatewayForm = ({ gateway }) => {
    const { toast } = useToast();
    const cache = useQueryClient();

    const updatePaymentMutation = useMutation({
        mutationFn: SETUP_API.UPDATE_PAYMENT.FN,
        onSuccess: () => {
            toast({
                title: 'Success',
                description: `${gateway.method_title} updated successfully.`,
            });
            cache.invalidateQueries({ queryKey: [SETUP_API.GET_PAYMENTS.KEY] });
        },
        onError: (error) => {
            console.log(`Error updating ${gateway.title}:`, error);
            toast({
                title: 'Error',
                description: `There was a problem updating ${gateway.title}.`,
                variant: 'destructive',
            });
        },
    });

    const handleSubmit = async (values) => {
        const updatedGateway = {
            id: values.id,
            enabled: values.enabled,
            settings: Object.fromEntries(
                Object.entries(values.settings).map(([key, setting]) => [key, setting.value])
            )
        };
        await updatePaymentMutation.mutateAsync(updatedGateway);
    };

    return (
        <Card key={gateway.id} className="mt-4 flex flex-col min-h-[400px]">
            <Formik
                initialValues={gateway}
                onSubmit={handleSubmit}
            >
                {({ values, setFieldValue, isSubmitting }) => (
                    <Form className="flex flex-col flex-grow">
                        <CardHeader>
                            <div className="flex gap-2 justify-between items-center">
                                <div className="flex gap-3 items-center ">
                                    <Switch
                                        id={`${gateway.id}-enabled`}
                                        checked={values.enabled}
                                        onCheckedChange={(checked) => setFieldValue('enabled', checked)}
                                        size="sm"
                                    />
                                    <CardTitle>{gateway.method_title}</CardTitle>
                                </div>
								<TooltipProvider>
									<Tooltip>
										<TooltipTrigger>
											<Button
												variant="ghost"
												size="icon"
												className="w-10 h-10 hover:bg-primary/10 group"
												type="button"
												onClick={() => window.open(gateway.settings_url, '_blank')}
											>
												<ExternalLink
													className="h-6 w-6 text-muted-foreground group-hover:text-primary transition-colors"
												/>
											</Button>
										</TooltipTrigger>
										<TooltipContent>
											<p>Open {gateway.method_title} settings in WooCommerce</p>
										</TooltipContent>
									</Tooltip>
								</TooltipProvider>
                            </div>
                            <CardDescription dangerouslySetInnerHTML={{ __html: gateway.description }} />
                        </CardHeader>
                        <CardContent className="space-y-4 flex-grow">
                            {gateway.settings && Object.entries(gateway.settings).map(([key, setting]) => {
                                const InputComponent = getInputComponent(setting.type);
                                return (
                                    <div key={key} className="space-y-2">
                                        {InputComponent === Select ? (
											<>
                                            <Label htmlFor={key}>{setting.label}</Label>
											<Select
                                                id={key}
                                                name={`settings.${key}.value`}
                                                value={values.settings[key].value}
                                                onValueChange={(value) => setFieldValue(`settings.${key}.value`, value)}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue placeholder={setting.placeholder} />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {setting.options && Object.entries(setting.options).map(([optionValue, optionLabel]) => (
                                                        <SelectItem key={optionValue} value={optionValue}>
                                                            {optionLabel}
                                                        </SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
											</>
                                        ) : InputComponent === Checkbox ? (
											<div className="items-top flex space-x-2">
                                            <Checkbox
                                                id={key}
                                                name={`settings.${key}.value`}
                                                checked={values.settings[key].value === 'yes'}
                                                onCheckedChange={(checked) => setFieldValue(`settings.${key}.value`, checked ? 'yes' : 'no')}
                                            />
											<Label htmlFor={key}>{setting.label}</Label>
											</div>
                                        ) : (
											<>
											<Label htmlFor={key}>{setting.label}</Label>
                                            <InputComponent
                                                type={setting.type}
                                                id={key}
                                                name={`settings.${key}.value`}
                                                value={values.settings[key].value}
                                                onChange={(e) => setFieldValue(`settings.${key}.value`, e.target.value)}
                                                placeholder={setting.placeholder}
                                            />
											</>
                                        )}
                                        <p className="text-sm text-muted-foreground">{setting.description}</p>
                                    </div>
                                );
                            })}
                        </CardContent>
                        <CardFooter className="mt-auto justify-end">
                            <Button
                                type="submit"
                                disabled={isSubmitting || updatePaymentMutation.isPending}
                            >
                                {isSubmitting || updatePaymentMutation.isPending ? 'Saving...' : `Save ${gateway.method_title} Settings`}
                            </Button>
                        </CardFooter>
                    </Form>
                )}
            </Formik>
        </Card>
    );
};

const Payments = () => {
    const paymentsQuery = useQuery({
        queryFn: SETUP_API.GET_PAYMENTS.FN,
        queryKey: [SETUP_API.GET_PAYMENTS.KEY],
    });

    if (paymentsQuery.isPending) {
        return <LoadingSpinner />;
    }

	if (paymentsQuery.error) {
		return 	(
			<NetworkError
				message={ paymentsQuery.error }
			/>
		)
	}

    if (!paymentsQuery.data) {
        return null;
    }

    return (
        <div className="flex flex-col gap-4 py-4 lg:grid lg:grid-cols-2">
            {paymentsQuery.data.map((gateway) => (
                <PaymentGatewayForm key={gateway.id} gateway={gateway} />
            ))}
        </div>
    );
};

export default Payments;