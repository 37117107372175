import React from "react";
import { useFormikContext, FieldArray } from "formik";
import { Button } from "components/ui/button";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";

const FormFileUploadArray = ({ name, label }) => {
  const { values, setFieldValue } = useFormikContext();

  const handleFileChoose = (index) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.style.display = "none";
    fileInput.onchange = (event) => {
      const file = event.currentTarget.files[0];
      if (file) {
        const newFiles = [...values[name]];
        newFiles[index] = { file, name: file.name };
        setFieldValue(name, newFiles);
      }
    };
    fileInput.click();
  };

  return (
    <FieldArray
      name={name}
      render={(arrayHelpers) => (
        <div>
          <Label className="block mb-2 font-bold">{label}</Label>
          {values[name] && values[name].length > 0
            ? values[name].map((file, index) => (
                <div key={index} className="flex items-center gap-2 mb-2">
                  <Input
                    type="text"
                    readOnly
                    value={file.name || ""}
                    placeholder="No file chosen"
                    className="flex-grow"
                  />
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => handleFileChoose(index)}
                  >
                    Choose file
                  </Button>
                  <Button
                    type="button"
                    variant="outline"
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    X
                  </Button>
                </div>
              ))
            : null}
          <Button
            type="button"
            onClick={() => arrayHelpers.push({ file: null, name: "" })}
            className="mt-2"
          >
            Add new file
          </Button>
        </div>
      )}
    />
  );
};

export default FormFileUploadArray;
