import React from 'react';
import { Card, CardContent, CardFooter } from 'components/ui/card';
import { Button } from 'components/ui/button';
import { Unplug } from 'lucide-react';

const NetworkError = ({ className }) => {
	return (
		<Card className='mt-12 max-w-2xl mx-auto'>
			<CardContent className='p-4'>
				<div className='flex items-center gap-2 mb-2'>
					<Unplug className='h-4 w-4 text-primary' />
					<h4 className='font-semibold'>
						Ooops! It seems there's a{' '}
						<span className='text-primary'>network issue</span>
					</h4>
				</div>
				<p className='text-sm text-muted-foreground'>
					StoreUI couldn't fetch the data from your store.
					<br />
					<br />
					This could be due to several reasons:
					<ul className='list-disc pl-6'>
						<li>Your store is down</li>
						<li>
							The API limit rate has been reached on your server
						</li>
						<li>Your store API keys are incorrect</li>
					</ul>
				</p>
			</CardContent>
			<CardFooter className='p-4 pt-0 flex gap-2'>
				<Button className='w-full' variant='outline'>
					How to check API credentials
				</Button>
				<Button className='w-full' variant='outline'>
					Check if my site is down
				</Button>
			</CardFooter>
		</Card>
	);
};

export default NetworkError;
