import React from 'react';
import { Badge } from 'components/ui/badge';
import { format } from 'date-fns';
import GenericTable from 'components/generic-table';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'components/ui/tooltip';
import { axiosClient } from 'api/axios';
import { useToast } from 'components/ui/use-toast';

const ProductTable = ({
	data,
	isLoading,
	isError,
	error,
	sorting,
	setSorting,
	columnFilters,
	setColumnFilters,
	columnVisibility,
	setColumnVisibility,
	rowSelection,
	setRowSelection,
	page,
	per_page,
	handleEditClick,
	selectedRowIds,
	setSelectedRowIds,
	total,
	setPage,
	refetchProducts,
}) => {
	const { toast } = useToast();

	function getStockStatusColor(status) {
		switch (status) {
			case 'onbackorder':
				return 'text-orange-700 bg-orange-50 ring-orange-600/10 hover:bg-orange-100';
			case 'instock':
				return 'text-green-700 bg-green-50 ring-green-600/20 hover:bg-green-100';
			case 'outofstock':
				return 'text-red-700 bg-red-50 ring-red-600/10 hover:bg-red-100';
			default:
				return 'text-zinc-700 bg-zinc-50 ring-zinc-600/10 hover:bg-zinc-100';
		}
	}

	function formatStockStatus(status) {
		switch (status) {
			case 'onbackorder':
				return 'on backorder';
			case 'instock':
				return 'in stock';
			case 'outofstock':
				return 'out of stock';
			default:
				return status;
		}
	}

	const columns = [
		{
			accessorKey: 'images',
			header: () => <div className='text-center'>Name</div>,
			cell: ({ row }) => {
				const images = row.original.images;
				const name = row.original.name;
				const slug = row.original.slug;

				return (
					<div className='flex flex-row items-center space-x-3'>
						<div>
							{images && images.length > 0 && images[0].src ? (
								<img
									src={images[0].src}
									alt={images[0].alt || name}
									width={64}
									height={64}
									className='rounded-md object-cover'
								/>
							) : (
								<div className='w-16 h-16 bg-gray-200 rounded-md flex items-center justify-center text-gray-500 font-medium'>
									{name.charAt(0)}
								</div>
							)}
						</div>
						<div>
							<div className='text-sm font-medium'>{name}</div>
							<div className='text-xs text-gray-500'>{slug}</div>
						</div>
					</div>
				);
			},
		},
		{
			accessorKey: 'stock_status',
			header: 'Stock',
			cell: ({ row }) => (
				<Badge
					className={`${getStockStatusColor(
						row.getValue('stock_status')
					)} rounded-md py-1 px-2 text-xs font-medium ring-1`}>
					<div>{formatStockStatus(row.getValue('stock_status'))}</div>
				</Badge>
			),
		},
		{
			accessorKey: 'price',
			header: 'Price',
			cell: ({ row }) => <div>€{row.getValue('price')}</div>,
		},
		{
			accessorKey: 'total_sales',
			header: 'Total sales',
			cell: ({ row }) => <div>{row.getValue('total_sales')}</div>,
		},
		{
			accessorKey: 'date_created',
			header: 'Date',
			cell: ({ row }) => {
				const date = new Date(row.getValue('date_created'));
				return (
					<div className='text-zinc-500 text-sm'>
						{format(date, 'MMM d yyyy')}
					</div>
				);
			},
		},
		{
			accessorKey: 'categories',
			header: () => <div className='text-center'>Categories</div>,
			cell: ({ row }) => {
				const categories = row.original.categories;
				if (categories.length === 0) {
					return <span className='text-zinc-500'>-</span>;
				}
				if (categories.length === 1) {
					return <Badge variant='outline'>{categories[0].name}</Badge>;
				}
				return (
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger>
								<Badge variant='outline'>{categories[0].name}</Badge>
								{categories.length > 1 && <span className='ml-1 text-xs'>+{categories.length - 1}</span>}
							</TooltipTrigger>
							<TooltipContent>
								<div className='flex flex-col gap-1'>
									{categories.slice(1).map((category) => (
										<Badge key={category.id} variant='secondary'>
											{category.name}
										</Badge>
									))}
								</div>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				);
			},
		},
		{
			accessorKey: 'tags',
			header: () => <div className='text-center'>Tags</div>,
			cell: ({ row }) => {
				const tags = row.original.tags;
				if (tags.length === 0) {
					return <span className='text-zinc-500'>-</span>;
				}
				if (tags.length === 1) {
					return <Badge variant='outline'>{tags[0].name}</Badge>;
				}
				return (
					<TooltipProvider>
						<Tooltip>
							<TooltipTrigger>
								<Badge variant='outline'>{tags[0].name}</Badge>
								{tags.length > 1 && <span className='ml-1 text-xs'>+{tags.length - 1}</span>}
							</TooltipTrigger>
							<TooltipContent>
								<div className='flex flex-col gap-1'>
									{tags.slice(1).map((tag) => (
										<Badge key={tag.id} variant='outline'>
											{tag.name}
										</Badge>
									))}
								</div>
							</TooltipContent>
						</Tooltip>
					</TooltipProvider>
				);
			},
		},
	];

	const bulkActions = [
		{
			label: 'Delete',
			action: async (selectedIds) => {
				try {
					for (const id of selectedIds) {
						await axiosClient.delete(`/products/${id}`, {
							params: { force: true }
						});
					}
					toast({
						title: "Success",
						description: `${selectedIds.length} product(s) deleted successfully.`,
					});
					if (typeof refetchProducts === 'function') {
						refetchProducts(); // Refetch products to update the table
					} else {
						console.warn('refetchProducts is not a function');
						console.error('Error deleting products:', error);
					}
				} catch (error) {
					console.error('Error deleting products:', error);
					toast({
						title: "Error",
						description: `Failed to delete products. ${error.response?.data?.message || error.message}`,
						variant: "destructive",
					});
				}
			},
		},
		{
			label: 'Duplicate',
			action: async (selectedIds) => {
				try {
					for (const id of selectedIds) {
						await axiosClient.post(`/products/${id}/duplicate`);
					}
					toast({
						title: "Success",
						description: `${selectedIds.length} product(s) duplicated successfully.`,
					});
					if (typeof refetchProducts === 'function') {
						refetchProducts(); // Refetch products to update the table
					} else {
						console.warn('refetchProducts is not a function');
					}
				} catch (error) {
					console.error('Error duplicating products:', error);
					toast({
						title: "Error",
						description: `Failed to duplicate products. ${error.response?.data?.message || error.message}`,
						variant: "destructive",
					});
				}
			},
		},
		// ... any other existing bulk actions
	];

	return (
		<GenericTable
			data={data}
			columns={columns}
			isLoading={isLoading}
			isError={isError}
			error={error}
			sorting={sorting}
			setSorting={setSorting}
			columnFilters={columnFilters}
			setColumnFilters={setColumnFilters}
			columnVisibility={columnVisibility}
			setColumnVisibility={setColumnVisibility}
			rowSelection={rowSelection}
			setRowSelection={setRowSelection}
			page={page}
			per_page={per_page}
			handleEditClick={handleEditClick}
			selectedRowIds={selectedRowIds}
			setSelectedRowIds={setSelectedRowIds}
			total={total}
			setPage={setPage}
			bulkActions={bulkActions}
		/>
	);
};

export default ProductTable;