import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const checkAuth = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const token = urlParams.get('token');
            const cookieData = Cookies.get('storeui_auth');

            if (token || cookieData) {
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(false);
            }
            setIsLoading(false);
        };

        // We'll use a small delay to allow the axios cookie creation to complete
        const timeoutId = setTimeout(checkAuth, 100);

        return () => clearTimeout(timeoutId);
    }, []);

    return { isAuthenticated, isLoading };
};