import React from 'react';
import { Button } from 'components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from 'components/ui/dialog';

const DeleteModal = ({
	isOpen,
	onClose,
	onConfirm,
	title,
	description,
	cancelText = 'Cancel',
	confirmText = 'Delete',
}) => {
	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>{title}</DialogTitle>
					<DialogDescription>{description}</DialogDescription>
				</DialogHeader>
				<DialogFooter>
					<Button variant='outline' onClick={onClose}>
						{cancelText}
					</Button>
					<Button
						onClick={onConfirm}
					>
						{confirmText}
					</Button>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	);
};

export default DeleteModal;
