import { axiosClient } from 'api/axios';

export const PRODUCT_REVIEW_API = {
	GET_PRODUCT_REVIEWS: {
		KEY: 'getProductReviews',
		FN: ({ productId, params }) =>
			axiosClient
				.get(`/products/reviews`, {
					params: {
						product: productId,
						status: 'all',
						...params },
				})
				.then((response) => response.data),
	},
	UPDATE_REVIEW_STATUS: {
		KEY: 'updateReviewStatus',
		FN: ({ reviewId, status }) =>
			axiosClient
				.put(`/products/reviews/${reviewId}`, { status })
				.then((response) => response.data),
	},
	DELETE_REVIEW: {
		KEY: 'deleteReview',
		FN: ({ reviewId }) =>
			axiosClient
				.delete(`/products/reviews/${reviewId}`)
				.then((response) => response.data),
	},
};
