import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SETUP_API } from 'api/setup';
import { Button } from 'components/ui/button';
import { Form, Formik } from 'formik';
import React from 'react';

import { initialValuesGenerator, renderField } from './render-fields';
import { useToast } from 'components/ui/use-toast';
import { LoadingSpinner } from 'components/ui/loadingSpinner';
import NetworkError from 'components/ui/networkError';

const General = () => {
	const { toast } = useToast();
	const cache = useQueryClient();

	const updateGeneralMutation = useMutation({
		mutationFn: SETUP_API.UPDATE_GENERAL.FN,
		mutationKey: SETUP_API.UPDATE_GENERAL.KEY,
		onError: (error) => {
			console.log('Error updating general: ', error);
			toast({
				title: 'Error',
				description:
					'There was a problem updating the general settings.',
				variant: 'destructive',
			});
		},
		onSuccess: (data) => {
			toast({
				title: 'Success',
				description: 'Your general settings were updated successfully.',
			});
			cache.invalidateQueries({
				queryKey: [SETUP_API.UPDATE_GENERAL.KEY],
			});
		},
	});

	const handleSubmit = async (values) => {
		try {
			await updateGeneralMutation.mutateAsync({
				values,
			});
		} catch (error) {
			console.log('Error updating general: ', error);
		}
	};

	//console.log("updateGeneralMutation", updateGeneralMutation);

	const generalQuery = useQuery({
		queryFn: () => SETUP_API.GET_GENERAL.FN(),
		queryKey: [SETUP_API.GET_GENERAL.KEY],
	});

	const apiData = generalQuery?.data ?? [];

	if (generalQuery.isPending) {
		return <LoadingSpinner />;
	}

	if (generalQuery.error) {
		return 	(
			<NetworkError
				message={ generalQuery.error }
			/>
		)
	}

	if (!generalQuery.data) {
		return null;
	}

	return (
		<Formik
			initialValues={initialValuesGenerator(apiData)}
			onSubmit={handleSubmit}>
			{({ isSubmitting }) => (
				<Form className='flex flex-col gap-4 p-4'>
					{apiData.map((field) => (
						<div key={field.id}>{renderField(field)}</div>
					))}
					<div className='flex justify-end'>
						<Button
							type='submit'
							className='w-32'
							disabled={
								isSubmitting || updateGeneralMutation.isPending
							}>
							{isSubmitting || updateGeneralMutation.isPending
								? 'Saving...'
								: 'Save'}
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default General;