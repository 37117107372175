import { axiosClient } from 'api/axios';

export const getCountries = async () => {
	try {
		const { data } = await axiosClient.get('/data/countries');
		return data;
	} catch (error) {
		console.error('Error fetching countries:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const getContinents = async () => {
	try {
		const { data } = await axiosClient.get('/data/continents');
		return data;
	} catch (error) {
		console.error('Error fetching continents:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const getCurrencies = async () => {
	try {
		const { data } = await axiosClient.get('/data/currencies');
		return data;
	} catch (error) {
		console.error('Error fetching currencies:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const DATA_API = {
	GET_COUNTRIES: {
		KEY: 'GET_COUNTRIES',
		FN: getCountries,
	},
	GET_CONTINENTS: {
		KEY: 'GET_CONTINENTS',
		FN: getContinents,
	},
	GET_CURRENCIES: {
		KEY: 'GET_CURRENCIES',
		FN: getCurrencies,
	},
};
