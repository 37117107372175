import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SETUP_API } from "api/setup";
import { Button } from "components/ui/button";
import { Form, Formik } from "formik";
import React from "react";
import { initialValuesGenerator, renderField } from "./render-fields";
import { useToast } from "components/ui/use-toast";
import { LoadingSpinner } from "components/ui/loadingSpinner";
import NetworkError from 'components/ui/networkError';

const Account = () => {
  const { toast } = useToast();
  const cache = useQueryClient();

  const updateAccountMutation = useMutation({
    mutationFn: SETUP_API.UPDATE_ACCOUNT.FN,
    mutationKey: SETUP_API.UPDATE_ACCOUNT.KEY,
    onError: (error) => {
      console.log("Error Updateing account: ", error);
      toast({
        title: "Error",
        description: "There was a problem updating the account settings.",
        variant: "destructive",
      });
    },
    onSuccess: (data) => {
      toast({
        title: "Success",
        description: "Your account settings were updated successfully.",
      });
      cache.invalidateQueries({
        queryKey: [SETUP_API.UPDATE_ACCOUNT.KEY],
      });
    },
  });

  const handleSubmit = async (values) => {
    try {
      await updateAccountMutation.mutateAsync({
        values,
      });
    } catch (error) {
      console.log("Error updating account: ", error);
    }
  };

  const accountQuery = useQuery({
    queryFn: () => SETUP_API.GET_ACCOUNT.FN(),
    queryKey: [SETUP_API.GET_ACCOUNT.KEY],
  });

  const apiData = accountQuery?.data ?? [];

  if (accountQuery.isPending) {
    return <LoadingSpinner />;
  }

  if (accountQuery.error) {
	return 	(
		<NetworkError
			message={ accountQuery.error }
		/>
	)
}

  if (!accountQuery.data) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValuesGenerator(apiData)}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="flex flex-col gap-4 p-4">
          {apiData.map((field) => (
            <div key={field.id}>{renderField(field)}</div>
          ))}
          <div className="flex justify-end">
            <Button
              type="submit"
              className="w-32"
              disabled={isSubmitting || updateAccountMutation.isPending}
              aria-disabled="true"
            >
              {isSubmitting || updateAccountMutation.isPending
                ? "Saving..."
                : "Save"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Account;
