import { CardTitle } from "components/ui/card";
import React from "react";

const FormHeading = ({ name, label }) => {
  return (
    <div className="flex flex-col py-4">
      <CardTitle key={name} className="text-md pb-2">
        {label}
      </CardTitle>
      <hr />
    </div>
  );
};

export default FormHeading;
