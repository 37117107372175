import React, { useState, useEffect } from 'react';
import { Button } from 'components/ui/button';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from 'components/ui/dialog';
import { AlertCircle, Send } from 'lucide-react';

const FakeChat = ({ isOpen, onOpenChange, customerName, orderId }) => {
	const [message, setMessage] = useState('');
	const [messages, setMessages] = useState([
		{
			sender: 'customer',
			content: 'Hello! I have a question about my recent order.',
			time: '10:30 AM',
		},
		{
			sender: 'support',
			content: 'Hi there! How can I help you?',
			time: '10:32 AM',
		},
		{
			sender: 'customer',
			content: 'I was wondering about the estimated delivery date.',
			time: '10:33 AM',
		},
		{
			sender: 'support',
			content: 'Certainly! Let me check that for you.',
			time: '10:35 AM',
		},
	]);

	const generateFakeResponse = () => {
		const responses = [
			'Thank you for the information!',
			"I see, that's helpful.",
			'Could you please clarify that?',
			'Is there anything else I should know?',
			"That's great, thanks for your help!",
			'I understand. What should I do next?',
			"Okay, I'll wait for further updates.",
			'That makes sense. I appreciate your assistance.',
			'Got it, thanks for explaining.',
			"Alright, I'll keep that in mind.",
		];
		return responses[Math.floor(Math.random() * responses.length)];
	};

	const handleSendMessage = () => {
		if (message.trim()) {
			const newSupportMessage = {
				sender: 'support',
				content: message,
				time: new Date().toLocaleTimeString([], {
					hour: '2-digit',
					minute: '2-digit',
				}),
			};
			setMessages((prev) => [...prev, newSupportMessage]);
			setMessage('');

			// Simulate customer response after a short delay
			setTimeout(() => {
				const fakeResponse = generateFakeResponse();
				const newCustomerMessage = {
					sender: 'customer',
					content: fakeResponse,
					time: new Date().toLocaleTimeString([], {
						hour: '2-digit',
						minute: '2-digit',
					}),
				};
				setMessages((prev) => [...prev, newCustomerMessage]);
			}, 1000 + Math.random() * 2000); // Random delay between 1-3 seconds
		}
	};

	useEffect(() => {
		// Scroll to bottom when messages update
		const messageContainer = document.getElementById('message-container');
		if (messageContainer) {
			messageContainer.scrollTop = messageContainer.scrollHeight;
		}
	}, [messages]);

	return (
		<Dialog open={isOpen} onOpenChange={onOpenChange}>
			<DialogContent className='sm:max-w-[500px] p-0 overflow-hidden'>
				<DialogHeader className='px-6 py-4 bg-primary-50'>
					<DialogTitle className='text-xl font-semibold text-primary-800'>
						Conversation with {customerName} on order #{orderId}
					</DialogTitle>
				</DialogHeader>
				<div className='flex flex-col h-[500px]'>
					<div className='bg-yellow-50 p-4 border-b border-yellow-200'>
						<div className='flex items-center'>
							<AlertCircle className='h-5 w-5 mr-2 text-yellow-600' />
							<div className='text-sm text-yellow-700 font-medium'>
								This is a preview of an upcoming feature. Direct
								messaging with customers is not yet available.
							</div>
						</div>
					</div>
					<div
						id='message-container'
						className='flex-1 overflow-y-auto p-6 space-y-4'>
						{messages.map((msg, index) => (
							<div
								key={index}
								className={`flex ${
									msg.sender === 'support'
										? 'justify-end'
										: 'justify-start'
								}`}>
								<div
									className={`max-w-[70%] p-3 rounded-lg shadow-sm ${
										msg.sender === 'support'
											? 'bg-primary text-white'
											: 'bg-gray-100 text-gray-800'
									}`}>
									<div className='text-xs font-semibold mb-1'>
										{msg.sender === 'support'
											? 'Me'
											: customerName}
									</div>
									<div className='text-sm'>{msg.content}</div>
									<div className='text-xs mt-1 opacity-70'>
										{msg.time}
									</div>
								</div>
							</div>
						))}
					</div>
					<div className='border-t border-gray-200 p-4 bg-gray-50'>
						<div className='flex items-center space-x-2'>
							<input
								type='text'
								value={message}
								onChange={(e) => setMessage(e.target.value)}
								onKeyPress={(e) =>
									e.key === 'Enter' && handleSendMessage()
								}
								placeholder='Type your message...'
								className='flex-1 p-2 border border-primary rounded-md focus:outline-none focus:ring-2 focus:ring-primary/80 focus:border-transparent'
							/>
							<Button
								onClick={handleSendMessage}
								size='icon'
								className='bg-primary hover:bg-primary/80 text-white rounded-full w-10 h-10 flex items-center justify-center'>
								<Send className='h-5 w-5' />
							</Button>
						</div>
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default FakeChat;
