import React from "react";

import General from "./_ui/general";
import Product from "./_ui/products";
import Tax from "./_ui/tax";
import Shipping from "./_ui/shipping";
import Account from "./_ui/account";
import Email from "./_ui/emails";
import Advanced from "./_ui/advanced";
import Payments from "./_ui/payments";
import CustomTabs from "components/custom-tabs";
import SiteVisibility from "./_ui/site-visibility";
const Setup = () => {
  const tabs = [
    { value: "general", label: "General", content: <General /> },
    { value: "products", label: "Products", content: <Product /> },
    { value: "tax", label: "Tax", content: <Tax /> },
    { value: "shipping", label: "Shipping", content: <Shipping /> },
    { value: "payments", label: "Payments", content: <Payments /> },
    {
      value: "account & privacy",
      label: "Account & Privacy",
      content: <Account />,
    },
    { value: "emails", label: "Emails", content: <Email /> },
    {
      value: "site visibility",
      label: "Site visibility",
      content: <SiteVisibility />,
    },
    { value: "advanced", label: "Advanced", content: <Advanced /> },
  ];

  return (
    <div className="p-4 max-w-5xl">
      <CustomTabs
        tabs={tabs}
        defaultValue="general"
        className="w-full border-none"
      />
    </div>
  );
};

export default Setup;
