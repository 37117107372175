import { jsPDF } from "jspdf";
import "jspdf-autotable";

export const generateInvoicePDF = (order) => {
    const doc = new jsPDF();

    // Add header
    doc.setFontSize(20);
    doc.setTextColor(40);
    doc.text("Invoice", 14, 22);

    // Add logo (if available)
    // doc.addImage("logo.png", "PNG", 170, 10, 30, 30);

    // Add order details
    doc.setFontSize(12);
    doc.setTextColor(80);
    doc.text(`Order #: ${order.id}`, 14, 40);
    doc.text(`Date: ${new Date(order.date_created).toLocaleDateString()}`, 14, 48);
    doc.text(`Payment Method: ${order.payment_method_title}`, 14, 56);

    // Add billing details
    doc.setFontSize(14);
    doc.setTextColor(40);
    doc.text("Bill To:", 14, 70);
    doc.setFontSize(12);
    doc.setTextColor(80);
    doc.text(`${order.billing.first_name} ${order.billing.last_name}`, 14, 78);
    doc.text(order.billing.address_1, 14, 86);
    doc.text(`${order.billing.city}, ${order.billing.state} ${order.billing.postcode}`, 14, 94);
    doc.text(order.billing.country, 14, 102);
    doc.text(`Email: ${order.billing.email}`, 14, 110);
    doc.text(`Phone: ${order.billing.phone}`, 14, 118);

    // Add shipping details (if different from billing)
    if (order.shipping.address_1 !== order.billing.address_1) {
        doc.setFontSize(14);
        doc.setTextColor(40);
        doc.text("Ship To:", 120, 70);
        doc.setFontSize(12);
        doc.setTextColor(80);
        doc.text(`${order.shipping.first_name} ${order.shipping.last_name}`, 120, 78);
        doc.text(order.shipping.address_1, 120, 86);
        doc.text(`${order.shipping.city}, ${order.shipping.state} ${order.shipping.postcode}`, 120, 94);
        doc.text(order.shipping.country, 120, 102);
    }

    // Add items table
    const tableColumn = ["Item", "Quantity", "Price", "Total"];
    const tableRows = order.line_items.map(item => [
        item.name,
        item.quantity,
        `${order.currency_symbol}${parseFloat(item.price).toFixed(2)}`,
        `${order.currency_symbol}${(parseFloat(item.price) * item.quantity).toFixed(2)}`
    ]);

    doc.autoTable({
        startY: 130,
        head: [tableColumn],
        body: tableRows,
        headStyles: { fillColor: [40, 40, 40], textColor: 255 },
        alternateRowStyles: { fillColor: [245, 245, 245] },
        columnStyles: {
            0: { cellWidth: 80 },
            1: { cellWidth: 30, halign: 'center' },
            2: { cellWidth: 40, halign: 'right' },
            3: { cellWidth: 40, halign: 'right' }
        }
    });

    // Add totals
    const finalY = doc.lastAutoTable.finalY || 130;
    doc.setFontSize(12);
    doc.setTextColor(80);
    
    doc.text(`Subtotal:`, 140, finalY + 20);
    doc.text(`${order.currency_symbol}${parseFloat(order.total).toFixed(2)}`, 190, finalY + 20, { align: 'right' });
    
    doc.text(`Shipping:`, 140, finalY + 30);
    doc.text(`${order.currency_symbol}${parseFloat(order.shipping_total).toFixed(2)}`, 190, finalY + 30, { align: 'right' });
    
    doc.text(`Tax:`, 140, finalY + 40);
    doc.text(`${order.currency_symbol}${parseFloat(order.total_tax).toFixed(2)}`, 190, finalY + 40, { align: 'right' });
    
    doc.setFontSize(14);
    doc.setTextColor(40);
    doc.text(`Total:`, 140, finalY + 55);
    doc.text(`${order.currency_symbol}${parseFloat(order.total).toFixed(2)}`, 190, finalY + 55, { align: 'right' });

    // Add footer
    doc.setFontSize(10);
    doc.setTextColor(150);
    doc.text("Thank you for your business!", 14, 280);

    // Save the PDF
    doc.save(`invoice_order_${order.id}.pdf`);
};