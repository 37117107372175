import React from "react";
import { Tabs, TabsList, TabsTrigger, TabsContent } from "components/ui/tabs";

const CustomTabs = ({ tabs, defaultValue, className }) => {
  return (
    <Tabs defaultValue={defaultValue} className="box-shadow-none">
      <TabsList className={`w-full flex items-start justify-start text-zinc-500 gap-3 ${className}`}>
        {tabs.map((tab) => (
          <TabsTrigger
            key={tab.value}
            value={tab.value}
            className=""
          >
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>
      {tabs.map((tab) => (
        <TabsContent key={tab.value} value={tab.value}>
          {tab.content}
        </TabsContent>
      ))}
    </Tabs>
  );
};
export default CustomTabs;
