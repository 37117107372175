import React, { useState, useEffect } from 'react';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from 'components/ui/select';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CATEGORIE_API } from 'api/categories';
import { useToast } from 'components/ui/use-toast';
import { Form, Field, Formik } from 'formik';

const displayTypeOptions = [
	{ value: 'default', label: 'Default' },
	{ value: 'products', label: 'Products' },
	{ value: 'subcategories', label: 'Subcategories' },
	{ value: 'both', label: 'Both' },
];

const CategoryDialog = ({
	isOpen,
	onClose,
	category,
	allCategories,
	onCategoryCreated,
}) => {
	const { toast } = useToast();
	const queryClient = useQueryClient();
	const [isReady, setIsReady] = useState(false);

	const isEditMode = !!category;

	useEffect(() => {
		if (isOpen) {
			setIsReady(true);
		} else {
			setIsReady(false);
		}
	}, [isOpen]);

	const mutation = useMutation({
		mutationFn: isEditMode
			? CATEGORIE_API.UPDATE_CATEGORIES.FN
			: CATEGORIE_API.ADD_CATEGORIES.FN,
		onSuccess: (data) => {
			queryClient.invalidateQueries([
				CATEGORIE_API.GET_ALL_CATEGORIES.KEY,
			]);
			toast({
				title: 'Success',
				description: `Category ${
					isEditMode ? 'updated' : 'created'
				} successfully.`,
			});
			if (!isEditMode) {
				onCategoryCreated(data);
			}
			onClose();
		},
		onError: (error) => {
			toast({
				title: 'Error',
				description: `Failed to ${
					isEditMode ? 'update' : 'create'
				} category.`,
				variant: 'destructive',
			});
		},
	});

	const handleSubmit = (values) => {
		const data = {
			name: values.name,
			parent: values.parent === '0' ? 0 : parseInt(values.parent, 10),
			display: values.display,
		};

		if (values.description) {
			data.description = values.description;
		}

		if (values.slug) {
			data.slug = values.slug;
		}

		if (isEditMode) {
			mutation.mutate({ id: category.id, data });
		} else {
			mutation.mutate({ data });
		}
	};

	const initialValues = {
		name: category?.name || '',
		slug: category?.slug || '',
		description: category?.description || '',
		parent: category?.parent?.toString() || '0',
		display: category?.display || 'default',
	};

	if (!isReady) return null;

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						{isEditMode ? 'Edit Category' : 'Create New Category'}
					</DialogTitle>
					<DialogDescription>
						{isEditMode
							? 'Editing a category allows you to update its name, slug, description, and parent category.'
							: 'Creating a new category allows you to add a new category to the store.'}
					</DialogDescription>
				</DialogHeader>
				<Formik
					initialValues={initialValues}
					onSubmit={handleSubmit}
					enableReinitialize>
					{({ values, setFieldValue }) => (
						<Form className='space-y-4'>
							<div className='space-y-2'>
								<Label htmlFor='name'>Name</Label>
								<Field name='name' as={Input} />
							</div>
							<div className='space-y-2'>
								<Label htmlFor='slug'>Slug (optional)</Label>
								<Field name='slug' as={Input} />
							</div>
							<div className='space-y-2'>
								<Label htmlFor='description'>Description</Label>
								<Field
									name='description'
									as='textarea'
									className='w-full h-24 p-2 border rounded'
								/>
							</div>
							<div className='space-y-2'>
								<Label htmlFor='parent'>Parent Category</Label>
								<Select
									value={values.parent}
									onValueChange={(value) =>
										setFieldValue('parent', value)
									}>
									<SelectTrigger>
										<SelectValue placeholder='Select parent category' />
									</SelectTrigger>
									<SelectContent>
										<SelectItem value='0'>
											None (top-level category)
										</SelectItem>
										{allCategories
											.filter(
												(cat) => cat.id !== category?.id
											)
											.map((cat) => (
												<SelectItem
													key={cat.id}
													value={cat.id.toString()}>
													{cat.name}
												</SelectItem>
											))}
									</SelectContent>
								</Select>
							</div>
							<div className='space-y-2'>
								<Label htmlFor='display'>Display Type</Label>
								<Select
									value={values.display}
									onValueChange={(value) =>
										setFieldValue('display', value)
									}>
									<SelectTrigger>
										<SelectValue placeholder='Select display type' />
									</SelectTrigger>
									<SelectContent>
										{displayTypeOptions.map((option) => (
											<SelectItem
												key={option.value}
												value={option.value}>
												{option.label}
											</SelectItem>
										))}
									</SelectContent>
								</Select>
							</div>
							<div className='flex justify-end space-x-2'>
								<Button
									type='button'
									variant='outline'
									onClick={onClose}>
									Cancel
								</Button>
								<Button
									type='submit'
									disabled={mutation.isPending}>
									{mutation.isPending
										? 'Saving...'
										: isEditMode
										? 'Save Changes'
										: 'Create Category'}
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</DialogContent>
		</Dialog>
	);
};

export default CategoryDialog;
