import React from 'react';
import { Formik, Form } from 'formik';
import {
	Sheet,
	SheetContent,
	SheetHeader,
	SheetTitle,
} from 'components/ui/sheet';
import { Button } from 'components/ui/button';
import { ScrollArea } from 'components/ui/scroll-area';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CATEGORIE_API } from 'api/categories';
import { useToast } from 'components/ui/use-toast';
import { renderNormalField } from 'components/renderNormalField';
import ReusableDialogButton from 'components/reusable-dialog-button';
import MediaLibrarySelect from 'components/ui/MediaLibrarySelect';

const CategorieSheet = ({ isOpen, onClose, category, page, isEdit }) => {
	const { toast } = useToast();
	const cache = useQueryClient();
	const categoriesQuery = useQuery({
		queryFn: () =>
			CATEGORIE_API.GET_ALL_CATEGORIES.FN({
				params: { per_page: 100 },
			}),
		queryKey: [CATEGORIE_API.GET_ALL_CATEGORIES.KEY],
	});

	const updateCategorieMutation = useMutation({
		mutationFn: CATEGORIE_API.UPDATE_CATEGORIES.FN,
		mutationKey: CATEGORIE_API.UPDATE_CATEGORIES.KEY,
		onError: (error) => {
			console.log('Error updating categorie: ', error);
			toast({
				title: 'Error',
				description: 'There was a problem updating the categorie.',
				variant: 'destructive',
			});
		},
		onSuccess: (data) => {
			onClose();
			toast({
				title: 'Success',
				description: 'Your categorie was updated successfully.',
			});
			cache.invalidateQueries({
				queryKey: [CATEGORIE_API.GET_ALL_CATEGORIES.KEY, page],
			});
		},
	});

	const addCategorieMutation = useMutation({
		mutationFn: CATEGORIE_API.ADD_CATEGORIES.FN,
		mutationKey: CATEGORIE_API.ADD_CATEGORIES.KEY,
		onError: (error) => {
			console.log('Error Adding categorie: ', error);
			toast({
				title: 'Error',
				description: 'There was a problem Adding the categorie.',
				variant: 'destructive',
			});
		},
		onSuccess: (data) => {
			onClose();
			toast({
				title: 'Success',
				description: 'Your categorie was Added successfully.',
			});
			cache.invalidateQueries({
				queryKey: [CATEGORIE_API.ADD_CATEGORIES.KEY, page],
			});
		},
	});

	const deleteCategorieMutation = useMutation({
		mutationFn: () =>
			CATEGORIE_API.DELETE_CATEGORIES.FN({ id: category.id }),
		mutationKey: CATEGORIE_API.DELETE_CATEGORIES.KEY,
		onSuccess: (data) => {
			console.log('Categorie deleted successfully:', data);
			onClose();
			toast({
				title: 'Success',
				description: 'Your categorie was deleted successfully.',
			});
			cache.invalidateQueries({
				queryKey: [CATEGORIE_API.GET_ALL_CATEGORIES.KEY, page],
			});
		},
		onError: (error) => {
			console.log('Error deleting categorie:', error);
			toast({
				title: 'Error',
				description:
					error.message ||
					'There was a problem deleting the categorie.',
				variant: 'destructive',
			});
		},
	});
	const handleDeleteButtonClick = () => {
		if (category.id) {
			deleteCategorieMutation.mutate(category.id);
		} else {
			console.error('No categorie id provided for deletion');
			toast({
				title: 'Error',
				description: 'Unable to delete. No categorie id available.',
				variant: 'destructive',
			});
		}
	};
	const handleSubmit = async (values) => {
		const data = {
			name: values.name,
			slug: values.slug,
			display: values.display.value,
			parent: values.parent.value,
			description: values.description,
		};

		// If there's an image, include it in the data
		if (values.image) {
			data.image = { src: values.image };
		} else if (isEdit) {
			// If we're editing and the image is empty, set it to null to remove it
			data.image = null;
		}

		if (isEdit) {
			try {
				await updateCategorieMutation.mutateAsync({
					id: category.id,
					data: data,
				});
			} catch (error) {
				console.log('Error updating categorie: ', error);
			}
		} else {
			try {
				await addCategorieMutation.mutateAsync({
					data: data,
				});
			} catch (error) {
				console.log('Error adding categorie: ', error);
			}
		}
	};

	const displayTypeOptions = [
		{
			value: 'default',
			label: 'Default',
		},
		{
			value: 'products',
			label: 'Products',
		},
		{
			value: 'subcategories',
			label: 'Subcategories',
		},
		{
			value: 'both',
			label: 'Both',
		},
	];
	const fields = [
		{ name: 'name', label: 'Name', type: 'text' },
		{ name: 'slug', label: 'Slug', type: 'text' },
		{
			name: 'description',
			label: 'Description',
			type: 'textarea',
		},

		{
			name: 'parent',
			label: 'Parent Category',
			type: 'select',
			options:
				categoriesQuery?.data?.map((category) => {
					return {
						value: category.id,
						label: category.name,
					};
				}) ?? [],
		},
		{
			name: 'display',
			label: 'Display type',
			type: 'select',
			options: displayTypeOptions,
		},
	];
	console.log('category', category?.display);

	return (
		<Sheet open={isOpen} scrollBehavior='inside' onOpenChange={onClose}>
			<SheetContent side='right' className='min-w-[680px]'>
				<SheetHeader>
					<SheetTitle className='mb-6'>
						{isEdit ? ' Category details' : ' Category details'}
					</SheetTitle>
				</SheetHeader>
				<ScrollArea className='w-full h-full pb-16'>
					<Formik
						initialValues={
							isEdit
								? {
										name: category?.name || '',
										slug: category?.slug || '',
										display: category?.display || '',
										parent: category?.parent || '',
										description:
											category?.description || '',
										image: category?.image?.src || '',
								  }
								: {
										name: '',
										slug: '',
										display: '',
										parent: '',
										description: '',
										image: '',
								  }
						}
						// validationSchema={CategorieSchema}
						onSubmit={handleSubmit}>
						{({ setFieldValue, values }) => (
							<Form className='flex flex-col gap-4 p-1 mr-4'>
								{fields.map((field) => (
									<div key={field.label}>
										{renderNormalField(field)}
									</div>
								))}

								<div className='mb-4'>
									<label className='block text-sm font-medium mb-1'>
										Category Image
									</label>
									<MediaLibrarySelect
										value={values.image}
										onChange={(imageUrl) =>
											setFieldValue('image', imageUrl)
										}
									/>
								</div>

								<div className='flex justify-start gap-4 flex-row mt-4 mb-8 items-center'>
									<Button
										type='submit'
										disabled={
											updateCategorieMutation.isPending ||
											addCategorieMutation.isPending
										}>
										{updateCategorieMutation.isPending ||
										addCategorieMutation.isPending
											? 'Saving...'
											: 'Save'}
									</Button>
									<Button variant='outline' onClick={onClose}>
										Cancel
									</Button>

									{isEdit && (
										<ReusableDialogButton
											dialogDescription='Are you sure you want to delete this category?'
											dialogTitle='This action cannot be undone. This will permanently delete the category and remove it from the database.'
											onConfirm={handleDeleteButtonClick}
											triggerText='Delete'
										/>
									)}

									<span className='text-sm text-zinc-500'>
										Changes will not be saved if you leave
										this page.
									</span>
								</div>
							</Form>
						)}
					</Formik>
				</ScrollArea>
			</SheetContent>
		</Sheet>
	);
};

export default CategorieSheet;
