import { useState } from 'react';
import { SHIPPING_API } from 'api/shipping';
import { DATA_API } from 'api/data';
import { PlusIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Select, SelectTrigger, SelectValue, SelectContent, SelectItem } from 'components/ui/select';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from 'components/ui/table';
import {
	Dialog,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTitle,
} from 'components/ui/dialog';
import { ScrollArea } from 'components/ui/scroll-area';
import { axiosClient } from 'api/axios';
import SimpleSelect from "components/simple-select";
import { Textarea } from 'components/ui/textarea';
import { useToast } from 'components/ui/use-toast';
import { Switch } from 'components/ui/switch';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

export default function ShippingZoneManager() {
    const queryClient = useQueryClient();
    const { toast } = useToast();

    // Fetch shipping zones
    const { data: zones, isLoading: zonesLoading, isError: zonesError, error: zonesErrorData } = useQuery({
        queryKey: [SHIPPING_API.GET_SHIPPING_ZONES.KEY],
        queryFn: SHIPPING_API.GET_SHIPPING_ZONES.FN,
    });

    // Fetch countries data
    const { data: countriesData, isLoading: countriesLoading, isError: countriesError, error: countriesErrorData } = useQuery({
        queryKey: [DATA_API.GET_COUNTRIES.KEY],
        queryFn: DATA_API.GET_COUNTRIES.FN,
    });

    // Create shipping zone mutation
    const createZoneMutation = useMutation({
        mutationFn: SHIPPING_API.CREATE_SHIPPING_ZONE.FN,
        onSuccess: () => {
            queryClient.invalidateQueries([SHIPPING_API.GET_SHIPPING_ZONES.KEY]);
            toast({
                title: "Success",
                description: "Shipping zone created successfully.",
            });
        },
        onError: (error) => {
            toast({
                title: "Error",
                description: `Failed to create shipping zone. ${error.message}`,
                variant: "destructive",
            });
        },
    });

    // Update shipping zone mutation
    const updateZoneMutation = useMutation({
        mutationFn: ({ zoneId, zoneData }) => SHIPPING_API.UPDATE_SHIPPING_ZONE.FN(zoneId, zoneData),
        onSuccess: () => {
            queryClient.invalidateQueries([SHIPPING_API.GET_SHIPPING_ZONES.KEY]);
            toast({
                title: "Success",
                description: "Shipping zone updated successfully.",
            });
        },
        onError: (error) => {
            toast({
                title: "Error",
                description: `Failed to update shipping zone. ${error.message}`,
                variant: "destructive",
            });
        },
    });

    // Delete shipping zone mutation
    const deleteZoneMutation = useMutation({
        mutationFn: SHIPPING_API.DELETE_SHIPPING_ZONE.FN,
        onSuccess: () => {
            queryClient.invalidateQueries([SHIPPING_API.GET_SHIPPING_ZONES.KEY]);
            toast({
                title: "Success",
                description: "Shipping zone deleted successfully.",
            });
        },
        onError: (error) => {
            toast({
                title: "Error",
                description: `Failed to delete shipping zone. ${error.message}`,
                variant: "destructive",
            });
        },
    });

    // Fetch zone details (locations and methods)
    const fetchZoneDetails = async (zoneId) => {
        const [locations, methods] = await Promise.all([
            SHIPPING_API.GET_ZONE_LOCATIONS.FN(zoneId),
            SHIPPING_API.GET_ZONE_METHODS.FN(zoneId),
        ]);
        setLocations(locations);
        setMethods(methods);
    };

    const [currentZone, setCurrentZone] = useState(null);
    const [locations, setLocations] = useState([]); // eslint-disable-line
    const [methods, setMethods] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingZone, setIsEditingZone] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isEditingMethod, setIsEditingMethod] = useState(false);
    const [currentMethod, setCurrentMethod] = useState(null);
    const [isManagingMethods, setIsManagingMethods] = useState(false);
    const [isEditingLocations, setIsEditingLocations] = useState(false);
    const [isSavingLocations, setIsSavingLocations] = useState(false);
    const [currentLocations, setCurrentLocations] = useState({ countries: [], states: [], postcodes: '' });
    const [isSavingZone, setIsSavingZone] = useState(false); // eslint-disable-line
    const [isSavingMethod, setIsSavingMethod] = useState(false);
    const [isDeletingMethod, setIsDeletingMethod] = useState(false);
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
    const [methodToDelete, setMethodToDelete] = useState(null);

    const handleAddZone = () => {
        setCurrentZone({ name: '', order: 0 });
        setIsEditing(true);
    };

    const handleEditZone = async (zone) => {
        setCurrentZone(zone);
        setIsEditing(true);
        setIsEditingZone(true);
        await fetchZoneDetails(zone.id);
    };

    const handleDeleteZone = (zone) => {
        setCurrentZone(zone);
        setIsDeleting(true);
    };

    const handleConfirmDelete = () => {
        if (currentZone) {
            deleteZoneMutation.mutate(currentZone.id);
            setIsDeleting(false);
            setCurrentZone(null);
        }
    };

    const handleSaveZone = async (e) => {
        e.preventDefault();
        if (currentZone) {
            if (currentZone.id) {
                updateZoneMutation.mutate({ zoneId: currentZone.id, zoneData: currentZone });
            } else {
                createZoneMutation.mutate(currentZone);
            }
            setIsEditing(false);
            setCurrentZone(null);
        }
    };

    const handleEditLocations = async () => {
        if (currentZone) {
            try {
                const response = await axiosClient.get(`/shipping/zones/${currentZone.id}/locations`);
                const locations = response.data;
               
                const countries = [];
                const states = [];
                let postcodes = '';

                locations.forEach(location => {
                    if (location.type === 'country') {
                        const codes = location.code.split(',');
                        codes.forEach(code => {
                            if (code.includes(':')) {
                                states.push(code);
                            } else {
                                countries.push(code);
                            }
                        });
                    } else if (location.type === 'postcode') {
                        postcodes = location.code;
                    }
                });

                setCurrentLocations({ countries, states, postcodes });
                setIsEditingLocations(true);
            } catch (error) {
                console.error('Error fetching zone locations:', error);
            }
        }
    };

    const handleSaveLocations = async (e) => {
        e.preventDefault();
        if (currentZone) {
            setIsSavingLocations(true);
            try {
                let updatedLocations = [];

                if (currentLocations.countries.length > 0 || currentLocations.states.length > 0) {
                    const countriesAndStates = [
                        ...currentLocations.countries,
                        ...currentLocations.states
                    ];
                    updatedLocations.push({ type: 'country', code: countriesAndStates.join(',') });
                }

                if (currentLocations.postcodes.trim()) {
                    updatedLocations.push({ type: 'postcode', code: currentLocations.postcodes.trim() });
                }

                await axiosClient.put(
                    `/shipping/zones/${currentZone.id}/locations`,
                    updatedLocations
                );
                fetchZoneDetails(currentZone.id);
                setIsEditingLocations(false);
                setCurrentLocations({ countries: [], states: [], postcodes: '' });
            } catch (error) {
                console.error('Error saving locations:', error);
            } finally {
                setIsSavingLocations(false);
            }
        }
    };

    const handleAddMethod = () => {
        setCurrentMethod({
            method_id: 'flat_rate',
            enabled: true,
            title: '',
            settings: {
                title: { value: 'Flat rate' },
                tax_status: { value: 'taxable' },
                cost: { value: '' },
            },
        });
        setIsEditingMethod(true);
    };

    const handleEditMethod = (method) => {
        setCurrentMethod({
            ...method,
            title: method.settings.title.value,
            settings: {
                ...method.settings,
                tax_status: { value: method.settings.tax_status.value },
                cost: { value: method.settings.cost.value }
            }
        });
        setIsEditingMethod(true);
    };

    const handleSaveMethod = async (e) => {
        e.preventDefault();
        if (currentMethod && currentZone) {
            setIsSavingMethod(true);
            try {
                const methodData = {
                    method_id: currentMethod.method_id,
                    method_title: currentMethod.title,
                    enabled: true,
                    settings: {
                        title: currentMethod.title,
                        tax_status: currentMethod.settings.tax_status.value,
                        cost: currentMethod.settings.cost.value,
                    }
                };

                let response;
                if (currentMethod.instance_id) {
                    response = await axiosClient.put(
                        `/shipping/zones/${currentZone.id}/methods/${currentMethod.instance_id}`,
                        methodData
                    );
                } else {
                    response = await axiosClient.post(
                        `/shipping/zones/${currentZone.id}/methods`,
                        methodData
                    );
                }

                console.log('API Response:', response.data);

                await fetchZoneDetails(currentZone.id);
                setIsEditingMethod(false);
                setCurrentMethod(null);
                toast({
                    title: "Success",
                    description: "Shipping method saved successfully.",
                });
            } catch (error) {
                console.error('Error saving method:', error);
                console.log('Error response:', error.response);
                toast({
                    title: "Error",
                    description: `Failed to save shipping method. ${error.response?.data?.message || error.message}`,
                    variant: "destructive",
                });
            } finally {
                setIsSavingMethod(false);
            }
        }
    };

    const handleDeleteMethod = (method) => {
        setMethodToDelete(method);
        setIsConfirmingDelete(true);
    };

    const confirmDeleteMethod = async () => {
        if (currentZone && methodToDelete) {
            setIsDeletingMethod(true);
            try {
                await axiosClient.delete(
                    `/shipping/zones/${currentZone.id}/methods/${methodToDelete.instance_id}`,
                    { params: { force: true } }
                );
                await fetchZoneDetails(currentZone.id);
                toast({
                    title: "Success",
                    description: `Shipping method "${methodToDelete.title}" has been deleted.`,
                });
                setIsConfirmingDelete(false);
            } catch (error) {
                console.error('Error deleting method:', error);
                toast({
                    title: "Error",
                    description: `Failed to delete shipping method. ${error.response?.data?.message || error.message}`,
                    variant: "destructive",
                });
            } finally {
                setIsDeletingMethod(false);
                setMethodToDelete(null);
            }
        }
    };

    // Reorder shipping zones by order - zero 
    const reorderZones = (zones) => { // eslint-disable-line
        zones.sort((a, b) => a.order - b.order);
        return zones;
    };

    if (zonesLoading || countriesLoading) {
        return <div>Loading data...</div>;
    }

    if (zonesError) {
        return <div>Error loading shipping zones: {zonesErrorData.message}</div>;
    }

    if (countriesError) {
        return <div>Error loading countries data: {countriesErrorData.message}</div>;
    }

    // Ensure zones and countriesData are arrays, even if they're empty
    const safeZones = Array.isArray(zones) ? zones : [];
    const safeCountriesData = Array.isArray(countriesData) ? countriesData : [];

    return (
        <div className='p-4'>
            <h2 className='text-2xl font-bold mb-4'>
                Shipping Zone Management
            </h2>
            {!isEditing && (
                <>
                    <Button onClick={handleAddZone} className='mb-4'>
                        <PlusIcon className='mr-2 h-4 w-4' /> Add Shipping Zone
                    </Button>

                    <ScrollArea className='h-[400px] rounded-md border'>
                        <Table>
                            <TableHeader className='bg-gray-50'>
                                <TableRow>
                                    <TableHead className='pl-4'>Name</TableHead>
                                    <TableHead>Order</TableHead>
                                    <TableHead className='text-right pr-4'>
                                        Actions
                                    </TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {safeZones.map((zone) => (
                                    <TableRow key={zone.id}>
                                        <TableCell className='font-medium'>
                                            {zone.name}
                                        </TableCell>
                                        <TableCell>{zone.order}</TableCell>
                                        <TableCell className='text-right'>
                                            <Button
                                                variant='ghost'
                                                size='icon'
                                                onClick={() => {
                                                    handleEditZone(zone);
                                                    setIsEditingZone(true);
                                                }}>
                                                <Pencil1Icon className='h-4 w-4' />
                                            </Button>
                                            <Button
                                                variant='ghost'
                                                size='icon'
                                                onClick={() =>
                                                    handleDeleteZone(zone)
                                                }>
                                                <TrashIcon className='h-4 w-4' />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </ScrollArea>
                </>
            )}
            {isEditing && currentZone && (
                <>
                    <form onSubmit={handleSaveZone} className='space-y-4'>
                        <h2 className='text-xl font-semibold mb-4'>
                            {currentZone.id
                                ? 'Edit Shipping Zone'
                                : 'Add Shipping Zone'}
                        </h2>
                        <div className='flex space-x-2 w-full'>
                            <div className='space-y-2 w-3/4'>
                                <Label htmlFor='name'>Zone Name</Label>
                                <Input
                                    id='name'
                                    value={currentZone.name}
                                    onChange={(e) =>
                                        setCurrentZone({
                                            ...currentZone,
                                            name: e.target.value,
                                        })
                                    }
                                    required
                                />
                            </div>
                            <div className='space-y-2 w-1/4'>
                                <Label htmlFor='order'>Order</Label>
                                <Input
                                    id='order'
                                    type='number'
                                    value={currentZone.order}
                                    onChange={(e) =>
                                        setCurrentZone({
                                            ...currentZone,
                                            order: parseInt(e.target.value),
                                        })
                                    }
                                    required
                                />
                            </div>
                        </div>
                        <div className='flex justify-end space-x-2'>
                            { isEditingZone && (
                                <div className='flex-1 flex space-x-2'>
                                    <Button
                                        onClick={handleEditLocations}
                                        variant='outline'
                                        type='button'
                                    >
                                        Manage Locations
                                    </Button>
                                    <Button
                                        onClick={() => setIsManagingMethods(true)}
                                        variant='outline'
                                        type='button'
                                    >
                                        Manage Shipping Methods
                                    </Button>
                                </div>
                            )}
                            <Button
                                type='button'
                                variant='outline'
                                onClick={() => {
                                    setIsEditing(false);
                                    setCurrentZone(null);
                                    setIsEditingZone(false);
                                }}>
                                Cancel
                            </Button>
                            <Button type='submit' disabled={isSavingZone}>
                                {isSavingZone ? 'Saving...' : 'Save zone'}
                            </Button>
                        </div>
                    </form>
                </>
            )}

            {/* Edit Locations Dialog */}
            <Dialog open={isEditingLocations} onOpenChange={setIsEditingLocations}>
                <DialogContent className="max-w-3xl">
                    <DialogHeader>
                        <DialogTitle>Edit Locations for {currentZone?.name}</DialogTitle>
                    </DialogHeader>
                    <form onSubmit={handleSaveLocations} className='space-y-4 p-4'>
                        <div className='space-y-2'>
                            <Label htmlFor='countries'>Countries</Label>
                            <SimpleSelect
                                options={safeCountriesData.map((country) => ({
                                    value: country.code,
                                    label: country.name,
                                }))}
                                value={currentLocations.countries.map(code => ({
                                    value: code,
                                    label: safeCountriesData.find(country => country.code === code)?.name || code
                                }))}
                                onChange={(selectedOptions) =>
                                    setCurrentLocations({
                                        ...currentLocations,
                                        countries: selectedOptions.map(option => option.value),
                                    })
                                }
                                isMulti={true}
                                placeholder="Select countries"
                            />
                        </div>
                        <div className='space-y-2'>
                            <Label htmlFor='states'>States</Label>
                            <SimpleSelect
                                options={safeCountriesData.flatMap(country => 
                                    (country.states || []).map(state => ({
                                        value: `${country.code}:${state.code}`,
                                        label: `${country.name} - ${state.name}`,
                                    }))
                                )}
                                value={currentLocations.states.map(code => {
                                    const [countryCode, stateCode] = code.split(':');
                                    const country = safeCountriesData.find(c => c.code === countryCode);
                                    const state = country?.states?.find(s => s.code === stateCode);
                                    return {
                                        value: code,
                                        label: state ? `${country.name} - ${state.name}` : code
                                    };
                                })}
                                onChange={(selectedOptions) =>
                                    setCurrentLocations({
                                        ...currentLocations,
                                        states: selectedOptions.map(option => option.value),
                                    })
                                }
                                isMulti={true}
                                placeholder="Select states"
                            />
                        </div>
                        <div className='space-y-2'>
                            <Label htmlFor='postcodes'>Postcodes</Label>
                            <Textarea
                                id='postcodes'
                                value={currentLocations.postcodes}
                                onChange={(e) =>
                                    setCurrentLocations({
                                        ...currentLocations,
                                        postcodes: e.target.value,
                                    })
                                }
                                placeholder="Enter postcodes separated by commas"
                            />
                            <p className="text-sm text-gray-500">
                                Enter postcodes separated by commas. Ranges are supported (e.g., 12345-12350).
                            </p>
                        </div>
                        <DialogFooter className='flex justify-end'>
                            <Button variant="outline" type='button' onClick={() => setIsEditingLocations(false)}>Cancel</Button>
                            <Button type='submit' disabled={isSavingLocations}>
                                {isSavingLocations ? 'Updating...' : 'Update Locations'}
                            </Button>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>

            {/* Manage Shipping Methods Modal */}
            <Dialog open={isManagingMethods} onOpenChange={setIsManagingMethods}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Manage Shipping Methods for {currentZone?.name}</DialogTitle>
                    </DialogHeader>
                    <ScrollArea className='h-[400px]'>
                        <div className='p-4 space-y-4'>
                            <Table>
                                <TableHeader>
                                    <TableRow>
                                        <TableHead>Method</TableHead>
                                        <TableHead>Title</TableHead>
                                        <TableHead className='text-right'>Actions</TableHead>
                                    </TableRow>
                                </TableHeader>
                                <TableBody>
                                    {methods.map((method) => (
                                        <TableRow key={method.instance_id}>
                                            <TableCell>{method.method_id}</TableCell>
                                            <TableCell>{method.title}</TableCell>
                                            <TableCell className='text-right'>
                                                <Button variant='ghost' size='icon' onClick={() => handleEditMethod(method)}>
                                                    <Pencil1Icon className='h-4 w-4' />
                                                </Button>
                                                <Button 
                                                    variant='ghost' 
                                                    size='icon' 
                                                    onClick={() => handleDeleteMethod(method)}
                                                    disabled={isDeletingMethod}
                                                >
                                                    <TrashIcon className='h-4 w-4' />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </div>
                    </ScrollArea>
                    <DialogFooter className='flex justify-end'>
                        <Button variant="outline" type='button' onClick={() => setIsManagingMethods(false)}>Cancel</Button>
                        <Button onClick={handleAddMethod}>
                            <PlusIcon className='mr-2 h-4 w-4' /> Add Shipping Method
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

            {/* Add/Edit Shipping Method Dialog */}
            <Dialog open={isEditingMethod} onOpenChange={setIsEditingMethod}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>{currentMethod?.instance_id ? 'Edit Shipping Method' : 'Add Shipping Method'}</DialogTitle>
                    </DialogHeader>
                    <ScrollArea className='h-[400px]'>
                        <form onSubmit={handleSaveMethod} className='space-y-4 p-4'>
                            <div className='space-y-2'>
                                <Label htmlFor='method_id'>Method</Label>
                                <Select
                                    id='method_id'
                                    value={currentMethod?.method_id || ''}
                                    onValueChange={(value) =>
                                        setCurrentMethod({
                                            ...currentMethod,
                                            method_id: value,
                                        })
                                    }
                                    required
                                >
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select a method" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value='flat_rate'>Flat Rate</SelectItem>
                                        <SelectItem value='free_shipping'>Free Shipping</SelectItem>
                                        <SelectItem value='local_pickup'>Local Pickup</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                            <div className='space-y-2'>
                                <Label htmlFor='title'>Method Title</Label>
                                <Input
                                    id='title'
                                    value={currentMethod?.title || ''}
                                    onChange={(e) =>
                                        setCurrentMethod({
                                            ...currentMethod,
                                            title: e.target.value,
                                            settings: {
                                                ...currentMethod.settings,
                                                title: { value: e.target.value },
                                            },
                                        })
                                    }
                                    required
                                />
                            </div>
                            {currentMethod?.method_id === 'flat_rate' && (
                                <>
                                    <div className='flex items-center space-x-2'>
                                        <Label htmlFor='tax_status'>Taxable</Label>
                                        <Switch
                                            id='tax_status'
                                            checked={currentMethod?.settings?.tax_status?.value === 'taxable'}
                                            onCheckedChange={(checked) =>
                                                setCurrentMethod({
                                                    ...currentMethod,
                                                    settings: {
                                                        ...currentMethod.settings,
                                                        tax_status: { value: checked ? 'taxable' : 'none' },
                                                    },
                                                })
                                            }
                                        />
                                    </div>
                                    <div className='space-y-2'>
                                        <Label htmlFor='cost'>Cost</Label>
                                        <Input
                                            id='cost'
                                            value={currentMethod?.settings?.cost?.value || ''}
                                            onChange={(e) =>
                                                setCurrentMethod({
                                                    ...currentMethod,
                                                    settings: {
                                                        ...currentMethod.settings,
                                                        cost: { value: e.target.value },
                                                    },
                                                })
                                            }
                                            placeholder='Enter cost or formula'
                                        />
                                        <p className='text-sm text-gray-500'>
                                            Enter a cost (excl. tax) or sum, e.g. 10.00 * [qty]. Use [qty] for the number of items, [cost] for the total cost of items.
                                        </p>
                                    </div>
                                </>
                            )}
                        </form>
                    </ScrollArea>
                    <DialogFooter>
                        <div className='flex justify-end space-x-2'>
                            <Button variant="outline" type='button' onClick={() => setIsEditingMethod(false)}>Cancel</Button>	
                            <Button 
                                type='submit' 
                                onClick={handleSaveMethod} 
                                disabled={isSavingMethod}
                            >
                                {isSavingMethod ? 'Saving...' : 'Save Method'}
                            </Button>
                        </div>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

            {/* Delete Confirmation Dialog */}
            <Dialog open={isDeleting} onOpenChange={setIsDeleting}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Delete Shipping Zone</DialogTitle>
                    </DialogHeader>
                    <p>Are you sure you want to delete the shipping zone "{currentZone?.name}"?</p>
                    <DialogFooter>
                        <Button variant="outline" type='button' onClick={() => setIsDeleting(false)}>Cancel</Button>
                        <Button onClick={handleConfirmDelete}>Delete</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>

            {/* Delete Shipping Method Confirmation Dialog */}
            <Dialog open={isConfirmingDelete} onOpenChange={setIsConfirmingDelete}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Delete Shipping Method</DialogTitle>
                    </DialogHeader>
                    <p>Are you sure you want to delete the shipping method "{methodToDelete?.title}"?</p>
                    <DialogFooter>
                        <Button variant="outline" onClick={() => setIsConfirmingDelete(false)}>Cancel</Button>
                        <Button onClick={confirmDeleteMethod} disabled={isDeletingMethod}>
                            {isDeletingMethod ? 'Deleting...' : 'Delete'}
                        </Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
}
