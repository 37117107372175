export const printOrder = (order) => {
    const printContent = `
        <html>
            <head>
                <title>Order #${order.id}</title>
                <style>
                    body { 
                        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                        line-height: 1.6;
                        color: #333;
                        max-width: 800px;
                        margin: 0 auto;
                        padding: 20px;
                    }
                    .header { 
                        text-align: center;
                        margin-bottom: 30px;
                        border-bottom: 2px solid #f0f0f0;
                        padding-bottom: 20px;
                    }
                    .header h1 {
                        color: #2c3e50;
                        font-size: 28px;
                    }
                    .order-details {
                        background-color: #f9f9f9;
                        border-radius: 5px;
                        padding: 20px;
                        margin-bottom: 30px;
                    }
                    .order-details p {
                        margin: 10px 0;
                    }
                    .items-table {
                        width: 100%;
                        border-collapse: separate;
                        border-spacing: 0;
                    }
                    .items-table th, .items-table td {
                        border: 1px solid #e0e0e0;
                        padding: 12px;
                        text-align: left;
                    }
                    .items-table th {
                        background-color: #f2f2f2;
                        font-weight: 600;
                        text-transform: uppercase;
                        font-size: 14px;
                    }
                    .items-table tr:nth-child(even) {
                        background-color: #fafafa;
                    }
                    .total {
                        margin-top: 30px;
                        text-align: right;
                        font-size: 18px;
                        font-weight: bold;
                    }
                    @media print {
                        body { print-color-adjust: exact; -webkit-print-color-adjust: exact; }
                    }
                </style>
            </head>
            <body>
                <div class="header">
                    <h1>Order #${order.id}</h1>
                </div>
                <div class="order-details">
                    <p><strong>Date:</strong> ${new Date(order.date_created).toLocaleDateString()}</p>
                    <p><strong>Customer:</strong> ${order.billing.first_name} ${order.billing.last_name}</p>
                    <p><strong>Email:</strong> ${order.billing.email}</p>
                </div>
                <table class="items-table">
                    <thead>
                        <tr>
                            <th>Item</th>
                            <th>Quantity</th>
                            <th>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${order.line_items.map(item => `
                            <tr>
                                <td>${item.name}</td>
                                <td>${item.quantity}</td>
                                <td>${order.currency_symbol}${parseFloat(item.price).toFixed(2)}</td>
                            </tr>
                        `).join('')}
                    </tbody>
                </table>
                <div class="total">
                    <p>Total: ${order.currency_symbol}${parseFloat(order.total).toFixed(2)}</p>
                </div>
            </body>
        </html>
    `;

    const printWindow = window.open('', '_blank');
    printWindow.document.write(printContent);
    printWindow.document.close();

    printWindow.onafterprint = () => {
        printWindow.close();
    };

    printWindow.print();

    // Fallback for browsers that don't support onafterprint
    setTimeout(() => {
        if (!printWindow.closed) {
            printWindow.close();
        }
    }, 1000);
};

export const printPackingSlip = (order) => {
    const content = generatePackingSlipContent(order);
    const printWindow = window.open('', '_blank');
    printWindow.document.write(content);
    printWindow.document.close();

    printWindow.onafterprint = () => {
        printWindow.close();
    };

    printWindow.print();

    // Fallback for browsers that don't support onafterprint
    setTimeout(() => {
        if (!printWindow.closed) {
            printWindow.close();
        }
    }, 1000);
};

const generatePackingSlipContent = (order) => {
    return `
        <html>
            <head>
                <title>Packing Slip - Order #${order.id}</title>
                <style>
                    body {
                        font-family: 'Arial', sans-serif;
                        line-height: 1.6;
                        color: #333;
                        max-width: 800px;
                        margin: 0 auto;
                        padding: 20px;
                    }
                    .header {
                        text-align: center;
                        margin-bottom: 30px;
                        border-bottom: 2px solid #f0f0f0;
                        padding-bottom: 20px;
                    }
                    .header h1 {
                        color: #2c3e50;
                        font-size: 28px;
                        margin-bottom: 10px;
                    }
                    .order-info {
                        background-color: #f9f9f9;
                        border-radius: 5px;
                        padding: 15px;
                        margin-bottom: 20px;
                    }
                    .shipping-address {
                        margin-bottom: 20px;
                    }
                    .items-list {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    .items-list th, .items-list td {
                        border: 1px solid #ddd;
                        padding: 12px;
                        text-align: left;
                    }
                    .items-list th {
                        background-color: #f2f2f2;
                        font-weight: bold;
                    }
                    .items-list tr:nth-child(even) {
                        background-color: #f9f9f9;
                    }
                    .checkbox {
                        width: 20px;
                        height: 20px;
                        border: 2px solid #333;
                        display: inline-block;
                        margin-right: 10px;
                        vertical-align: middle;
                    }
                    @media print {
                        body { print-color-adjust: exact; -webkit-print-color-adjust: exact; }
                    }
                </style>
            </head>
            <body>
                <div class="header">
                    <h1>Packing Slip</h1>
                    <h2>Order #${order.id}</h2>
                </div>
                <div class="order-info">
                    <p><strong>Date:</strong> ${new Date(order.date_created).toLocaleDateString()}</p>
                </div>
                <div class="shipping-address">
                    <h3>Shipping Address:</h3>
                    <p>${order.shipping.first_name} ${order.shipping.last_name}</p>
                    <p>${order.shipping.address_1}</p>
                    <p>${order.shipping.city}, ${order.shipping.state} ${order.shipping.postcode}</p>
                    <p>${order.shipping.country}</p>
                </div>
                <h3>Items:</h3>
                <table class="items-list">
                    <thead>
                        <tr>
                            <th>Check</th>
                            <th>Quantity</th>
                            <th>Item</th>
                        </tr>
                    </thead>
                    <tbody>
                        ${order.line_items.map(item => `
                            <tr>
                                <td><div class="checkbox"></div></td>
                                <td>${item.quantity}</td>
                                <td>${item.name}</td>
                            </tr>
                        `).join('')}
                    </tbody>
                </table>
                <p><strong>Note:</strong> Please check each item as it is packed for shipment.</p>
            </body>
        </html>
    `;
};