import { useState, useEffect, useCallback } from 'react';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Link from '@tiptap/extension-link';
import { Button } from 'components/ui/button';
import { Textarea } from 'components/ui/textarea';
import {
	Bold,
	Italic,
	List,
	ListOrdered,
	Link as LinkIcon,
	Heading1,
	Heading2,
	Heading3,
	Quote,
	Minus,
	X,
	Undo,
	Redo,
	Eye,
	EyeOff,
} from 'lucide-react';

export function WYSIWYGEditor({ name, label, value, onChange }) {
	const [showHTML, setShowHTML] = useState(false);

	const handleChange = useCallback((newValue) => {
		if (typeof onChange === 'function') {
			onChange(newValue);
		}
	}, [onChange]);

	const editor = useEditor({
		extensions: [
			StarterKit.configure({
				html: true,
			}),
			Link.configure({
				openOnClick: false,
				HTMLAttributes: {
					class: 'text-blue-500 underline',
				},
			}),
		],
		content: value || '',
		editorProps: {
			attributes: {
				class: 'prose prose-sm sm:prose lg:prose-lg xl:prose-2xl mx-auto focus:outline-none',
			},
		},
		parseOptions: {
			preserveWhitespace: 'full',
		},
		onUpdate: ({ editor }) => {
			handleChange(editor.getHTML());
		},
	});

	useEffect(() => {
		if (editor && value !== editor.getHTML()) {
			editor.commands.setContent(value || '');
		}
	}, [value, editor]);

	if (!editor) {
		return null;
	}

	const addLink = () => {
		const url = window.prompt('Enter the URL');
		if (url) {
			editor
				.chain()
				.focus()
				.extendMarkRange('link')
				.setLink({ href: url })
				.run();
		}
	};

	const toggleHTMLView = () => {
		setShowHTML(!showHTML);
	};

	return (
		<div className='w-full max-w-4xl'>
			<label className='w-[300px] text-sm font-medium leading-none text-gray-900 peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
				{label}
			</label>
			<div className='mb-4 mt-4 flex flex-wrap gap-2'>
				<Button
					type='button'
					variant='outline'
					size='icon'
					onClick={() => editor.chain().focus().toggleBold().run()}
					className={editor.isActive('bold') ? 'bg-muted' : ''}>
					<Bold className='h-4 w-4' />
				</Button>
				<Button
					type='button'
					variant='outline'
					size='icon'
					onClick={() => editor.chain().focus().toggleItalic().run()}
					className={editor.isActive('italic') ? 'bg-muted' : ''}>
					<Italic className='h-4 w-4' />
				</Button>
				<Button
					type='button'
					variant='outline'
					size='icon'
					onClick={() =>
						editor.chain().focus().toggleBulletList().run()
					}
					className={editor.isActive('bulletList') ? 'bg-muted' : ''}>
					<List className='h-4 w-4' />
				</Button>
				<Button
					type='button'
					variant='outline'
					size='icon'
					onClick={() =>
						editor.chain().focus().toggleOrderedList().run()
					}
					className={
						editor.isActive('orderedList') ? 'bg-muted' : ''
					}>
					<ListOrdered className='h-4 w-4' />
				</Button>
				<Button
					type='button'
					variant='outline'
					size='icon'
					onClick={addLink}
					className={editor.isActive('link') ? 'bg-muted' : ''}>
					<LinkIcon className='h-4 w-4' />
				</Button>
				<Button
					type='button'
					variant='outline'
					size='icon'
					onClick={() =>
						editor.chain().focus().toggleHeading({ level: 1 }).run()
					}
					className={
						editor.isActive('heading', { level: 1 })
							? 'bg-muted'
							: ''
					}>
					<Heading1 className='h-4 w-4' />
				</Button>
				<Button
					type='button'
					variant='outline'
					size='icon'
					onClick={() =>
						editor.chain().focus().toggleHeading({ level: 2 }).run()
					}
					className={
						editor.isActive('heading', { level: 2 })
							? 'bg-muted'
							: ''
					}>
					<Heading2 className='h-4 w-4' />
				</Button>
				<Button
					type='button'
					variant='outline'
					size='icon'
					onClick={() =>
						editor.chain().focus().toggleHeading({ level: 3 }).run()
					}
					className={
						editor.isActive('heading', { level: 3 })
							? 'bg-muted'
							: ''
					}>
					<Heading3 className='h-4 w-4' />
				</Button>
				<Button
					type='button'
					variant='outline'
					size='icon'
					onClick={() =>
						editor.chain().focus().toggleBlockquote().run()
					}
					className={editor.isActive('blockquote') ? 'bg-muted' : ''}>
					<Quote className='h-4 w-4' />
				</Button>
				<Button
					type='button'
					variant='outline'
					size='icon'
					onClick={() =>
						editor.chain().focus().setHorizontalRule().run()
					}>
					<Minus className='h-4 w-4' />
				</Button>
				<Button
					type='button'
					variant='outline'
					size='icon'
					onClick={() =>
						editor
							.chain()
							.focus()
							.unsetAllMarks()
							.clearNodes()
							.run()
					}>
					<X className='h-4 w-4' />
				</Button>
				<Button
					type='button'
					variant='outline'
					size='icon'
					onClick={() => editor.chain().focus().undo().run()}>
					<Undo className='h-4 w-4' />
				</Button>
				<Button
					type='button'
					variant='outline'
					size='icon'
					onClick={() => editor.chain().focus().redo().run()}>
					<Redo className='h-4 w-4' />
				</Button>
				<Button
					type='button'
					variant='outline'
					size='icon'
					onClick={toggleHTMLView}>
					{showHTML ? (
						<Eye className='h-4 w-4' />
					) : (
						<EyeOff className='h-4 w-4' />
					)}
				</Button>
			</div>
			{showHTML ? (
				<Textarea
					value={editor.getHTML()}
					onChange={(e) => {
						editor.commands.setContent(e.target.value);
						handleChange(e.target.value);
					}}
					className='min-h-[300px] font-mono text-sm'
				/>
			) : (
				<EditorContent
					editor={editor}
					className='min-h-[300px] border rounded-md p-4 editor-content'
				/>
			)}
		</div>
	);
}
