import React, { useState } from 'react';
import { Button } from "components/ui/button";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "components/ui/dropdown-menu";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "components/ui/select";
import { ChevronDown, Printer, FileText, RefreshCw, Trash2, Check, Package } from 'lucide-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ORDER_API } from 'api/orders';
import { useToast } from 'components/ui/use-toast';
import ReusableDialogButton from 'components/reusable-dialog-button';
import RefundModal from '../order-summary/RefundModal';
import { printOrder, printPackingSlip } from '../print/PrintFunctions';
import { generateInvoicePDF } from '../pdf/GenerateInvoicePDF';
import { StickyNote } from 'lucide-react';
import NotesDialog from '../notes/NotesDialog';

const OrderActionsDropdown = ({ order, isEdit, deleteMutate, deleteLoading }) => {
    const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);
    const [isNotesDialogOpen, setIsNotesDialogOpen] = useState(false);
    const { toast } = useToast();
    const queryClient = useQueryClient();

    const updateOrderMutation = useMutation({
        mutationFn: ORDER_API.UPDATE_ORDER_BY_ID.FN,
        onSuccess: () => {
            queryClient.invalidateQueries([ORDER_API.GET_ORDER_BY_ID.KEY, { id: order.id }]);
            toast({
                title: 'Success',
                description: `Order status updated`,
            });
        },
        onError: (error) => {
            toast({
                title: 'Error',
                description: 'Failed to update order status',
                variant: 'destructive',
            });
        },
    });

    const handleOrderStatusChange = async (value) => {
        updateOrderMutation.mutate({
            id: order.id,
            updateData: { status: value },
        });
    };

	if (!order) return null;

    const handlePrintOrder = () => {
        printOrder(order);
    };

    const handleGenerateInvoice = () => {
        generateInvoicePDF(order);
    };

    const handleRefund = () => {
        setIsRefundModalOpen(true);
    };

    const handleOpenNotesDialog = () => {
        setIsNotesDialogOpen(true);
    };

    const handlePrintPackingSlip = () => {
        printPackingSlip(order);
    };

    const isOrderCompleted = order.status === 'completed';

    return (
        <div className="flex space-x-2">
            {isOrderCompleted ? (
				
                <div className="flex items-center border transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-green-700 bg-green-50 ring-green-600/20 gap-2 rounded-md py-1 px-4 text-sm font-medium ring-1 ring-inset">
                    <Check className="h-4 w-4" />
                    <span>Completed</span>
                </div>
            ) : (
                <Select onValueChange={handleOrderStatusChange} defaultValue={order.status}>
                    <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Update Status" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="processing">Processing</SelectItem>
                        <SelectItem value="completed">Completed</SelectItem>
                        <SelectItem value="on-hold">On Hold</SelectItem>
                        <SelectItem value="cancelled">Cancelled</SelectItem>
                        <SelectItem value="refunded">Refunded</SelectItem>
                        <SelectItem value="failed">Failed</SelectItem>
                    </SelectContent>
                </Select>
            )}

            <DropdownMenu>
                <DropdownMenuTrigger asChild>
                    <Button variant="outline">
                        More Actions <ChevronDown className="ml-2 h-4 w-4" />
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                    <DropdownMenuItem onClick={handlePrintOrder}>
                        <Printer className="mr-2 h-4 w-4" />
                        Print order
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={handlePrintPackingSlip}>
                        <Package className="mr-2 h-4 w-4" />
                        Print packing slip
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={handleGenerateInvoice}>
                        <FileText className="mr-2 h-4 w-4" />
                        Generate PDF invoice
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={handleRefund}>
                        <RefreshCw className="mr-2 h-4 w-4" />
                        Refund
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={handleOpenNotesDialog}>
                        <StickyNote className="mr-2 h-4 w-4" />
                        Order notes
                    </DropdownMenuItem>
                    {isEdit && order && order.id && !isOrderCompleted && (
                        <DropdownMenuItem>
                            <ReusableDialogButton
                                triggerText='Delete order'
                                onConfirm={() => deleteMutate({ id: order.id })}
                                dialogTitle='Delete Order'
                                dialogDescription='Are you sure you want to delete this order?'
                                cancelText='Cancel'
                                className='text-red-500 hover:text-red-600 flex items-center'
                                confirmText='Confirm'
                                isLoading={deleteLoading}
                            >
                                <Trash2 className="mr-2 h-4 w-4" />
                                Delete order
                            </ReusableDialogButton>
                        </DropdownMenuItem>
                    )}
                </DropdownMenuContent>
            </DropdownMenu>

            <RefundModal
                orderId={order.id}
                isOpen={isRefundModalOpen}
                onClose={() => setIsRefundModalOpen(false)}
            />
            <NotesDialog
                orderId={order.id}
                isOpen={isNotesDialogOpen}
                onClose={() => setIsNotesDialogOpen(false)}
            />
        </div>
    );
};

export default OrderActionsDropdown;