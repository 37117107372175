import { cn } from "lib/utils";
import { LoaderCircle } from "lucide-react";

export const LoadingSpinner = ({ className, containerClassName }) => {
  return (
    <div className={cn("flex items-center justify-center min-h-[100px]", containerClassName)}>
      <LoaderCircle
        className={cn("animate-spin h-14 w-14 text-zinc-300", className)}
      />
    </div>
  );
};