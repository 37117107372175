import React from "react";
import { format } from "date-fns";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Button } from "components/ui/button";
import { Calendar } from "components/ui/calendar";
import { cn } from "lib/utils";
import { Field } from "formik";

export const FormDateRangePicker = ({ name, label }) => (
  <Field name={name}>
    {({ field, form, meta }) => (
      <div className="flex flex-row gap-3 items-center">
        <label className="flex-[1] text-sm font-medium leading-none text-gray-900 peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
          {label}
        </label>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "flex-[2] pl-3 text-left font-normal",
                !field.value && "text-muted-foreground"
              )}
            >
              <CalendarIcon className="mr-2 h-4 w-4" />
              {field.value?.from ? (
                field.value.to ? (
                  <>
                    {format(new Date(field.value.from), "LLL dd, y")} -{" "}
                    {format(new Date(field.value.to), "LLL dd, y")}
                  </>
                ) : (
                  format(new Date(field.value.from), "LLL dd, y")
                )
              ) : (
                <span>Pick a date range</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={
                field.value?.from ? new Date(field.value.from) : undefined
              }
              selected={field.value}
              onSelect={(dateRange) => {
                form.setFieldValue(name, dateRange);
              }}
              numberOfMonths={2}
            />
          </PopoverContent>
        </Popover>
        {meta.error && meta.touched ? <div>{meta.error}</div> : null}
      </div>
    )}
  </Field>
);
