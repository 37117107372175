import { Button } from "components/ui/button";

export const SaveProductButton = ({ loading }) => {
  return (
    <div className="flex justify-end gap-4 flex-row mt-4 mb-8 items-center">
      <span className="text-sm text-zinc-500">
        Changes will not be saved if you leave this page.
      </span>
      <Button type="submit">{loading ? "Saving..." : "Save product"}</Button>
    </div>
  );
};
