import { useState } from 'react';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from 'components/ui/table';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from 'components/ui/select';
import { Badge } from 'components/ui/badge';
import { SearchIcon, Star, Trash2Icon } from 'lucide-react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { PRODUCT_REVIEW_API } from 'api/productReviews';
import { useToast } from 'components/ui/use-toast';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "components/ui/dialog";
import { ScrollArea } from "components/ui/scroll-area";

export default function ProductReviews({ productId }) {
	const [searchTerm, setSearchTerm] = useState('');
	const [filterStatus, setFilterStatus] = useState('all');
	const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
	const [reviewToDelete, setReviewToDelete] = useState(null);
	const { toast } = useToast();
	const queryClient = useQueryClient();

	const { data: reviews, isLoading, isError, error } = useQuery({
		queryKey: [PRODUCT_REVIEW_API.GET_PRODUCT_REVIEWS.KEY, productId],
		queryFn: () => PRODUCT_REVIEW_API.GET_PRODUCT_REVIEWS.FN({ productId }),
	});

	const updateReviewStatusMutation = useMutation({
		mutationFn: PRODUCT_REVIEW_API.UPDATE_REVIEW_STATUS.FN,
		onSuccess: () => {
			queryClient.invalidateQueries([PRODUCT_REVIEW_API.GET_PRODUCT_REVIEWS.KEY, productId]);
			toast({
				title: 'Success',
				description: 'Review status updated successfully.',
			});
		},
		onError: (error) => {
			toast({
				title: 'Error',
				description: `Failed to update review status. ${error.message}`,
				variant: 'destructive',
			});
		},
	});

	const deleteReviewMutation = useMutation({
		mutationFn: PRODUCT_REVIEW_API.DELETE_REVIEW.FN,
		onSuccess: () => {
			queryClient.invalidateQueries([PRODUCT_REVIEW_API.GET_PRODUCT_REVIEWS.KEY, productId]);
			toast({
				title: 'Success',
				description: 'Review deleted successfully.',
			});
		},
		onError: (error) => {
			toast({
				title: 'Error',
				description: `Failed to delete review. ${error.message}`,
				variant: 'destructive',
			});
		},
	});

	const handleStatusChange = async (reviewId, newStatus) => {
		updateReviewStatusMutation.mutate({ reviewId, status: newStatus });
	};

	const handleDeleteReview = (review) => {
		setReviewToDelete(review)
		setDeleteDialogOpen(true)
	}

	const confirmDeleteReview = () => {
		if (reviewToDelete) {
			deleteReviewMutation.mutate({ reviewId: reviewToDelete.id })
			setDeleteDialogOpen(false)
			setReviewToDelete(null)
		}
	}

	const filteredReviews = reviews
		? reviews
				.filter(
					(review) =>
						(review.reviewer
							.toLowerCase()
							.includes(searchTerm.toLowerCase()) ||
							review.review
								.toLowerCase()
								.includes(searchTerm.toLowerCase())) &&
						(filterStatus === 'all' || review.status === filterStatus)
				)
				.sort((a, b) => new Date(b.date_created) - new Date(a.date_created))
		: [];

	const renderStars = (rating) => {
		return Array(5)
			.fill(0)
			.map((_, index) => (
				<Star
					key={index}
					className={`h-4 w-4 ${
						index < rating
							? 'text-yellow-400 fill-current'
							: 'text-gray-300'
					}`}
				/>
			));
	};

	if (isLoading) {
		return <div>Loading reviews...</div>;
	}

	if (isError) {
		return <div>Error loading reviews: {error.message}</div>;
	}

	return (
		<div className='container mx-auto px-0'>
			<h2 className='text-2xl font-bold mb-4'>Product Reviews</h2>
			<div className='flex justify-between items-center mb-4'>
				<div className='flex items-center space-x-2'>
					<div className='relative'>
						<SearchIcon className='absolute left-2 top-2.5 h-4 w-4 text-muted-foreground' />
						<Input
							placeholder='Search reviews...'
							value={searchTerm}
							onChange={(e) => setSearchTerm(e.target.value)}
							className='pl-8 w-[250px]'
						/>
					</div>
					<Select
						value={filterStatus}
						onValueChange={setFilterStatus}>
						<SelectTrigger className='w-[150px]'>
							<SelectValue placeholder='Filter by status' />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value='all'>All Reviews</SelectItem>
							<SelectItem value='hold'>Pending</SelectItem>
							<SelectItem value='approved'>Approved</SelectItem>
						</SelectContent>
					</Select>
				</div>
			</div>
			<ScrollArea className="h-[400px]">
				<div className='rounded-md border overflow-hidden'>
					<Table>
						<TableHeader>
							<TableRow>
								<TableHead className='w-[150px] pl-4'>Customer</TableHead>
								<TableHead className='w-[100px]'>Rating</TableHead>
								<TableHead className='w-[300px]'>Review</TableHead>
								<TableHead className='w-[100px]'>Status</TableHead>
								<TableHead className='w-[100px]'>Date</TableHead>
								<TableHead className='w-[80px] text-right pr-4'>
									Actions
								</TableHead>
							</TableRow>
						</TableHeader>
						<TableBody>
							{filteredReviews.map((review) => (
								<TableRow key={review.id}>
									<TableCell className='font-medium'>
										{review.reviewer}
									</TableCell>
									<TableCell>
										<div className='flex'>
											{renderStars(review.rating)}
										</div>
									</TableCell>
									<TableCell>
										<div
											className='max-w-[300px] break-words'
											dangerouslySetInnerHTML={{
												__html: review.review,
											}}></div>
									</TableCell>
									<TableCell>
										<Select
											value={review.status}
											onValueChange={(newStatus) =>
												handleStatusChange(
													review.id,
													newStatus
												)
											}>
											<SelectTrigger className='w-[124px]'>
												<SelectValue>
													<Badge
														variant={
															review.status ===
															'approved'
																? 'default'
																: 'secondary'
														}>
														{review.status}
													</Badge>
												</SelectValue>
											</SelectTrigger>
											<SelectContent>
												<SelectItem value='hold'>
													Pending
												</SelectItem>
												<SelectItem value='approved'>
													Approved
												</SelectItem>
												<SelectItem value='spam'>
													Spam
												</SelectItem>
												<SelectItem value='trash'>
													Trash
												</SelectItem>
											</SelectContent>
										</Select>
									</TableCell>
									<TableCell>
										{new Date(
											review.date_created
										).toLocaleDateString()}
									</TableCell>
									<TableCell className='text-right'>
										<Button
											variant='outline'
											size='icon'
											onClick={() =>
												handleDeleteReview(review)
											}>
											<Trash2Icon className='h-4 w-4' />
										</Button>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</div>
			</ScrollArea>
			<Dialog open={deleteDialogOpen} onOpenChange={setDeleteDialogOpen}>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>Confirm Deletion</DialogTitle>
						<DialogDescription>
							Are you sure you want to delete this review? This action cannot be undone.
						</DialogDescription>
					</DialogHeader>
					<DialogFooter>
						<Button type="button" variant="outline" onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
						<Button type="button" onClick={confirmDeleteReview}>Delete</Button>
					</DialogFooter>
				</DialogContent>
			</Dialog>
		</div>
	);
}
