import * as Yup from "yup";

import { Input } from "components/ui/input";
import { Select } from "components/ui/select";
import { useQuery } from "@tanstack/react-query";
import { PRODUCT_API } from "api/product";
import { CATEGORIE_API } from "api/categories";

export const DiscountSchema = Yup.object().shape({
  code: Yup.string().required("Required"),
  amount: Yup.string().required("Required"),
  discount_type: Yup.string().required("Required"),
  description: Yup.string(),
  date_expires: Yup.date(),
  individual_use: Yup.boolean(),
  exclude_sale_items: Yup.boolean(),
  minimum_amount: Yup.string(),
  maximum_amount: Yup.string(),
  product_ids: Yup.array(),
  excluded_product_ids: Yup.array(),
  product_categories: Yup.array(),
  excluded_product_categories: Yup.array(),
  email_restrictions: Yup.array(),
  usage_limit: Yup.number(),
  usage_limit_per_user: Yup.number(),
  limit_usage_to_x_items: Yup.number(),
  free_shipping: Yup.boolean(),
});

export const GetProducts = () => {
  const productsQuery = useQuery({
    queryFn: () =>
      PRODUCT_API.GET_ALL_PRODUCTS.FN({
        params: { per_page: 100 },
      }),
    queryKey: [PRODUCT_API.GET_ALL_PRODUCTS.KEY, { per_page: 100 }],
  });

  if (productsQuery.isLoading) {
    return [];
  }

  return productsQuery.data.map((product) => ({
    value: product.id,
    label: product.name,
  })) || [];
};

export const GetProductCategories = () => {
	const categoriesQuery = useQuery({
	  queryFn: () =>
		CATEGORIE_API.GET_ALL_CATEGORIES.FN({
		  params: { per_page: 100 },
		}),
	  queryKey: [CATEGORIE_API.GET_ALL_CATEGORIES.KEY, { per_page: 100 }],
	});
  
	if (categoriesQuery.isLoading || !categoriesQuery.data) {
	  return [];
	}
  
	return categoriesQuery.data.map((category) => ({
	  value: category.id,
	  label: category.name,
	})) || [];
  };

export const sections = [
  {
    legend: "General",
    fields: [
      { name: "code", label: "Code", type: "text", component: Input },
      { name: "amount", label: "Amount", type: "text", component: Input },
      {
        name: "discount_type",
        label: "Type",
        type: "select",
        component: Select,
        options: [
          { value: "percent", label: "Percentage discount" },
          { value: "fixed_cart", label: "Fixed cart discount" },
          { value: "fixed_product", label: "Fixed product discount" },
        ],
      },
      {
        name: "description",
        label: "Description",
        type: "textarea",
      },
    ],
  },
  {
    legend: "Usage Restrictions",
    fields: [
      {
        name: "individual_use",
        label: "Individual Use Only",
        type: "checkbox",
      },
      {
        name: "exclude_sale_items",
        label: "Exclude Sale Items",
        type: "checkbox",
      },
      {
        name: "minimum_amount",
        label: "Minimum Amount",
        type: "text",
      },
      {
        name: "maximum_amount",
        label: "Maximum Amount",
        type: "text",
      },
      {
        name: "product_ids",
        label: "Product IDs",
        type: "multiselect",
        component: Select,
        options: GetProducts,
      },
      {
        name: "excluded_product_ids",
        label: "Excluded Product IDs",
        type: "multiselect",
        component: Select,
        options: GetProducts,
      },
      {
        name: "product_categories",
        label: "Product Categories",
        type: "multiselect",
		component: Select,
        options: GetProductCategories,
      },
      {
        name: "excluded_product_categories",
        label: "Excluded Categories",
        type: "multiselect",
		component: Select,
        options: GetProductCategories,
      },
      {
        name: "email_restrictions",
        label: "Email Restrictions",
        type: "textarea",
      },
    ],
  },
  {
    legend: "Usage Limits",
    fields: [
      {
        name: "usage_limit",
        label: "Usage Limit",
        type: "number",
      },
      {
        name: "usage_limit_per_user",
        label: "Usage Limit Per User",
        type: "number",
      },
      {
        name: "limit_usage_to_x_items",
        label: "Limit Usage To X Items",
        type: "number",
      },
    ],
  },
  {
    legend: "Other",
    fields: [
      {
        name: "date_expires",
        label: "Expiry",
        type: "date",
      },
      {
        name: "free_shipping",
        label: "Free Shipping",
        type: "checkbox",
      },
    ],
  },
];