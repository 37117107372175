import React from "react";
import { format } from "date-fns";
import { FaCalendar } from "react-icons/fa";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/popover";
import { Button } from "components/ui/button";
import { Calendar } from "components/ui/calendar";
import { cn } from "lib/utils";
import { Field } from "formik";

const FormDate = ({ name, label }) => (
  <Field name={name}>
    {({ field, form, meta }) => (
      <div key={name} className="flex flex-row gap-3 items-center">
        <label className="w-[300px] text-sm font-medium leading-none text-gray-900 peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
          {label}
        </label>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant={"outline"}
              className={cn(
                "pl-3 text-left font-normal w-full",
                !field.value && "text-muted-foreground"
              )}
            >
              {field.value ? (
                format(new Date(field.value), "PPP")
              ) : (
                <span>Pick a date</span>
              )}
              <FaCalendar className="ml-auto h-4 w-4 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={field.value ? new Date(field.value) : undefined}
              onSelect={(date) => {
                form.setFieldValue(name, date);
              }}
              initialFocus
            />
          </PopoverContent>
        </Popover>
        {meta.error && meta.touched ? <div>{meta.error}</div> : null}
      </div>
    )}
  </Field>
);

export default FormDate;
