import { axiosAnalyticsClient } from 'api/axios';

export const getRevenueStats = async ({ queryKey }) => {
	const [_, params] = queryKey; // eslint-disable-line
	try {
		const { data } = await axiosAnalyticsClient.get(
			`/reports/revenue/stats`,
			{
				params: {
					_locale: 'user',
					order: 'asc',
					interval: 'day',
					per_page: 100,
					...params,
				},
			}
		);
		return data;
	} catch (error) {
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const getLeaderboards = async ({ queryKey }) => {
	const [_, params] = queryKey; // eslint-disable-line
	try {
		const { data } = await axiosAnalyticsClient.get(`/leaderboards`, {
			params: {
				after: params.after || '2024-01-01T07:14:00.000Z',
				before: params.before || '2024-07-31T07:14:00.000Z',
				persisted_query: '[object Object]',
				_locale: 'user',
				per_page: 6,
				...params,
			},
		});
		return data;
	} catch (error) {
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const DASHBOARD_API = {
	GET_STATS: {
		KEY: 'GET_STATS',
		FN: getRevenueStats,
	},
	GET_LEADERBOARDS: {
		KEY: 'GET_LEADERBOARDS',
		FN: getLeaderboards,
	},
};

