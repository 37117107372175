import { axiosClient } from '../axios';

export const getAllCoupons = async ({ params }) => {
	try {
		const { data } = await axiosClient.get(`/coupons`, {
			params,
		}); // Append serialized authParams to the URL
		return data;
	} catch (error) {
		console.log('Error fetching coupons: ', error); // Debugging log for errors
		throw error.response ? error.response.data : new Error('Network Error');
	}
};
// /wp-json/wc/v3/coupons/<id>
export const updateCoupon = async ({ id, data }) => {
	try {
		const response = await axiosClient.put(`/coupons/${id}`, data);
		return response.data;
	} catch (error) {
		console.log('Error updating coupon: ', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const addCoupon = async ({ data }) => {
	try {
		const response = await axiosClient.post(`/coupons`, data);
		return response.data;
	} catch (error) {
		console.log('Error adding coupon: ', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const deleteCoupon = async ({ id }) => {
	try {
		const response = await axiosClient.delete(`/coupons/${id}`, {
			params: { force: true },
		});
		return response.data;
	} catch (error) {
		console.log('Error deleting coupon: ', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

const deleteBatchCoupons = async ({ ids }) => {
	try {
		const response = await axiosClient.post(`coupons/batch`, {
			delete: ids,
		});
		return response.data;
	} catch (error) {
		console.log('Error deleting batch coupons: ', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const COUPON_API = {
	GET_ALL_COUPONS: {
		KEY: 'GET_ALL_COUPONS',
		FN: getAllCoupons,
	},
	UPDATE_COUPONS: {
		KEY: 'UPDATE_COUPONS',
		FN: updateCoupon,
	},
	ADD_COUPONS: {
		KEY: 'ADD_COUPONS',
		FN: addCoupon,
	},
	DELETE_COUPONS: {
		KEY: 'DELETE_COUPONS',
		FN: deleteCoupon,
	},
	DELETE_BATCH_COUPONS: {
		KEY: 'DELETE_BATCH_COUPONS',
		FN: deleteBatchCoupons,
	},
};
