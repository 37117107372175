import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from 'components/ui/table';
import { Skeleton } from 'components/ui/skeleton';

const SalesTableCard = ({ tableData, loading }) => (
	<Card className='w-full'>
		<CardHeader>
			<CardTitle>
				{loading ? <Skeleton className='h-6 w-3/4' /> : tableData.label}
			</CardTitle>
		</CardHeader>
		<CardContent>
			<Table>
				<TableHeader>
					<TableRow>
						{loading
							? Array(tableData.headers.length)
									.fill(0)
									.map((_, index) => (
										<TableHead key={index}>
											<Skeleton className='h-4 w-full' />
										</TableHead>
									))
							: tableData.headers.map((header, index) => (
									<TableHead key={index}>
										{header.label}
									</TableHead>
							  ))}
					</TableRow>
				</TableHeader>
				<TableBody>
					{loading ? (
						// Skeleton rows
						Array(5)
							.fill(0)
							.map((_, rowIndex) => (
								<TableRow key={rowIndex}>
									{Array(tableData.headers.length)
										.fill(0)
										.map((_, cellIndex) => (
											<TableCell key={cellIndex}>
												<div className="h-4 w-full">
													<Skeleton className="h-full w-full" />
												</div>
											</TableCell>
										))}
								</TableRow>
							))
					) : tableData.rows.length === 0 ? (
						<TableRow>
							<TableCell
								colSpan={tableData.headers.length}
								className='text-center'>
								No data for this period
							</TableCell>
						</TableRow>
					) : (
						tableData.rows.map((row, rowIndex) => (
							<TableRow key={rowIndex}>
								{row.map((cell, cellIndex) => (
									<TableCell key={cellIndex}>
										{cell.display.startsWith('<a href=') ? (
											<a
												href={
													cell.display.match(
														/href='([^']*)/
													)[1]
												}>
												{cell.value}
											</a>
										) : cell.display.startsWith(
												'<span class="woocommerce-Price-amount'
										  ) ? (
											<span>
												{cell.value.toFixed(2)} €
											</span>
										) : (
											cell.display
										)}
									</TableCell>
								))}
							</TableRow>
						))
					)}
				</TableBody>
			</Table>
		</CardContent>
	</Card>
);

export default SalesTableCard;
