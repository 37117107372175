import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SETUP_API } from "api/setup";
import { Button } from "components/ui/button";
import { Form, Formik } from "formik";
import React from "react";
import { initialValuesGenerator, renderField } from "./render-fields";
import { useToast } from "components/ui/use-toast";
import { LoadingSpinner } from "components/ui/loadingSpinner";
import NetworkError from 'components/ui/networkError';

const Advanced = () => {
  const { toast } = useToast();
  const cache = useQueryClient();

  const updateAdvancedMutation = useMutation({
    mutationFn: SETUP_API.UPDATE_ADVANCED.FN,
    mutationKey: SETUP_API.UPDATE_ADVANCED.KEY,
    onError: (error) => {
      console.log("Error updating advanced: ", error);
      toast({
        title: "Error",
        description: "There was a problem updating the advanced settings.",
        variant: "destructive",
      });
    },
    onSuccess: (data) => {
      toast({
        title: "Success",
        description: "Your advanced settings were updated successfully.",
      });
      cache.invalidateQueries({
        queryKey: [SETUP_API.UPDATE_ADVANCED.KEY],
      });
    },
  });

  const handleSubmit = async (values) => {
    try {
      await updateAdvancedMutation.mutateAsync({
        values,
      });
    } catch (error) {
      console.log("Error updateing advanced: ", error);
    }
  };
  const advancedQuery = useQuery({
    queryFn: () => SETUP_API.GET_ADVANCED.FN(),
    queryKey: [SETUP_API.GET_ADVANCED.KEY],
  });

  const apiData = advancedQuery?.data ?? [];

  if (advancedQuery.isPending) {
    return <LoadingSpinner />;
	}

	if (advancedQuery.error) {
		return 	(
			<NetworkError
				message={ advancedQuery.error }
			/>
		)
	}

  if (!advancedQuery.data) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValuesGenerator(apiData)}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="flex flex-col gap-4 p-4">
          {apiData.map((field) => (
            <div key={field.id}>{renderField(field)}</div>
          ))}
          <div className="flex justify-end">
            <Button
              type="submit"
              className="w-32"
              disabled={isSubmitting || updateAdvancedMutation.isPending}
              aria-disabled="true"
            >
              {isSubmitting || updateAdvancedMutation.isPending
                ? "Saving..."
                : "Save"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Advanced;
