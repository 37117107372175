import React from 'react';
import { Button } from 'components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { ListFilter } from 'lucide-react';
import { Form, Formik } from 'formik';

import { CardTitle } from 'components/ui/card';

import { renderNormalField } from 'components/renderNormalField';
import { FormDateRangePicker } from 'components/form/form-date-range-picker';

export default function FilterDropdown({ dropdownState, filters, setFilters }) {
	const filterFields = [
		{
			name: 'filters',
			label: 'Filters',
			type: 'heading',
			component: CardTitle,
		},
		{
			name: 'dateRange',
			label: 'Date range',
			type: 'date-range',
			component: FormDateRangePicker,
		},

		{
			name: 'table settings',
			label: 'Table settings',
			type: 'heading',
			component: CardTitle,
		},
		{
			name: 'per_page',
			label: 'Per page',
			type: 'number',
		},
		{
			name: 'order',
			label: 'Order',
			type: 'select',
			options: [
				{ value: 'asc', label: 'Ascending' },
				{ value: 'desc', label: 'Descending' },
			],
		},
		{
			name: 'orderBy',
			label: 'Order by',
			type: 'select',
			options: [
				{ value: 'id', label: 'ID' },
				{ value: 'include', label: 'Include' },
				{ value: 'name', label: 'Name' },
				{ value: 'registered_date', label: 'Registered Date' },
			],
		},
	];

	return (
		<DropdownMenu
			defaultOpen={false}
			open={dropdownState.isOpen}
			onOpenChange={dropdownState.onToggle}>
			<DropdownMenuTrigger asChild>
				<Button
					variant='outline'
					size='sm'
					className='px-4 h-10 text-sm font-medium gap-1'>
					<ListFilter className='h-3.5 w-3.5' />
					<span className='sr-only sm:not-sr-only sm:whitespace-nowrap'>
						Filter
					</span>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align='end'>
				<Formik
					initialValues={{
						dateRange: filters.dateRange,

						per_page: filters.per_page,
						order: filters.order,
						orderBy: filters.orderBy,
					}}
					enableReinitialize
					onSubmit={(values) => {
						setFilters({
							dateRange: {
								from: values.dateRange.from,
								to: values.dateRange.to,
							},
							per_page: values.per_page,
							order: values.order.value,
							orderBy: values.orderBy.value,
						});
						dropdownState.onToggle();
					}}>
					{({}) => ( // eslint-disable-line
						<Form className='flex flex-col gap-4 p-1 mr-4'>
							{filterFields.map((field) => (
								<div key={field.name}>
									{renderNormalField(field)}
								</div>
							))}
							<Button type='submit' className='mt-4'>
								Save
							</Button>
						</Form>
					)}
				</Formik>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
