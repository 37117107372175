import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card';
import { Avatar, AvatarImage, AvatarFallback } from 'components/ui/avatar';
import { Skeleton } from 'components/ui/skeleton';
import { BASE_URL } from '../../../api/axios';
import { ExternalLinkIcon } from 'lucide-react';
import { Link } from 'react-router-dom';

export function Orders({ orders = [], loading }) {
	return (
		<Card>
			<CardHeader>
				<CardTitle>Orders</CardTitle>
			</CardHeader>
			<CardContent>
				<div className='space-y-4'>
					{loading
						? // Render skeletons when loading
						  [...Array(3)].map((_, index) => (
								<div key={index} className='h-24 w-full'>
									<Skeleton className='h-full w-full' />
								</div>
						  ))
						: orders.map((order) => (
								<OrderItem
									key={order.id}
									title={`${order.line_items[0].name} (${
										order.line_items.length - 1
									} others)`}
									date={formatDate(order.date_created)}
									amount={`${order.currency_symbol}${order.total}`}
									imageSrc={order.line_items[0].image.src}
									orderId={order.id}
								/>
						  ))}
				</div>
			</CardContent>
		</Card>
	);
}

function OrderItem({ title, date, amount, imageSrc, orderId }) {
	return (
		<div className='flex items-center justify-between gap-2'>
			<Link
				to={`/orders?isUpdate=true&orderId=${orderId}`}
				className='flex flex-1'>
				<div className='flex flex-1 items-center rounded-md p-2 hover:bg-muted/50'>
					<Avatar className='h-9 w-9'>
						<AvatarImage src={imageSrc} alt='Order icon' />
						<AvatarFallback>O</AvatarFallback>
					</Avatar>
					<div className='ml-4 space-y-1 flex flex-col'>
						<div className='text-sm font-medium leading-none gap-2'>
							<p className='text-sm text-muted-foreground'>
								#{orderId} - {title}
							</p>
						</div>
						<p className='text-sm text-muted-foreground'>{date}</p>
					</div>
					<div className='ml-auto font-medium flex items-center gap-2'>
						<p className='text-sm text-muted-foreground'>
							{amount}
						</p>
					</div>
				</div>
			</Link>
			<div className='flex items-center gap-2 w-4'>
				<a
					href={`${BASE_URL}/wp-admin/edit.php?post_type=shop_order&order=${orderId}`}
					target='_blank'
					rel='noreferrer'
					className='text-muted-foreground hover:text-zinc-600 transition-colors'>
					<ExternalLinkIcon className='h-4 w-4' />
				</a>
			</div>
		</div>
	);
}

function formatDate(dateString) {
	const date = new Date(dateString);
	return date
		.toLocaleString('en-US', {
			day: '2-digit',
			month: 'short',
			year: 'numeric',
		})
		.replace(',', '');
}
