import { axiosClient, axiosWCAdminClient } from "api/axios";

export const getGeneral = async () => {
  try {
    const { data } = await axiosClient.get(`/settings/general`); // Append serialized authParams to the URL
    return data;
  } catch (error) {
    console.log("Error fetching general: ", error); // Debugging log for errors
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getProduct = async () => {
  try {
    const { data } = await axiosClient.get(`/settings/products`); // Append serialized authParams to the URL
    return data;
  } catch (error) {
    console.log("Error fetching products: ", error); // Debugging log for errors
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getTax = async () => {
  try {
    const { data } = await axiosClient.get(`/settings/tax`); // Append serialized authParams to the URL
    return data;
  } catch (error) {
    console.log("Error fetching tax: ", error); // Debugging log for errors
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getShipping = async () => {
  try {
    const { data } = await axiosClient.get(`/settings/shipping`); // Append serialized authParams to the URL
    return data;
  } catch (error) {
    console.log("Error fetching shipping: ", error); // Debugging log for errors
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getAccount = async () => {
  try {
    const { data } = await axiosClient.get(`/settings/account`); // Append serialized authParams to the URL
    return data;
  } catch (error) {
    console.log("Error fetching account: ", error); // Debugging log for errors
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getEmail = async () => {
  try {
    const { data } = await axiosClient.get(`/settings/email`); // Append serialized authParams to the URL
    return data;
  } catch (error) {
    console.log("Error fetching email: ", error); // Debugging log for errors
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getAdvanced = async () => {
  try {
    const { data } = await axiosClient.get(`/settings/advanced`); // Append serialized authParams to the URL
    return data;
  } catch (error) {
    console.log("Error fetching advanced: ", error); // Debugging log for errors
    throw error.response ? error.response.data : new Error("Network Error");
  }
};
function convertObjectToArray(obj) {
  return Object.entries(obj).map(([id, value]) => ({
    id,
    value,
  }));
}
export const updateGeneral = async ({ values }) => {
  const requiredData = convertObjectToArray(values);

  try {
    const { data } = await axiosClient.post(`/settings/general/batch`, {
      update: requiredData,
    });
    return data;
  } catch (error) {
    console.log("Error fetching general settings: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const updateProduct = async ({ values }) => {
  const requiredData = convertObjectToArray(values);

  try {
    const { data } = await axiosClient.post(`/settings/products/batch`, {
      update: requiredData,
    });
    return data;
  } catch (error) {
    console.log("Error fetching product settings: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const updateTax = async ({ values }) => {
  const requiredData = convertObjectToArray(values);

  try {
    const { data } = await axiosClient.post(`/settings/tax/batch`, {
      update: requiredData,
    });
    return data;
  } catch (error) {
    console.log("Error fetching tax settings: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const updateShipping = async ({ values }) => {
  const requiredData = convertObjectToArray(values);

  try {
    const { data } = await axiosClient.post(`/settings/shipping/batch`, {
      update: requiredData,
    });
    return data;
  } catch (error) {
    console.log("Error fetching shipping settings: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const updateAccount = async ({ values }) => {
  const requiredData = convertObjectToArray(values);

  try {
    const { data } = await axiosClient.post(`/settings/account/batch`, {
      update: requiredData,
    });
    return data;
  } catch (error) {
    console.log("Error fetching account settings: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const updateEmail = async ({ values }) => {
  const requiredData = convertObjectToArray(values);

  try {
    const { data } = await axiosClient.post(`/settings/email/batch`, {
      update: requiredData,
    });
    return data;
  } catch (error) {
    console.log("Error fetching email settings: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const updateAdvanced = async ({ values }) => {
  const requiredData = convertObjectToArray(values);

  try {
    const { data } = await axiosClient.post(`/settings/advanced/batch`, {
      update: requiredData,
    });
    return data;
  } catch (error) {
    console.log("Error fetching advanced settings: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getPayments = async () => {
  try {
    const { data } = await axiosClient.get(`/payment_gateways`);
    return data;
  } catch (error) {
    console.log("Error fetching payments: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const updatePaymentGateway = async (gateway) => {
    try {
        const { data } = await axiosClient.put(`/payment_gateways/${gateway.id}`, gateway);
        return data;
    } catch (error) {
        console.log("Error updating payment gateway: ", error);
        throw error.response ? error.response.data : new Error("Network Error");
    }
};

export const getSiteVisibility = async () => {
  try {
    const { data } = await axiosWCAdminClient.get(`/options?options=woocommerce_coming_soon,woocommerce_store_pages_only,woocommerce_private_link,woocommerce_share_key`);
    return {
      woocommerce_coming_soon: data.woocommerce_coming_soon,
      woocommerce_store_pages_only: data.woocommerce_store_pages_only,
      woocommerce_private_link: data.woocommerce_private_link,
	  woocommerce_share_key: data.woocommerce_share_key,
    };
  } catch (error) {
    console.log("Error fetching site visibility settings: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const updateSiteVisibility = async (updates) => {
  try {
    const { data } = await axiosWCAdminClient.post(`/options`, updates);
    return data;
  } catch (error) {
    console.log("Error updating site visibility settings: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const SETUP_API = {
  GET_GENERAL: {
    KEY: "GET_GENERAL",
    FN: getGeneral,
  },
  GET_PRODUCT: {
    KEY: "GET_PRODUCT",
    FN: getProduct,
  },
  GET_TAX: {
    KEY: "GET_TAX",
    FN: getTax,
  },
  GET_SHIPPING: {
    KEY: "GET_SHIPPING",
    FN: getShipping,
  },
  GET_ACCOUNT: {
    KEY: "GET_ACCOUNT",
    FN: getAccount,
  },
  GET_EMAIL: {
    KEY: "GET_EMAIL",
    FN: getEmail,
  },
  GET_ADVANCED: {
    KEY: "GET_ADVANCED",
    FN: getAdvanced,
  },
  UPDATE_GENERAL: {
    KEY: "UPDATE_GENERAL",
    FN: updateGeneral,
  },
  UPDATE_PRODUCT: {
    KEY: "UPDATE_PRODUCT",
    FN: updateProduct,
  },
  UPDATE_TAX: {
    KEY: "UPDATE_TAX",
    FN: updateTax,
  },
  UPDATE_SHIPPING: {
    KEY: "UPDATE_SHIPPING",
    FN: updateShipping,
  },
  UPDATE_ACCOUNT: {
    KEY: "UPDATE_ACCOUNT",
    FN: updateAccount,
  },
  UPDATE_EMAIL: {
    KEY: "UPDATE_EMAIL",
    FN: updateEmail,
  },
  UPDATE_ADVANCED: {
    KEY: "UPDATE_ADVANCED",
    FN: updateAdvanced,
  },
  GET_PAYMENTS: {
    KEY: 'GET_PAYMENTS',
    FN: getPayments,
  },
  UPDATE_PAYMENT: {
    KEY: 'UPDATE_PAYMENT',
    FN: updatePaymentGateway,
  },
  GET_SITE_VISIBILITY: {
    KEY: "GET_SITE_VISIBILITY",
    FN: getSiteVisibility,
  },
  UPDATE_SITE_VISIBILITY: {
    KEY: "UPDATE_SITE_VISIBILITY",
    FN: updateSiteVisibility,
  },
};