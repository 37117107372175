import { axiosClient } from "../axios";

export const getAllAttributes = async ({ params }) => {
  try {
    const { data } = await axiosClient.get(`/products/attributes`, { params });
    return data;
  } catch (error) {
    console.log("Error fetching attributes: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const updateAttribute = async ({ id, data }) => {
  try {
    const response = await axiosClient.put(`products/attributes/${id}`, data);
    return response.data;
  } catch (error) {
    console.log("Error updating attribute: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const addAttribute = async ({ data }) => {
  try {
    const response = await axiosClient.post("products/attributes", {
      name: data.name,
      slug: data.name.toLowerCase().replace(/ /g, '-'),
      type: "select",
      order_by: "menu_order",
      has_archives: true
    });
    
    // If the attribute is created successfully, add the terms
    if (response.data && response.data.id) {
      const attributeId = response.data.id;
      const termsPromises = data.terms.map(term => 
        axiosClient.post(`products/attributes/${attributeId}/terms`, {
          name: term.name
        })
      );
      await Promise.all(termsPromises);
    }
    
    return response.data;
  } catch (error) {
    console.log("Error adding attribute: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const deleteAttribute = async ({ id }) => {
  try {
    const response = await axiosClient.delete(`products/attributes/${id}`, {
      params: { force: true },
    });
    return response.data;
  } catch (error) {
    console.log("Error deleting attribute: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getAllTerms = async ({ attributeId, params }) => {
  try {
    const { data } = await axiosClient.get(`/products/attributes/${attributeId}/terms`, { params });
    return data;
  } catch (error) {
    console.log("Error fetching terms: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const updateTerm = async ({ attributeId, termId, data }) => {
  try {
    const response = await axiosClient.put(`products/attributes/${attributeId}/terms/${termId}`, data);
    return response.data;
  } catch (error) {
    console.log("Error updating term: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const addTerm = async ({ attributeId, data }) => {
  try {
    const response = await axiosClient.post(`products/attributes/${attributeId}/terms`, data);
    return response.data;
  } catch (error) {
    console.log("Error adding term: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const deleteTerm = async ({ attributeId, termId }) => {
  try {
    const response = await axiosClient.delete(`products/attributes/${attributeId}/terms/${termId}`, {
      params: { force: true },
    });
    return response.data;
  } catch (error) {
    console.log("Error deleting term: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const ATTRIBUTE_API = {
  GET_ALL_ATTRIBUTES: {
    KEY: "GET_ALL_ATTRIBUTES",
    FN: getAllAttributes,
  },
  UPDATE_ATTRIBUTE: {
    KEY: "UPDATE_ATTRIBUTE",
    FN: updateAttribute,
  },
  ADD_ATTRIBUTE: {
    KEY: "ADD_ATTRIBUTE",
    FN: addAttribute,
  },
  DELETE_ATTRIBUTE: {
    KEY: "DELETE_ATTRIBUTE",
    FN: deleteAttribute,
  },
  GET_ALL_TERMS: {
    KEY: "GET_ALL_TERMS",
    FN: getAllTerms,
  },
  UPDATE_TERM: {
    KEY: "UPDATE_TERM",
    FN: updateTerm,
  },
  ADD_TERM: {
    KEY: "ADD_TERM",
    FN: addTerm,
  },
  DELETE_TERM: {
    KEY: "DELETE_TERM",
    FN: deleteTerm,
  },
};