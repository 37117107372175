import { axiosClient } from "../axios"; // Ensure the path is correct

export const getAllCategories = async ({ params }) => {
  try {
    const { data } = await axiosClient.get(`/products/categories`, {
      params,
    }); // Append serialized authParams to the URL
    return data;
  } catch (error) {
    console.log("Error fetching categories: ", error); // Debugging log for errors
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

// /wp-json/wc/v3/categories/<id>
export const updateCategories = async ({ id, data }) => {
  try {
    const response = await axiosClient.put(`products/categories/${id}`, data);
    return response.data;
  } catch (error) {
    console.log("Error updating categories: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const addCategorie = async ({ data }) => {
  try {
    const response = await axiosClient.post("products/categories", data);
    return response.data;
  } catch (error) {
    console.log("Error add categories: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const deleteCategorie = async ({ id }) => {
  try {
    const response = await axiosClient.delete(`products/categories/${id}`, {
      params: { force: true },
    });
    return response.data;
  } catch (error) {
    console.log("Error deleting categorie: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

const deleteBatchCategories = async ({ ids }) => {
  try {
    const response = await axiosClient.post(`products/categories/batch`, {
      delete: ids,
    });
    return response.data;
  } catch (error) {
    console.log("Error deleting batch categories: ", error);
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const CATEGORIE_API = {
  GET_ALL_CATEGORIES: {
    KEY: "GET_ALL_CATEGORIES",
    FN: getAllCategories,
  },
  UPDATE_CATEGORIES: {
    KEY: "UPDATE_CATEGORIES",
    FN: updateCategories,
  },
  ADD_CATEGORIES: {
    KEY: "ADD_CATEGORIES",
    FN: addCategorie,
  },
  DELETE_CATEGORIES: {
    KEY: "DELETE_CATEGORIES",
    FN: deleteCategorie,
  },
  DELETE_BATCH_CATEGORIES: {
    KEY: "DELETE_BATCH_CATEGORIES",
    FN: deleteBatchCategories,
  },
};
