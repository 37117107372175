import { axiosClient } from '../axios';

export const getAllProducts = async ({ params }) => {
	try {
		const { data } = await axiosClient.get(`products`, {
			params,
		}); // Append serialized authParams to the URL
		return data;
	} catch (error) {
		console.log('Error fetching products: ', error); // Debugging log for errors
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

const getProductById = async ({ queryKey }) => {
	const [_, { id }] = queryKey; // eslint-disable-line
	try {
		const response = await axiosClient.get(`products/${id}`);
		return response.data;
	} catch (error) {
		console.log('Error fetching product by id: ', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const getProductCategories = async ({ queryKey }) => {
	const [_] = queryKey; // eslint-disable-line
	try {
		const response = await axiosClient.get('products/categories', {
			params: { per_page: 100 },
		});
		return response.data;
	} catch (error) {
		console.error('Error fetching product categories:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const getProductTags = async ({ queryKey }) => {
	const [_] = queryKey; // eslint-disable-line
	try {
		const response = await axiosClient.get('products/tags', {
			params: { per_page: 100 },
		});
		return response.data;
	} catch (error) {
		console.error('Error fetching product tags:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const getProductAttributes = async (productId) => {
	try {
		const response = await axiosClient.get(`products/${productId}`);
		return response.data.attributes;
	} catch (error) {
		console.error('Error fetching product attributes:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const createProductAttribute = async ({ attribute }) => {
	try {
		const response = await axiosClient.post(
			'products/attributes',
			attribute
		);
		return response.data;
	} catch (error) {
		console.error('Error creating product attribute:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

// /wp-json/wc/v3/products/<id>
export const updateProducts = async ({ id, data }) => {
	try {
		const response = await axiosClient.put(`products/${id}`, data, {
			params: {
				ls_endpoint: `products/${id}`,
				include: 'variants',
				rawResponse: true,
				context: 'edit',
			},
		});
		return response.data;
	} catch (error) {
		console.log('Error updating products: ', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const addProduct = async ({ data }) => {
	try {
		const response = await axiosClient.post('products', data);
		return response.data;
	} catch (error) {
		console.log('Error add products: ', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const deleteProduct = async ({ id }) => {
	try {
		const response = await axiosClient.delete(`products/${id}`, {
			params: { force: true },
		});
		return response.data;
	} catch (error) {
		console.log('Error deleting product: ', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

const deleteBatchProducts = async ({ ids }) => {
	try {
		const response = await axiosClient.post(`products/batch`, {
			delete: ids,
		});
		return response.data;
	} catch (error) {
		console.log('Error deleting batch products: ', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

const getProductName = async ({ queryKey }) => {
	const [_, { id }] = queryKey; // eslint-disable-line
	try {
		const response = await axiosClient.get(`products/${id}`, {
			params: { _fields: 'name,id' },
		});
		return response.data;
	} catch (error) {
		console.log('Error fetching product name: ', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const getShippingClasses = async () => {
	try {
		const response = await axiosClient.get('products/shipping_classes', {
			params: { per_page: 100 },
		});
		return response.data;
	} catch (error) {
		console.error('Error fetching shipping classes:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const getProductVariations = async ({ queryKey }) => {
	const [_, { productId }] = queryKey; // eslint-disable-line

	console.log('productId', productId);
	try {
		const response = await axiosClient.get(
			`products/${productId}/variations`,
			{
				params: { per_page: 100 },
			}
		);
		return response.data;
	} catch (error) {
		console.error('Error fetching product variations:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};
export const addProductVariation = async ({ productId, data }) => {
	try {
		const response = await axiosClient.post(
			`products/${productId}/variations`,
			data
		);
		return response.data;
	} catch (error) {
		console.error('Error adding product variation:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};
export const updateProductVariation = async ({
	productId,
	variationId,
	data,
}) => {
	try {
		const response = await axiosClient.put(
			`products/${productId}/variations/${variationId}`,
			data,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-WP-Nonce': '67f9b07a4e', // Note: In a real application, this should be dynamically obtained
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error('Error updating product variation:', error);
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const getLowStockProducts = async ({ params }) => {
	try {
		const { data } = await axiosClient.get(`products`, {
			params: {
				stock_status: 'instock',
				orderby: 'stock_quantity',
				order: 'asc',
				per_page: 5,
			},
		}); // Append serialized authParams to the URL
		return data;
	} catch (error) {
		console.log('Error fetching products: ', error); // Debugging log for errors
		throw error.response ? error.response.data : new Error('Network Error');
	}
};

export const PRODUCT_API = {
	GET_ALL_PRODUCTS: {
		KEY: 'GET_ALL_PRODUCTS',
		FN: getAllProducts,
	},
	GET_PRODUCT_BY_ID: {
		KEY: 'GET_PRODUCT_BY_ID',
		FN: getProductById,
	},
	UPDATE_PRODUCT: {
		KEY: 'UPDATE_PRODUCT',
		FN: updateProducts,
	},
	ADD_PRODUCTS: {
		KEY: 'ADD_PRODUCTS',
		FN: addProduct,
	},
	DELETE_PRODUCTS: {
		KEY: 'DELETE_PRODUCTS',
		FN: deleteProduct,
	},
	DELETE_BATCH_PRODUCTS: {
		KEY: 'DELETE_BATCH_PRODUCTS',
		FN: deleteBatchProducts,
	},
	GET_PRODUCT_CATEGORIES: {
		KEY: 'GET_PRODUCT_CATEGORIES',
		FN: getProductCategories,
	},
	GET_PRODUCT_TAGS: {
		KEY: 'GET_PRODUCT_TAGS',
		FN: getProductTags,
	},
	GET_PRODUCT_ATTRIBUTES: {
		KEY: 'GET_PRODUCT_ATTRIBUTES',
		FN: getProductAttributes,
	},
	CREATE_PRODUCT_ATTRIBUTE: {
		KEY: 'CREATE_PRODUCT_ATTRIBUTE',
		FN: createProductAttribute,
	},
	GET_PRODUCT_NAME: {
		KEY: 'GET_PRODUCT_NAME',
		FN: getProductName,
	},
	GET_SHIPPING_CLASSES: {
		KEY: 'GET_SHIPPING_CLASSES',
		FN: getShippingClasses,
	},
	GET_PRODUCT_VARIATIONS: {
		KEY: 'GET_PRODUCT_VARIATIONS',
		FN: getProductVariations,
	},
	ADD_PRODUCT_VARIATION: {
		KEY: 'ADD_PRODUCT_VARIATION',
		FN: addProductVariation,
	},
	UPDATE_PRODUCT_VARIATION: {
		KEY: 'UPDATE_PRODUCT_VARIATION',
		FN: updateProductVariation,
	},
	GET_LOW_STOCK_PRODUCTS: {
		KEY: 'GET_LOW_STOCK_PRODUCTS',
		FN: getLowStockProducts,
	},
};
