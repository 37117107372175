import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter } from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import { Button } from 'components/ui/button';
import { useToast } from 'components/ui/use-toast';

export function DemoEmailDialog({ open, onEmailVerified, onClose }) {
	const [email, setEmail] = useState('');
	const [code, setCode] = useState('');
	const [step, setStep] = useState('email');
	const [isLoading, setIsLoading] = useState(false);
	const { toast } = useToast();

	const handleSubmitEmail = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		try {
			const response = await fetch(`https://storeui.net/wp-json/storeui/v1/demo-email`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email }),
			});
			
			if (!response.ok) {
				throw new Error('Failed to send verification code');
			}
			
			const responseData = await response.json();

			setStep('code');
			toast({ title: 'Verification code sent', description: 'Please check your email for the code.' });
		} catch (error) {
			toast({ 
				title: 'Error', 
				description: error.message || 'An unexpected error occurred', 
				variant: 'destructive' 
			});
		} finally {
			setIsLoading(false);
		}
	};

	const handleVerifyCode = async (e) => {
		e.preventDefault();
		setIsLoading(true);
		try {
			const response = await fetch('https://storeui.net/wp-json/storeui/v1/verify-code', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ email, code }),
			});

			if (!response.ok) {
				const errorData = await response.json();
				throw new Error(errorData.message || 'Invalid verification code');
			}

			const responseData = await response.json();

			localStorage.setItem('demoEmailVerified', 'true');

			if (typeof onEmailVerified === 'function') {
				onEmailVerified(email);
			}

			toast({
				title: "Verification Successful",
				description: "Your email has been verified. You can now access the demo.",
				duration: 5000,
				variant: "default",
				className: "bg-white",
			});

			if (typeof onClose === 'function') {
				onClose();
			}

		} catch (error) {
			toast({ 
				title: 'Verification Failed', 
				description: error.message, 
				variant: 'destructive',
				duration: 5000,
				className: "bg-white",
			});
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<Dialog open={open} onOpenChange={onClose} modal={true}>
			<DialogContent className='sm:max-w-[425px]' hideCloseButton={true}>
				<DialogHeader>
					<DialogTitle>Welcome to the Demo</DialogTitle>
					<DialogDescription>
						{step === 'email' 
							? 'Please provide your email to access the demo. A verification code will be sent to your email.'
							: 'Enter the verification code sent to your email.'}
					</DialogDescription>
				</DialogHeader>
				<form onSubmit={step === 'email' ? handleSubmitEmail : handleVerifyCode}>
					<div className='grid gap-4 py-4'>
						{step === 'email' ? (
							<div className='grid grid-cols-4 items-center gap-4'>
								<Label htmlFor='email' className='text-right'>Your Email</Label>
								<Input
									id='email'
									type='email'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									className='col-span-3'
									required
								/>
							</div>
						) : (
							<div className='grid grid-cols-4 items-center gap-4'>
								<Label htmlFor='code' className='text-right'>Code</Label>
								<Input
									id='code'
									type='text'
									value={code}
									onChange={(e) => setCode(e.target.value)}
									className='col-span-3'
									required
								/>
							</div>
						)}
					</div>
					<DialogFooter>
						<Button type='submit' disabled={isLoading}>
							{isLoading 
								? (step === 'email' ? 'Sending code...' : 'Verifying...') 
								: (step === 'email' ? 'Send Verification Code' : 'Verify Code')}
						</Button>
					</DialogFooter>
					<p className='text-xs text-gray-500 w-xl mt-4 text-center'>
						By providing your email, you agree to receive occasional updates and marketing emails from StoreUI.
					</p>
				</form>
			</DialogContent>
		</Dialog>
	);
}
