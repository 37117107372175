import React from "react";

const InputWrapper = ({ label, tip, description, children }) => {
  return (
    <div className="flex border-b border-b-zinc-200 gap-8 max-w-full py-8">
      <div className="flex-1 gap-2 pt-2 max-w-[400px] w-full">
        {label ? (
          <p
            className="text-zinc-900 font-semibold"
            dangerouslySetInnerHTML={{ __html: label }}
          />
        ) : null}
        {description ? (
          <p
            className="text-sm text-zinc-500"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        ) : null}
      </div>
      <div className="flex-1 gap-1 flex flex-col max-w-[400px] w-full">
        <div>{children}</div>
        {tip ? (
          <p
            className="text-zinc-400 text-sm "
            dangerouslySetInnerHTML={{ __html: tip }}
          />
        ) : null}
      </div>
    </div>
  );
};

export default InputWrapper;
