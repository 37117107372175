import React from 'react';
import { Formik, Form } from 'formik';
import {
	Sheet,
	SheetContent,
	SheetHeader,
	SheetTitle,
	SheetDescription,
} from 'components/ui/sheet';
import { Button } from 'components/ui/button';
import { ScrollArea } from 'components/ui/scroll-area';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CUSTOMER_API } from 'api/customers';
import { useToast } from 'components/ui/use-toast';
import CustomTabs from 'components/custom-tabs';
import { sections } from '../customerSchema';
import ProfileCard from './ProfileCard';
import AddUserProfile from './AddProfile';
import { ORDER_API } from 'api/orders';
import { Orders } from './Order';
import { renderNormalField } from 'components/renderNormalField';
import ReusableDialogButton from 'components/reusable-dialog-button';

const CustomerSheet = ({ isOpen, onClose, customer, page, isEdit }) => {
	const { toast } = useToast();
	const cache = useQueryClient();
	const customerId = customer?.id;
	const orderQuery = useQuery({
		queryFn: () =>
			ORDER_API.GET_ALL_ORDERS.FN({
				params: {
					customer: customerId,
					per_page: 100,
				},
			}),
		queryKey: [ORDER_API.GET_ALL_ORDERS.KEY, customer?.id, 10],
		enabled: !!customerId,
	});

	// count number of orders
	const customerOrdersCount = orderQuery.data?.length;

	// sum total spent of all orders
	const customerTotalSpent = orderQuery.data?.reduce(
		(acc, order) => acc + parseFloat(order.total),
		0
	);

	const updateCustomerMutation = useMutation({
		mutationFn: CUSTOMER_API.UPDATE_CUSTOMERS.FN,
		mutationKey: CUSTOMER_API.UPDATE_CUSTOMERS.KEY,
		onError: (error) => {
			console.log('Error updating customer: ', error);
			toast({
				title: 'Error',
				description: 'There was a problem updating the customer.',
				variant: 'destructive',
			});
		},
		onSuccess: (data) => {
			onClose();
			toast({
				title: 'Success',
				description: 'Your customer was updated successfully.',
			});
			cache.invalidateQueries({
				queryKey: [CUSTOMER_API.GET_ALL_CUSTOMERS.KEY],
			});
		},
	});

	const addCustomerMutation = useMutation({
		mutationFn: CUSTOMER_API.ADD_CUSTOMERS.FN,
		mutationKey: CUSTOMER_API.ADD_CUSTOMERS.KEY,
		onError: (error) => {
			console.log('Error Adding customer: ', error);
			toast({
				title: 'Error',
				description: 'There was a problem Adding the customer.',
				variant: 'destructive',
			});
		},
		onSuccess: (data) => {
			onClose();
			toast({
				title: 'Success',
				description: 'Your customer was Added successfully.',
			});
			cache.invalidateQueries({
				queryKey: [CUSTOMER_API.ADD_CUSTOMERS.KEY],
			});
		},
	});

	const deleteCustomerMutation = useMutation({
		mutationFn: (id) => CUSTOMER_API.DELETE_CUSTOMERS.FN({ id }),
		mutationKey: CUSTOMER_API.DELETE_CUSTOMERS.KEY,
		onSuccess: (data) => {
			console.log('Customer deleted successfully:', data);
			onClose();
			toast({
				title: 'Success',
				description: 'Your customer was deleted successfully.',
			});
			cache.invalidateQueries({
				queryKey: [CUSTOMER_API.GET_ALL_CUSTOMERS.KEY, page],
			});
		},
		onError: (error) => {
			console.log('Error deleting customer:', error);
			toast({
				title: 'Error',
				description:
					error.message ||
					'There was a problem deleting the customer.',
				variant: 'destructive',
			});
		},
	});

	const handleDeleteButtonClick = () => {
		deleteCustomerMutation.mutate(customer.id);
	};

	const handleSubmit = (values) => {
		if (isEdit) {
			updateCustomerMutation.mutate({
				data: {
					...customer,
					...values,
					billing: {
						...values.billing,
						country: values.billing.country.value,
					},
					shipping: {
						...values.shipping,
						country: values.shipping.country.value,
					},
				},
			});
		} else {
			addCustomerMutation.mutate({
				data: {
					...values,
				},
			});
		}
	};

	return (
		<Sheet open={isOpen} scrollBehavior='inside' onOpenChange={onClose}>
			<SheetContent side='right' className='min-w-[680px]'>
				<SheetHeader>
					<SheetTitle>
						{isEdit ? 'Edit Customer ' : 'Add Customer '}
					</SheetTitle>
					<SheetDescription>
						<ScrollArea className='w-full h-screen pb-16'>
							<Formik
								initialValues={
									isEdit
										? {
												...customer,
										  }
										: {
												first_name: '',
												last_name: '',
												username: '',
												email: '',
												billing: {
													first_name: '',
													last_name: '',
													company: '',
													address_1: '',
													address_2: '',
													city: '',
													postcode: '',
													country: '',
													state: '',
													email: '',
													phone: '',
												},
												shipping: {
													first_name: '',
													last_name: '',
													company: '',
													address_1: '',
													address_2: '',
													city: '',
													postcode: '',
													country: '',
													state: '',
													phone: '',
												},
										  }
								}
								onSubmit={handleSubmit}>
								{({
									errors,
									touched,
									values,
									setFieldValue,
								}) => {
									const tabs = sections.map((section, i) => ({
										value: section.legend,
										label: section.legend,
										content: (
											<div
												key={`section-${i}`}
												className='space-y-4 mt-4'>
												{section?.fields.map(
													(field, fieldIndex) => (
														<div
															key={`field-${fieldIndex}`}>
															{renderNormalField(
																field
															)}
														</div>
													)
												)}
											</div>
										),
									}));
									return (
										<Form className='flex flex-col gap-8 p-1 mr-4'>
											<div>
												{customer ? (
													<ProfileCard
														customer={customer}
														ordersCount={
															customerOrdersCount
														}
														totalSpent={
															customerTotalSpent
														}
													/>
												) : (
													<AddUserProfile
														errors={errors}
														setFieldValue={
															setFieldValue
														}
														touched={touched}
														values={values}
													/>
												)}
											</div>
											<div>
												{isEdit && (
													<Orders
														loading={
															orderQuery.isLoading
														}
														orders={orderQuery.data}
													/>
												)}
											</div>
											<CustomTabs
												tabs={tabs}
												defaultValue={
													sections[0].legend
												}
											/>
											<div className='flex justify-start gap-4 flex-row mt-4 mb-8 items-center'>
												<Button
													type='submit'
													disabled={
														updateCustomerMutation.isPending
													}>
													{updateCustomerMutation.isPending
														? 'Saving...'
														: 'Save'}
												</Button>
												<Button
													variant='outline'
													onClick={onClose}>
													Cancel
												</Button>
												{isEdit &&
													customer &&
													customer.id && (
														<ReusableDialogButton
															dialogDescription='Are you sure you want to delete this customer?'
															dialogTitle='This action cannot be undone. This will permanently delete the customer and remove it from the database.'
															onConfirm={
																handleDeleteButtonClick
															}
															triggerText='Delete'
														/>
													)}
												<span className='text-sm text-zinc-500'>
													Changes will not be saved if
													you leave this page.
												</span>
											</div>
										</Form>
									);
								}}
							</Formik>
						</ScrollArea>
					</SheetDescription>
				</SheetHeader>
			</SheetContent>
		</Sheet>
	);
};

export default CustomerSheet;
