import React, { useState, useMemo, useEffect } from 'react';
import { Button } from 'components/ui/button';
import { PlusIcon, Pencil, Trash } from 'lucide-react';
import { SaveProductButton } from './SaveProductButton';
import { useQuery } from '@tanstack/react-query';
import { ATTRIBUTE_API } from 'api/attributes';
import { PRODUCT_API } from 'api/product';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from 'components/ui/dialog';
import { Input } from 'components/ui/input';
import { Switch } from 'components/ui/switch';
import { Label } from 'components/ui/label';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from 'components/ui/select';
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import FormMultiSelect from 'components/form/form-multi-select';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'components/ui/use-toast';
import NewGlobalAttributeDialog from './NewGlobalAttributeDialog';

const Attributes = ({ values, setFieldValue }) => {
	const [attributes, setAttributes] = useState(values.attributes || []);
	const [editingAttribute, setEditingAttribute] = useState(null);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [attributeSource, setAttributeSource] = useState('existing');
	const [isNewGlobalAttributeDialogOpen, setIsNewGlobalAttributeDialogOpen] = useState(false);

	const productId = values.id;

	const { data: globalAttributes, isLoading: isLoadingGlobal } = useQuery({
		queryKey: [ATTRIBUTE_API.GET_ALL_ATTRIBUTES.KEY],
		queryFn: () =>
			ATTRIBUTE_API.GET_ALL_ATTRIBUTES.FN({ params: { per_page: 100 } }),
	});

	const { data: productAttributes, isLoading: isLoadingProduct } = useQuery({ // eslint-disable-line
		queryKey: [PRODUCT_API.GET_PRODUCT_ATTRIBUTES.KEY, productId],
		queryFn: () => PRODUCT_API.GET_PRODUCT_ATTRIBUTES.FN(productId),
		enabled: !!productId,
	});

	const { data: attributeTerms, isLoading: isLoadingTerms } = useQuery({
		queryKey: [ATTRIBUTE_API.GET_ALL_TERMS.KEY, editingAttribute?.id],
		queryFn: () =>
			ATTRIBUTE_API.GET_ALL_TERMS.FN({
				attributeId: editingAttribute?.id,
			}),
		enabled: !!editingAttribute?.id && attributeSource === 'existing',
	});

	const availableGlobalAttributes = useMemo(() => {
		if (!globalAttributes) return [];
		// Filter out attributes that are already added to the product
		return globalAttributes.filter(attr => 
			!attributes.some(existingAttr => existingAttr.id === attr.id)
		);
	}, [globalAttributes, attributes]);

	const addAttribute = () => {
		setEditingAttribute({ name: '', options: [], visible: true });
		setAttributeSource('existing');
		setIsDialogOpen(true);
	};

	const editAttribute = (attribute) => {
		setEditingAttribute(attribute);
		setAttributeSource('manual');
		setIsDialogOpen(true);
	};

	const removeAttribute = (index) => {
		const newAttributes = attributes.filter((_, i) => i !== index);
		setAttributes(newAttributes);
		setFieldValue('attributes', newAttributes);
	};

	const queryClient = useQueryClient();

	const createGlobalAttributeMutation = useMutation({ // eslint-disable-line
		mutationFn: ATTRIBUTE_API.ADD_ATTRIBUTE.FN,
		onSuccess: () => {
			queryClient.invalidateQueries([ATTRIBUTE_API.GET_ALL_ATTRIBUTES.KEY]);
			toast({
				title: 'Success',
				description: 'Global attribute created successfully.',
			});
		},
		onError: (error) => {
			toast({
				title: 'Error',
				description: 'Failed to create global attribute.',
				variant: 'destructive',
			});
		},
	});

	const saveAttribute = () => {
		if (!editingAttribute) return;

		let attributeToSave = { ...editingAttribute };

		if (attributeSource === 'existing') {
			const selectedAttribute = availableGlobalAttributes.find(
				(attr) => attr.id === editingAttribute.id
			);
			if (selectedAttribute) {
				attributeToSave = {
						...selectedAttribute,
						options: selectedOptions.map(option => option.label),
						visible: editingAttribute.visible ?? true,
						variation: editingAttribute.variation ?? false
					};
			}
		} else {
			// For manually entered attributes, use the options directly
			attributeToSave.options = editingAttribute.options || [];
		}

		console.log('attributeToSave', attributeToSave);

		const newAttributes = attributes.filter(attr => attr.id !== attributeToSave.id);
		newAttributes.push(attributeToSave);

		setAttributes(newAttributes);
		setFieldValue('attributes', newAttributes);
		setIsDialogOpen(false);
		setEditingAttribute(null);
		setSelectedOptions([]);
	};

	const updateAttributes = (attributeToSave) => { // eslint-disable-line
		const newAttributes = attributes.filter(attr => attr.id !== attributeToSave.id);
		newAttributes.push(attributeToSave);

		setAttributes(newAttributes);
		setFieldValue('attributes', newAttributes);
		setIsDialogOpen(false);
		setEditingAttribute(null);
		setSelectedOptions([]);
	};

	const addGlobalAttribute = (newAttribute) => {
		const attributeToSave = {
			id: newAttribute.id,
			name: newAttribute.name,
			options: newAttribute.options,
			visible: newAttribute.visible,
			variation: newAttribute.variation
		};
		const newAttributes = [...attributes, attributeToSave];
		setAttributes(newAttributes);
		setFieldValue('attributes', newAttributes);
	};

	useEffect(() => {
		console.log('Attributes updated:', attributes);
	}, [attributes]);

	return (
		<>
			<div className='flex flex-col gap-4 p-1 mt-4 max-w-3xl'>
					{attributes.map((attribute, index) => {
						console.log('Rendering attribute:', attribute);
						return (
							<div
								key={index}
								className='flex items-center justify-between p-4 border rounded-lg'>
								<div>
									<h3 className='font-semibold'>{attribute.name}</h3>
									<p className='text-sm text-gray-500'>
										{attribute.options.length} options: {attribute.options.join(', ')}
									</p>
								</div>
								<div className='flex space-x-2'>
									<Button
										type='button'
										variant='outline'
										size='sm'
										onClick={() => editAttribute(attribute)}>
										<Pencil className='h-4 w-4 mr-2' />
										Edit
									</Button>
									<Button
										type='button'
										variant='ghost'
										size='sm'
										onClick={() => removeAttribute(index)}>
										<Trash className='h-4 w-4 mr-2' />
										Remove
									</Button>
								</div>
							</div>
						);
					})}
					<div className="flex space-x-2">
						<Button
							onClick={addAttribute}
							className='mt-4'
							type='button'
							variant='secondary'
							disabled={isLoadingGlobal}
						>
							<PlusIcon className='mr-2 h-4 w-4' /> Add attribute
						</Button>
						<Button
							onClick={() => setIsNewGlobalAttributeDialogOpen(true)}
							className='mt-4'
							type='button'
							variant='outline'
							disabled={isLoadingGlobal}
						>
							<PlusIcon className='mr-2 h-4 w-4' /> Create new global attribute
						</Button>
					</div>
				</div>

				<Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
					<DialogContent>
						<DialogHeader>
							<DialogTitle>
								{editingAttribute?.id
									? 'Edit Attribute'
									: 'Add Attribute'}
							</DialogTitle>
						</DialogHeader>
						<div className='space-y-4'>
							<RadioGroup
								value={attributeSource}
								onValueChange={setAttributeSource}>
								<div className='flex items-center space-x-2'>
									<RadioGroupItem value='existing' id='existing' />
									<Label htmlFor='existing'>
										Use existing global attribute
									</Label>
								</div>
								<div className='flex items-center space-x-2'>
									<RadioGroupItem value='manual' id='manual' />
									<Label htmlFor='manual'>
										Add product specific attribute manually
									</Label>
								</div>
							</RadioGroup>

							{attributeSource === 'existing' ? (
								<>
									<Select
										onValueChange={(value) => {
											const selectedAttribute = availableGlobalAttributes.find(
												(attr) => attr.id.toString() === value
											);
											console.log('Selected attribute:', selectedAttribute);
											if (selectedAttribute) {
												setEditingAttribute({
													...selectedAttribute,
													options: [],
												});
												setSelectedOptions([]);
											}
										}}
										disabled={isLoadingGlobal}
									>
										<SelectTrigger>
											<SelectValue placeholder='Select an attribute' />
										</SelectTrigger>
										<SelectContent>
											{isLoadingGlobal ? (
												<SelectItem value="loading">
													Loading...
												</SelectItem>
											) : (
												availableGlobalAttributes.map((attr) => (
													<SelectItem
														key={attr.id}
														value={attr.id.toString()}
													>
														{attr.name}
													</SelectItem>
												))
											)}
										</SelectContent>
									</Select>
									{editingAttribute && (
										<FormMultiSelect
											name='attribute_terms'
											label='Terms'
											options={
												attributeTerms?.map((term) => ({
													value: term.id.toString(),
													label: term.name,
												})) || []
											}
											value={selectedOptions}
											onChange={(newSelectedOptions) => {
												console.log('Selected options:', newSelectedOptions);
												setSelectedOptions(newSelectedOptions);
											}}
											isLoading={isLoadingTerms}
										/>
									)}
								</>
								) : (
									<>
										<div>
											<Label htmlFor='attribute-name'>Name</Label>
											<Input
												id='attribute-name'
												value={editingAttribute?.name || ''}
												onChange={(e) =>
													setEditingAttribute({
														...editingAttribute,
														name: e.target.value,
													})
												}
											/>
										</div>
										<div>
											<Label>Options</Label>
											<FormMultiSelect
												name='attribute_terms'
												label='Terms'
												options={selectedOptions}
												value={selectedOptions}
												onChange={(newSelectedOptions) => {
													setSelectedOptions(newSelectedOptions);
												}}
												isCreatable={true}
											/>
										</div>
									</>
								)}

							<div className='flex items-center space-x-2'>
								<Switch
									id='attribute-visible'
									checked={editingAttribute?.visible ?? true}
									onCheckedChange={(checked) =>
										setEditingAttribute({
											...editingAttribute,
											visible: checked,
										})
									}
								/>
								<Label htmlFor='attribute-visible'>
									Visible on product page
								</Label>
							</div>

							<div className='flex items-center space-x-2'>
								<Switch
									id='used-for-variations'
									checked={editingAttribute?.variation ?? false}
									onCheckedChange={(checked) =>
										setEditingAttribute({
											...editingAttribute,
											variation: checked,
										})
									}
								/>
								<Label htmlFor='used-for-variations'>
									Used for variations
								</Label>
							</div>
							<Button onClick={saveAttribute}>Save Attribute</Button>
						</div>
					</DialogContent>
				</Dialog>

				<NewGlobalAttributeDialog
					isOpen={isNewGlobalAttributeDialogOpen}
					onClose={() => setIsNewGlobalAttributeDialogOpen(false)}
					onAttributeCreated={addGlobalAttribute}
				/>

				<SaveProductButton />
			</>
		);
};

export default Attributes;