import React, { useState, useCallback, useRef } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'components/ui/dialog';
import { Label } from 'components/ui/label';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { Checkbox } from 'components/ui/checkbox';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from 'components/ui/select';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ATTRIBUTE_API } from 'api/attributes';
import { useToast } from 'components/ui/use-toast';
import { Trash2Icon, LoaderCircle, GripVertical } from 'lucide-react';

const NewGlobalAttributeDialog = ({ isOpen, onClose, onAttributeCreated }) => {
  const [name, setName] = useState('');
  const [terms, setTerms] = useState(['']);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [enableArchives, setEnableArchives] = useState(false);
  const [defaultSortOrder, setDefaultSortOrder] = useState('custom');
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);

  const createGlobalAttributeMutation = useMutation({
    mutationFn: ATTRIBUTE_API.ADD_ATTRIBUTE.FN,
    onSuccess: (data) => {
      queryClient.invalidateQueries([ATTRIBUTE_API.GET_ALL_ATTRIBUTES.KEY]);
      toast({
        title: 'Success',
        description: 'Global attribute created successfully.',
      });
      const formattedData = {
        ...data,
        terms: terms.filter(term => term.trim() !== '').map(term => ({ name: term })),
        options: terms.filter(term => term.trim() !== ''),
        visible: true,
        variation: false,
        has_archives: enableArchives,
        order_by: defaultSortOrder
      };
      onAttributeCreated(formattedData);
      setIsSubmitting(false);
      onClose();
    },
    onError: (error) => {
      console.error("Error creating global attribute:", error);
      toast({
        title: 'Error',
        description: error.message || 'Failed to create global attribute.',
        variant: 'destructive',
      });
      setIsSubmitting(false);
    },
  });

  const handleSubmit = useCallback(() => {
    if (!name) {
      toast({
        title: 'Error',
        description: 'Attribute name is required.',
        variant: 'destructive',
      });
      return;
    }

    const filteredTerms = terms.filter(term => term.trim() !== '');
    
    if (filteredTerms.length === 0) {
      toast({
        title: 'Error',
        description: 'At least one non-empty term is required.',
        variant: 'destructive',
      });
      return;
    }

    setIsSubmitting(true);
    createGlobalAttributeMutation.mutate({
      data: {
        name,
        slug: name.toLowerCase().replace(/ /g, '-'),
        type: 'select',
        order_by: defaultSortOrder,
        has_archives: enableArchives,
        terms: filteredTerms.map((term, index) => ({ 
          name: term,
          order: index
        })),
      },
    });
  }, [name, terms, defaultSortOrder, enableArchives, createGlobalAttributeMutation, toast]);

  const addTerm = useCallback(() => {
    setTerms(prevTerms => [...prevTerms, '']);
  }, []);

  const updateTerm = useCallback((index, value) => {
    setTerms(prevTerms => {
      const newTerms = [...prevTerms];
      newTerms[index] = value;
      return newTerms;
    });
  }, []);

  const removeTerm = useCallback((index) => {
    setTerms(prevTerms => prevTerms.filter((_, i) => i !== index));
  }, []);

  const handleDragStart = (e, index) => {
    dragItem.current = index;
    e.target.style.opacity = '0.5';
  };

  const handleDragEnter = (e, index) => {
    dragOverItem.current = index;
    e.target.style.backgroundColor = '#f0f0f0';
  };

  const handleDragLeave = (e) => {
    e.target.style.backgroundColor = '';
  };

  const handleDragEnd = (e) => {
    e.target.style.opacity = '1';
    e.target.style.backgroundColor = '';
    const newTerms = [...terms];
    const draggedItemContent = newTerms[dragItem.current];
    newTerms.splice(dragItem.current, 1);
    newTerms.splice(dragOverItem.current, 0, draggedItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setTerms(newTerms);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Create New Global Attribute</DialogTitle>
        </DialogHeader>
        <div className='space-y-4'>
          <div className='space-y-2'>
            <Label htmlFor='attribute-name'>Name</Label>
            <Input
              id='attribute-name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className='space-y-2'>
            <Label>Options</Label>
            {terms.map((term, index) => (
              <div 
                key={index}
                className="flex items-center space-x-2 mt-2"
                draggable
                onDragStart={(e) => handleDragStart(e, index)}
                onDragEnter={(e) => handleDragEnter(e, index)}
                onDragLeave={handleDragLeave}
                onDragEnd={handleDragEnd}
                onDragOver={(e) => e.preventDefault()}
              >
                <div className="cursor-move">
                  <GripVertical className="h-5 w-5 text-gray-500" />
                </div>
                <Input
                  value={term}
                  onChange={(e) => updateTerm(index, e.target.value)}
                  placeholder="Enter an option..."
                />
                <Button
                  type="button"
                  variant="ghost"
                  size="icon"
                  onClick={() => removeTerm(index)}
                >
                  <Trash2Icon className="h-4 w-4" />
                </Button>
              </div>
            ))}
            <Button
              type="button"
              variant="outline"
              size="sm"
              onClick={addTerm}
              className="mt-2"
            >
              Add Option
            </Button>
          </div>
          <div className="flex items-center space-x-2">
            <Checkbox
              id="enable-archives"
              checked={enableArchives}
              onCheckedChange={setEnableArchives}
            />
            <Label htmlFor="enable-archives">Enable archives?</Label>
          </div>
          <div className='space-y-2'>
            <Label htmlFor="default-sort-order">Default sort order</Label>
            <Select value={defaultSortOrder} onValueChange={setDefaultSortOrder}>
              <SelectTrigger>
                <SelectValue placeholder="Select sort order" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="custom">Custom ordering</SelectItem>
                <SelectItem value="name">Name</SelectItem>
                <SelectItem value="name_num">Name (numeric)</SelectItem>
                <SelectItem value="id">Term ID</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="flex justify-end space-x-2">
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
            <Button 
              onClick={handleSubmit} 
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  <LoaderCircle className="mr-2 h-4 w-4 animate-spin" />
                  Creating...
                </>
              ) : (
                'Create'
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NewGlobalAttributeDialog;