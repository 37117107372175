import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SETUP_API } from 'api/setup';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogTrigger } from 'components/ui/dialog';
import { Form, Formik } from 'formik';
import React from 'react';
import { initialValuesGenerator, renderField } from './render-fields';
import { useToast } from 'components/ui/use-toast';
import { LoadingSpinner } from 'components/ui/loadingSpinner';
import ShippingZonesDialog from './ShippingZonesDialog';
import NetworkError from 'components/ui/networkError';

const Shipping = () => {
	const { toast } = useToast();
	const cache = useQueryClient();

	const updateShippingMutation = useMutation({
		mutationFn: SETUP_API.UPDATE_SHIPPING.FN,
		mutationKey: SETUP_API.UPDATE_SHIPPING.KEY,
		onError: (error) => {
			console.log('Error Updating shipping: ', error);
			toast({
				title: 'Error',
				description:
					'There was a problem updating the shipping settings.',
				variant: 'destructive',
			});
		},
		onSuccess: (data) => {
			toast({
				title: 'Success',
				description:
					'Your shipping settings were updated successfully.',
			});
			cache.invalidateQueries({
				queryKey: [SETUP_API.UPDATE_SHIPPING.KEY],
			});
		},
	});

	const handleSubmit = async (values) => {
		try {
			await updateShippingMutation.mutateAsync({
				values,
			});
		} catch (error) {
			console.log('Error updating shipping: ', error);
		}
	};
	const shippingQuery = useQuery({
		queryFn: () => SETUP_API.GET_SHIPPING.FN(),
		queryKey: [SETUP_API.GET_SHIPPING.KEY],
	});

	const apiData = shippingQuery?.data ?? [];

	// Remove duplicates: logged issue https://github.com/woocommerce/woocommerce/issues/50941
	const removeDuplicates = (data) => {
		const uniqueIds = new Set();
		return data.filter(item => {
			if (uniqueIds.has(item.id)) {
				return false;
			}
			uniqueIds.add(item.id);
			return true;
		});
	};

	if (shippingQuery.isPending) {
		return <LoadingSpinner />;
	}

	if (shippingQuery.error) {
		return 	(
			<NetworkError
				message={ shippingQuery.error }
			/>
		)
	}

	if (!shippingQuery.data) {
		return null;
	}

	// Use the removeDuplicates function here
	const uniqueApiData = removeDuplicates(apiData);

	return (
		<>
			<Formik
				initialValues={initialValuesGenerator(uniqueApiData)}
				onSubmit={handleSubmit}>
				{({ isSubmitting }) => (
					<Form className='flex flex-col gap-4 p-4'>
						{uniqueApiData.map((field) => (
							<div key={field.id}>{renderField(field)}</div>
						))}
						<div className='flex justify-end gap-2'>
							<Dialog>
								<DialogTrigger asChild>
									<Button type="button" variant="outline">
										Manage Shipping Zones
									</Button>
								</DialogTrigger>
								<DialogContent className="max-w-4xl">
									<ShippingZonesDialog />
								</DialogContent>
							</Dialog>
							<Button
								type='submit'
								className='w-32'
								disabled={isSubmitting || updateShippingMutation.isPending}>
								{isSubmitting || updateShippingMutation.isPending
									? 'Saving...'
									: 'Save'}
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</>
	);
};

export default Shipping;