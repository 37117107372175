import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { PRODUCT_API } from "api/product";
import { Card, CardContent, CardHeader, CardTitle } from "components/ui/card";
import { Button } from "components/ui/button";
import { PlusCircle } from "lucide-react";
import { toast } from "components/ui/use-toast";
import ProductVariantDialog from "./ProductVariantDialog";

const Variants = ({ productId }) => {
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const cache = useQueryClient();
  const queryClient = useQueryClient();

  const { data, isLoading, error } = useQuery({
    queryKey: [PRODUCT_API.GET_PRODUCT_VARIATIONS.KEY, { productId }],
    queryFn: PRODUCT_API.GET_PRODUCT_VARIATIONS.FN,
  });

  const addVariationMutation = useMutation({
    mutationFn: PRODUCT_API.ADD_PRODUCT_VARIATION.FN,
    onSuccess: () => {
      queryClient.invalidateQueries([
        PRODUCT_API.GET_PRODUCT_VARIATIONS.KEY,
        { productId },
      ]);
      toast({
        title: "Success",
        description: "New variation added successfully",
      });
      cache.invalidateQueries([
        PRODUCT_API.GET_PRODUCT_VARIATIONS.KEY,
        { id: productId },
      ]);
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: `Failed to add new variation: ${error.message}`,
        variant: "destructive",
      });
    },
  });

  const handleAddVariant = () => {
    addVariationMutation.mutate({
      data: { sku: "" },
      productId,
    });
  };

  const handleVariantClick = (variant) => {
    setSelectedVariant(variant);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setSelectedVariant(null);
  };

  if (isLoading)
    return <div className="text-center py-4">Loading variations...</div>;
  if (error)
    return (
      <div className="text-center py-4 text-red-500">
        Error loading variations: {error.message}
      </div>
    );

  return (
    <>
      <Card className="w-full">
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Product Variations</CardTitle>
          <Button
            variant="outline"
            size="sm"
            onClick={handleAddVariant}
            disabled={addVariationMutation.isLoading}
          >
            <PlusCircle className="mr-2 h-4 w-4" />
            {addVariationMutation.isPending ? "Adding..." : "Add new variation"}
          </Button>
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {data?.map((variant) => (
              <Card
                key={variant.id}
                className="overflow-hidden cursor-pointer hover:shadow-lg transition-shadow duration-300"
                onClick={() => handleVariantClick(variant)}
              >
                <img
                  src={variant.image?.src || "/api/placeholder/300/200"}
                  alt={`Variant ${variant.id}`}
                  className="w-full h-48 object-cover"
                />
                <CardContent className="p-4">
                  <h3 className="text-lg font-semibold mb-2">#{variant.id}</h3>
                  <p className="text-sm text-gray-600 mb-1">
                    SKU: {variant.sku}
                  </p>
                  <p className="text-sm font-medium">Price: ${variant.price}</p>
                </CardContent>
              </Card>
            ))}
          </div>
        </CardContent>
      </Card>
      {selectedVariant && (
        <ProductVariantDialog
          isOpen={isDialogOpen}
          onClose={handleCloseDialog}
          variant={selectedVariant}
        />
      )}
    </>
  );
};

export default Variants;
