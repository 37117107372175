import * as React from "react";
import { Field } from "formik";
import { Textarea } from "./ui/textarea";

const ReusableTextarea = ({ label, placeholder, name }) => {
  return (
    <Field name={name}>
      {({ field, meta }) => {
        return (
          <div>
            {label && <label>{label}</label>}
            <Textarea {...field} placeholder={placeholder} />
            {meta.touched && meta.error ? (
              <div className="text-red-500 text-sm mt-1">{meta.error}</div>
            ) : null}
          </div>
        );
      }}
    </Field>
  );
};
export default ReusableTextarea;
