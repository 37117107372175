import { useState, useEffect } from 'react';
import { PlusIcon, Pencil1Icon, TrashIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Label } from 'components/ui/label';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from 'components/ui/table';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from 'components/ui/dialog';
import { ScrollArea } from 'components/ui/scroll-area';
import { axiosClient } from 'api/axios';
import { useToast } from 'components/ui/use-toast';
import { Switch } from 'components/ui/switch';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';

const TooltipIcon = ({ content }) => (
    <TooltipProvider>
        <Tooltip>
            <TooltipTrigger asChild>
                <InfoCircledIcon className="h-4 w-4 ml-1 text-gray-500" />
            </TooltipTrigger>
            <TooltipContent>
                <p>{content}</p>
            </TooltipContent>
        </Tooltip>
    </TooltipProvider>
);

export default function TaxDialog() {
    const [taxRates, setTaxRates] = useState([]);
    const [currentTaxRate, setCurrentTaxRate] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const { toast } = useToast();

    useEffect(() => {
		fetchTaxRates();
	}, []); // eslint-disable-line

	const fetchTaxRates = async () => {
        try {
            const response = await axiosClient.get('/taxes');
            setTaxRates(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching tax rates:', error);
            setIsLoading(false);
            toast({
                title: "Error",
                description: "Failed to fetch tax rates.",
                variant: "destructive",
            });
        }
    };

    const handleAddTaxRate = () => {
        setCurrentTaxRate({
            country: '',
            state: '',
            postcode: '',
            city: '',
            rate: '',
            name: '',
            priority: 1,
            compound: false,
            shipping: false,
        });
        setIsEditing(true);
    };

    const handleEditTaxRate = (taxRate) => {
        setCurrentTaxRate(taxRate);
        setIsEditing(true);
    };

    const handleDeleteTaxRate = (taxRate) => {
        setCurrentTaxRate(taxRate);
        setIsDeleting(true);
    };

    const handleSaveTaxRate = async (e) => {
        e.preventDefault();
        if (currentTaxRate) {
            setIsSaving(true);
            try {
                if (currentTaxRate.id) {
                    await axiosClient.put(`/taxes/${currentTaxRate.id}`, currentTaxRate);
                } else {
                    await axiosClient.post('/taxes', currentTaxRate);
                }
                await fetchTaxRates();
                setIsEditing(false);
                setCurrentTaxRate(null);
                toast({
                    title: "Success",
                    description: "Tax rate saved successfully.",
                });
            } catch (error) {
                console.error('Error saving tax rate:', error);
                toast({
                    title: "Error",
                    description: `Failed to save tax rate. ${error.response?.data?.message || error.message}`,
                    variant: "destructive",
                });
            } finally {
                setIsSaving(false);
            }
        }
    };

    const handleConfirmDelete = async () => {
        if (currentTaxRate) {
            try {
                await axiosClient.delete(`/taxes/${currentTaxRate.id}`, {
                    params: { force: true }
                });
                await fetchTaxRates();
                setIsDeleting(false);
                setCurrentTaxRate(null);
                toast({
                    title: "Success",
                    description: `Tax rate "${currentTaxRate.name}" has been deleted.`,
                });
            } catch (error) {
                console.error('Error deleting tax rate:', error);
                toast({
                    title: "Error",
                    description: `Failed to delete tax rate. ${error.response?.data?.message || error.message}`,
                    variant: "destructive",
                });
            }
        }
    };

    if (isLoading) {
        return <div>Loading tax rates...</div>;
    }

    return (
        <div className='p-4'>
            <h2 className='text-2xl font-bold mb-4'>Tax Rate Management</h2>
            {!isEditing && (
                <>
                    <Button onClick={handleAddTaxRate} className='mb-4'>
                        <PlusIcon className='mr-2 h-4 w-4' /> Add Tax Rate
                    </Button>

                    <ScrollArea className='h-[400px] rounded-md border'>
                        <Table>
                            <TableHeader className='bg-gray-50'>
                                <TableRow>
                                    <TableHead className='pl-4'>Name</TableHead>
                                    <TableHead>Country</TableHead>
                                    <TableHead>State</TableHead>
                                    <TableHead>Rate</TableHead>
                                    <TableHead className='text-right pr-4'>Actions</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {taxRates.map((taxRate) => (
                                    <TableRow key={taxRate.id}>
                                        <TableCell className='font-medium'>{taxRate.name}</TableCell>
                                        <TableCell>{taxRate.country}</TableCell>
                                        <TableCell>{taxRate.state}</TableCell>
                                        <TableCell>{taxRate.rate}%</TableCell>
                                        <TableCell className='text-right'>
                                            <Button
                                                variant='ghost'
                                                size='icon'
                                                onClick={() => handleEditTaxRate(taxRate)}
                                            >
                                                <Pencil1Icon className='h-4 w-4' />
                                            </Button>
                                            <Button
                                                variant='ghost'
                                                size='icon'
                                                onClick={() => handleDeleteTaxRate(taxRate)}
                                            >
                                                <TrashIcon className='h-4 w-4' />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </ScrollArea>
                </>
            )}
            {isEditing && currentTaxRate && (
                <form onSubmit={handleSaveTaxRate} className='space-y-4'>
                    <h2 className='text-xl font-semibold mb-4'>
                        {currentTaxRate.id ? 'Edit Tax Rate' : 'Add Tax Rate'}
                    </h2>
                    <div className='grid grid-cols-2 gap-4'>
                        <div className='space-y-2'>
                            <Label htmlFor='name' className="flex items-center">
                                Name
                                <TooltipIcon content="Enter a name for this tax rate." />
                            </Label>
                            <Input
                                id='name'
                                value={currentTaxRate.name}
                                onChange={(e) => setCurrentTaxRate({ ...currentTaxRate, name: e.target.value })}
                                required
                            />
                        </div>
                        <div className='space-y-2'>
                            <Label htmlFor='rate' className="flex items-center">
                                Rate %
                                <TooltipIcon content="Enter a tax rate (percentage) to 4 decimal places." />
                            </Label>
                            <Input
                                id='rate'
                                type='number'
                                step='0.0001'
                                value={currentTaxRate.rate}
                                onChange={(e) => setCurrentTaxRate({ ...currentTaxRate, rate: e.target.value })}
                                required
                            />
                        </div>
                        <div className='space-y-2'>
                            <Label htmlFor='country' className="flex items-center">
                                Country
                                <TooltipIcon content="A 2 digit country code, e.g. US. Leave blank to apply to all." />
                            </Label>
                            <Input
                                id='country'
                                value={currentTaxRate.country}
                                onChange={(e) => setCurrentTaxRate({ ...currentTaxRate, country: e.target.value })}
                            />
                        </div>
                        <div className='space-y-2'>
                            <Label htmlFor='state' className="flex items-center">
                                State
                                <TooltipIcon content="A 2 digit state code, e.g. AL. Leave blank to apply to all." />
                            </Label>
                            <Input
                                id='state'
                                value={currentTaxRate.state}
                                onChange={(e) => setCurrentTaxRate({ ...currentTaxRate, state: e.target.value })}
                            />
                        </div>
                        <div className='space-y-2'>
                            <Label htmlFor='postcode' className="flex items-center">
                                Postcode / ZIP
                                <TooltipIcon content="Postcode for this rule. Semi-colon (;) separate multiple values. Leave blank to apply to all areas. Wildcards (*) and ranges for numeric postcodes (e.g. 12345...12350) can also be used." />
                            </Label>
                            <Input
                                id='postcode'
                                value={currentTaxRate.postcode}
                                onChange={(e) => setCurrentTaxRate({ ...currentTaxRate, postcode: e.target.value })}
                            />
                        </div>
                        <div className='space-y-2'>
                            <Label htmlFor='city' className="flex items-center">
                                City
                                <TooltipIcon content="Cities for this rule. Semi-colon (;) separate multiple values. Leave blank to apply to all cities." />
                            </Label>
                            <Input
                                id='city'
                                value={currentTaxRate.city}
                                onChange={(e) => setCurrentTaxRate({ ...currentTaxRate, city: e.target.value })}
                            />
                        </div>
                        <div className='space-y-2'>
                            <Label htmlFor='priority' className="flex items-center">
                                Priority
                                <TooltipIcon content="Choose a priority for this tax rate. Only 1 matching rate per priority will be used. To define multiple tax rates for a single area you need to specify a different priority per rate." />
                            </Label>
                            <Input
                                id='priority'
                                type='number'
                                value={currentTaxRate.priority}
                                onChange={(e) => setCurrentTaxRate({ ...currentTaxRate, priority: parseInt(e.target.value) })}
                                required
                            />
                        </div>
                    </div>
                    <div className='flex items-center space-x-2'>
                        <Switch
                            id='compound'
                            checked={currentTaxRate.compound}
                            onCheckedChange={(checked) => setCurrentTaxRate({ ...currentTaxRate, compound: checked })}
                        />
                        <Label htmlFor='compound' className="flex items-center">
                            Compound
                            <TooltipIcon content="Choose whether or not this is a compound rate. Compound tax rates are applied on top of other tax rates." />
                        </Label>
                    </div>
                    <div className='flex items-center space-x-2'>
                        <Switch
                            id='shipping'
                            checked={currentTaxRate.shipping}
                            onCheckedChange={(checked) => setCurrentTaxRate({ ...currentTaxRate, shipping: checked })}
                        />
                        <Label htmlFor='shipping' className="flex items-center">
                            Apply to shipping
                            <TooltipIcon content="Choose whether or not this tax rate also gets applied to shipping." />
                        </Label>
                    </div>
                    <div className='flex justify-end space-x-2'>
                        <Button
                            type='button'
                            variant='outline'
                            onClick={() => {
                                setIsEditing(false);
                                setCurrentTaxRate(null);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button type='submit' disabled={isSaving}>
                            {isSaving ? 'Saving...' : 'Save Tax Rate'}
                        </Button>
                    </div>
                </form>
            )}

            {/* Delete Confirmation Dialog */}
            <Dialog open={isDeleting} onOpenChange={setIsDeleting}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>Delete Tax Rate</DialogTitle>
                    </DialogHeader>
                    <p>Are you sure you want to delete the tax rate "{currentTaxRate?.name}"?</p>
                    <DialogFooter>
                        <Button variant="outline" type='button' onClick={() => setIsDeleting(false)}>Cancel</Button>
                        <Button onClick={handleConfirmDelete}>Delete</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
        </div>
    );
}
