import React, { useState } from 'react';
import { TrendingUp, Filter } from 'lucide-react';
import {
	CartesianGrid,
	Line,
	LineChart,
	XAxis,
	ResponsiveContainer,
	Tooltip,
} from 'recharts';
import {
	Card,
	CardContent,
	CardFooter,
	CardHeader,
	CardTitle,
} from 'components/ui/card';
import { Button } from 'components/ui/button';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from 'components/ui/select';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Checkbox } from 'components/ui/checkbox';
import { Skeleton } from 'components/ui/skeleton';
import SuppressRechartsWarning from 'components/SuppressRechartsWarning';

const datasetConfig = {
	avg_items_per_order: { label: 'Average items per order', color: '#3b82f6' },
	avg_order_value: { label: 'Average order value', color: '#10b981' },
	coupons: { label: 'Coupons', color: '#f97316' },
	coupons_count: { label: 'Coupons count', color: '#facc15' },
	gross_sales: { label: 'Gross sales', color: '#22c55e' },
	net_revenue: { label: 'Net revenue', color: '#06b6d4' },
	num_items_sold: { label: 'Number of items sold', color: '#a855f7' },
	orders_count: { label: 'Orders', color: '#3b82f6' },
	refunds: { label: 'Refunds', color: '#ef4444' },
	shipping: { label: 'Shipping', color: '#f97316' },
	taxes: { label: 'Taxes', color: '#6b7280' },
	total_customers: { label: 'Total customers', color: '#8b5cf6' },
	total_sales: { label: 'Total sales', color: '#7F54B3' },
};

export function SalesPerformanceChart({
	intervals,
	loading,
	interval,
	setInterval,
}) {
	const [selectedDatasets, setSelectedDatasets] = useState(['total_sales']);

	const data =
		intervals?.map((item) => {
			const result = { date: item.date_start.split(' ')[0] };
			Object.keys(datasetConfig).forEach((key) => {
				result[key] = Math.round( item.subtotals[key], 2);
			});
			return result;
		}) || [];

	const formatXAxis = (dateString) => {
		const date = new Date(dateString);
		const day = date.getDate();
		const month = date.toLocaleString('default', { month: 'short' });

		if (interval === 'day') {
			return `${day} ${month}`;
		} else if (interval === 'week') {
			return `${day} ${month}`;
		}
	};

	return (
		<SuppressRechartsWarning>
			<Card className='w-full'>
				<CardHeader>
					<div className='flex items-center justify-between'>
						<CardTitle>Sales performance</CardTitle>
						<div className='flex items-center space-x-2'>
							<Select
								value={interval}
								onValueChange={setInterval}
								disabled={loading}>
								<SelectTrigger className='w-[100px]'>
									<SelectValue placeholder='Timeframe' />
								</SelectTrigger>
								<SelectContent>
									<SelectItem value='day'>Day</SelectItem>
									<SelectItem value='week'>Week</SelectItem>
								</SelectContent>
							</Select>
							<Popover>
								<PopoverTrigger asChild>
									<Button
										variant='outline'
										size='sm'
										disabled={loading}>
										<Filter className='h-4 w-4 mr-2' />
										Filters
									</Button>
								</PopoverTrigger>
								<PopoverContent className='w-80'>
									<div className='space-y-4'>
										<h4 className='font-medium leading-none'>
											Data sets
										</h4>
										<div className='grid grid-cols-2 gap-4'>
											{Object.entries(datasetConfig).map(
												([key, config]) => (
													<div
														key={key}
														className='flex items-center space-x-2'>
														<Checkbox
															id={key}
															checked={selectedDatasets.includes(
																key
															)}
															onCheckedChange={(
																checked
															) => {
																setSelectedDatasets(
																	(prev) =>
																		checked
																			? [
																					...prev,
																					key,
																			  ]
																			: prev.filter(
																					(
																						item
																					) =>
																					item !==
																					key
																			  )
																);
															}}
															style={{
																'--checkbox-color':
																	config.color,
																borderColor:
																	config.color,
																backgroundColor:
																	selectedDatasets.includes(
																		key
																	)
																	? config.color
																	: 'transparent',
															}}
															className='border-2 rounded-full w-4 h-4'
														/>
														<label
															htmlFor={key}
															className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
															{config.label}
														</label>
													</div>
												)
											)}
										</div>
									</div>
								</PopoverContent>
							</Popover>
						</div>
					</div>
					<div className='text-sm text-muted-foreground'>
						{loading ? (
							<Skeleton className='h-4 w-40' />
						) : (
							`${intervals[0]?.date_start.split(' ')[0]} - ${
								intervals[intervals.length - 1]?.date_end.split(
									' '
								)[0]
							}`
						)}
					</div>
				</CardHeader>
				<CardContent>
					{loading ? (
						<div>
							<Skeleton className='h-4 w-40' />
						</div>
					) : (
						<ResponsiveContainer width='100%' height={300}>
							<LineChart data={data}>
								<CartesianGrid
									vertical={false}
									strokeDasharray='3 3'
								/>
								<XAxis
									dataKey='date'
									tickLine={false}
									axisLine={false}
									tickFormatter={formatXAxis}
									tickMargin={8}
									scale="point"
									padding={{ left: 10, right: 10 }}
								/>
								<Tooltip />
								{selectedDatasets.map((key) => (
									<Line
										key={key}
										type='monotone'
										dataKey={key}
										stroke={datasetConfig[key].color}
										strokeWidth={2}
										dot={false}
										activeDot={{
											r: 6,
										}}
									/>
								))}
							</LineChart>
						</ResponsiveContainer>
					)}
				</CardContent>
				<CardFooter className='flex-col items-start gap-2 text-sm'>
					{loading ? (
						<div>
							<Skeleton className='h-4 w-40' />
							<Skeleton className='h-4 w-60' />
						</div>
					) : (
						<>
							<div className='flex gap-2 font-medium leading-none'>
								Trending up by 6.4% this month{' '}
								<TrendingUp className='h-4 w-4' />
							</div>
							<div className='leading-none text-muted-foreground'>
								Showing sales performance for the selected period
							</div>
						</>
					)}
				</CardFooter>
			</Card>
		</SuppressRechartsWarning>
	);
}
