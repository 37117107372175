import React from 'react';
import { Card, CardContent } from 'components/ui/card';
import { Avatar, AvatarFallback, AvatarImage } from 'components/ui/avatar';
import { Badge } from 'components/ui/badge';

const ProfileCard = ({ customer, totalSpent, ordersCount }) => {
	const lastSeen = new Date(customer?.date_modified).toLocaleString('en-US', {
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		hour12: false,
	});

	return (
		<div className='space-y-4'>
			<div className='flex justify-between items-start'>
				<div className='space-y-2'>
					<div className='flex items-center space-x-2'>
						<Avatar className='h-16 w-16'>
							<AvatarImage
								src={customer?.avatar_url}
								alt={`${customer?.first_name} ${customer?.last_name}`}
							/>
							<AvatarFallback>
								{customer?.first_name[0]}
							</AvatarFallback>
						</Avatar>
						<div className='space-y-1'>
							<h2 className='text-2xl font-bold text-gray-900'>
								{customer?.first_name} {customer?.last_name}
							</h2>
							<p className='text-sm text-muted-foreground'>
								{customer?.email}
							</p>
						</div>
					</div>
					<Badge variant='secondary' className='mt-2'>
						{customer?.is_paying_customer ? 'Paying customer' : 'Not paying customer'}
					</Badge>
				</div>
			</div>

			<div className='flex space-x-4'>
				<Card className='flex-1'>
					<CardContent className='pt-6'>
						<p className='text-sm text-muted-foreground'>Revenue</p>
						<p className='text-lg font-semibold'>
							{totalSpent ? `$${ Math.round(totalSpent) }` : 'N/A'}
						</p>
					</CardContent>
				</Card>
				<Card className='flex-1'>
					<CardContent className='pt-6'>
						<p className='text-sm text-muted-foreground'>Orders</p>
						<p className='text-lg font-semibold'>
							{ordersCount ? ordersCount : 'N/A'}
						</p>
					</CardContent>
				</Card>
				<Card className='flex-1'>
					<CardContent className='pt-6'>
						<p className='text-sm text-muted-foreground'>
							Last seen
						</p>
						<p className='text-lg font-semibold'>{ lastSeen }</p>
					</CardContent>
				</Card>
			</div>
		</div>
	);
};

export default ProfileCard;
