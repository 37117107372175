import React from 'react';
import FormHeading from 'components/form/form-heading';
import FormCheckbox from 'components/form/form-checkbox';
import FormSelect from 'components/form/form-select';
import FormDate from 'components/form/form-date';
import FormInput from 'components/form/form-input';
import { SelectItem } from 'components/ui/select';

const renderField = (field, errors, touched, values, setFieldValue) => {
	const { name, label, type, component: Component, options } = field;

	if (type === 'heading') {
		return <FormHeading key={name} name={name} label={label} />;
	}

	if (type === 'select') {
		return (
			<FormSelect
				key={name}
				name={name}
				label={label}
				options={options}
				value={values[name]}
				onChange={(value) => setFieldValue(name, value)}
				error={errors[name]}
				touched={touched[name]}>
				{options.map((option) => (
					<SelectItem key={option.value} value={option.value}>
						{option.label}
					</SelectItem>
				))}
			</FormSelect>
		);
	}

	if (type === 'checkbox') {
		return (
			<FormCheckbox
				key={name}
				name={name}
				label={label}
				checked={values[name]}
				onChange={(checked) => setFieldValue(name, checked)}
				error={errors[name]}
				touched={touched[name]}
			/>
		);
	}

	if (type === 'date') {
		return (
			<FormDate
				key={name}
				name={name}
				label={label}
				value={values[name]}
				onChange={(date) => setFieldValue(name, date)}
				error={errors[name]}
				touched={touched[name]}
			/>
		);
	}

	return (
		<FormInput
			key={name}
			name={name}
			label={label}
			type={type}
			component={Component}
			value={values[name]}
			onChange={(e) => setFieldValue(name, e.target.value)}
			error={errors[name]}
			touched={touched[name]}
		/>
	);
};

export default renderField;
