import React from "react";
import { useFormikContext } from "formik";
import { renderNormalField } from "components/renderNormalField";
import { SaveProductButton } from "./SaveProductButton";

const fields = [
  { name: "sku", label: "SKU", type: "text" },
  {
    name: "manage_stock",
    label: "Stock management",
    type: "checkbox",
  },
  {
    name: "stock_quantity",
    label: "Stock quantity",
    type: "number",
    min: 0,
  },
  {
    name: "backorders",
    label: "Allow backorders",
    type: "select",
    options: [
      { value: "no", label: "Do not allow" },
      { value: "notify", label: "Allow, but notify customer" },
      { value: "yes", label: "Allow" },
    ],
  },
  {
    name: "sold_individually",
    label: "Limit purchases to 1 item per order",
    type: "checkbox",
  },
  {
    name: "stock_status",
    label: "Stock status",
    type: "select",
    options: [
      { value: "instock", label: "In stock" },
      { value: "outofstock", label: "Out of stock" },
      { value: "onbackorder", label: "On backorder" },
    ],
  },
];

const Inventory = ({ loading }) => {
  const { values } = useFormikContext();

  const renderFields = fields.map((field) => {
    if (field.name === "stock_quantity" && !values.manage_stock) {
      return null;
    }
    return (
      <div key={field.name}>
        {renderNormalField(field)}
      </div>
    );
  });

  return (
    <>
      <div className="flex flex-col gap-8 p-1 m-4 max-w-3xl space-y-4">
        {renderFields}
      </div>

      <SaveProductButton loading={loading} />
    </>
  );
};

export default Inventory;
