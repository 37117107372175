import * as React from "react";
import { components } from "react-select";
import { Cross2Icon as CloseIcon } from "@radix-ui/react-icons";

export const ClearIndicator = (props) => {
  return (
    <components.ClearIndicator {...props}>
      <CloseIcon className={"h-3.5 w-3.5 opacity-50"} />
    </components.ClearIndicator>
  );
};
