import React from 'react';
import { Separator } from "components/ui/separator";

const OrderItems = ({ order }) => {
    return (
        <>
            <Separator className="my-6" />
            <div>
                <h3 className="text-lg font-semibold mb-4">Order #{order.id}</h3>
                <div className="grid grid-cols-12 gap-4 mb-4 flex-auto">
                    <div className="col-span-7 font-semibold">{order.line_items.length} item(s)</div>
                    <div className="col-span-2 font-semibold text-center">Quantity</div>
                    <div className="col-span-3 font-semibold text-right">Item price</div>
                </div>
                {order.line_items.map((item) => (
                    <div key={item.id} className="grid grid-cols-12 gap-4 items-center mb-4">
                        <div className="col-span-7 flex items-center gap-2">
                            <img src={item.image?.src || "/placeholder.svg"} alt={item.name} className="w-10 h-10 object-cover" />
                            <div>
                                <p className="font-medium">{item.name}</p>
                                <p className="text-sm text-muted-foreground">SKU: {item.sku}, ID: {item.product_id}</p>
                            </div>
                        </div>
                        <div className='col-span-2 text-center'>{item.quantity}</div>
                        <div className='col-span-3 text-right'>{order.currency_symbol}{parseFloat(item.price).toFixed(2)}</div>
                    </div>
                ))}
                <Separator className="my-4" />
                <div className="grid grid-cols-2 gap-2 text-sm">
                    <div>Total items</div>
                    <div className="text-right">{order.currency_symbol}{parseFloat(order.total).toFixed(2)}</div>
                    <div>Delivery fee</div>
                    <div className="text-right">{order.currency_symbol}{parseFloat(order.shipping_total).toFixed(2)}</div>
                    <div>Sales tax</div>
                    <div className="text-right">{order.currency_symbol}{parseFloat(order.total_tax).toFixed(2)}</div>
                </div>
                <Separator className="my-4" />
                <div className="flex justify-between items-center font-semibold">
                    <div>Order total</div>
                    <div>{order.currency_symbol}{parseFloat(order.total).toFixed(2)}</div>
                </div>
                <p className="text-sm text-muted-foreground mt-4">
                    Paid via {order.payment_method} on {new Date(order.date_paid).toLocaleDateString()}
                </p>
            </div>
        </>
    );
};

export default OrderItems;