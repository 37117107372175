import React from 'react';
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import AddressUpdateDialog from '../AddressUpdateDialog';
import { useQueryClient } from '@tanstack/react-query';
import { ORDER_API } from 'api/orders';

const AddressSection = ({ order }) => {
    const [isAddressUpdateDialogOpen, setIsAddressUpdateDialogOpen] = React.useState(false);
    const [addressType, setAddressType] = React.useState(null);
    const queryClient = useQueryClient();

    const openAddressUpdateDialog = (type) => {
        setAddressType(type);
        setIsAddressUpdateDialogOpen(true);
    };

    const handleAddressUpdate = () => {
        // Invalidate and refetch the order data
        queryClient.invalidateQueries([ORDER_API.GET_ORDER_BY_ID.KEY, { id: order.id }]);
    };

    return (
        <>
            <Separator className="my-6" />
            <div className="mb-6">
                <h3 className="text-lg font-semibold mb-2">Shipping & Billing Address</h3>
                <p className="text-sm text-muted-foreground">Transaction ID: { order.transaction_id ? order.transaction_id : 'N/A' }</p>
            </div>
            <div className="grid grid-cols-2 gap-4 mb-6">
                <div>
                    <h4 className="font-semibold mb-2">Ship to</h4>
                    <p>{order.shipping.first_name} {order.shipping.last_name}</p>
                    <p>{order.shipping.address_1}</p>
                    <p>{order.shipping.city}, {order.shipping.state} {order.shipping.postcode}</p>
                    <p>{order.shipping.country}</p>
                    <Button variant="link" className="p-0 mt-2" onClick={() => openAddressUpdateDialog('shipping')}>
                        Update shipping address
                    </Button>
                </div>
                <div>
                    <h4 className="font-semibold mb-2">Billed to</h4>
                    <p>{order.billing.first_name} {order.billing.last_name}</p>
                    <p>{order.billing.address_1}</p>
                    <p>{order.billing.city}, {order.billing.state} {order.billing.postcode}</p>
                    <p>{order.billing.country}</p>
                    <Button variant="link" className="p-0 mt-2" onClick={() => openAddressUpdateDialog('billing')}>
                        Update billing address
                    </Button>
                </div>
            </div>

            <AddressUpdateDialog 
                isOpen={isAddressUpdateDialogOpen}
                onClose={() => setIsAddressUpdateDialogOpen(false)}
                orderId={order.id}
                shippingAddress={order.shipping}
                billingAddress={order.billing}
                onUpdate={handleAddressUpdate}
                addressType={addressType}
            />
        </>
    );
};

export default AddressSection;