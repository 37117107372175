import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { renderNormalField } from 'components/renderNormalField';
import { SaveProductButton } from './SaveProductButton';
import { PRODUCT_API } from 'api/product';
import { LoadingSpinner } from 'components/ui/loadingSpinner';

const Shipping = ({ loading }) => {
	const { data: shippingClasses, isLoading } = useQuery({
		queryKey: [PRODUCT_API.GET_SHIPPING_CLASSES.KEY],
		queryFn: PRODUCT_API.GET_SHIPPING_CLASSES.FN,
		staleTime: 0,
	});

	if (isLoading) {
		return <LoadingSpinner />;
	}

	const shippingClassOptions =
		shippingClasses?.map((cls) => ({
			value: cls.id.toString(),
			label: cls.name,
		})) || [];

	const fields = [
		{
			name: 'weight',
			label: 'Weight',
			type: 'text',
		},
		{
			name: 'dimensions',
			label: 'Dimensions',
			type: 'dimensions',
		},
		{
			name: 'shipping_class',
			label: 'Shipping class',
			type: 'select',
			options: shippingClassOptions,
		},
	];

	return (
		<>
			<div>
				<div className='flex flex-col gap-8 p-1 m-4 max-w-3xl space-y-4'>
					{fields.map((field) => (
						<div key={field.name}>{renderNormalField(field)}</div>
					))}
				</div>
			</div>
			<SaveProductButton loading={loading} />
		</>
	);
};

export default Shipping;
