import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SETUP_API } from "api/setup";
import { Button } from "components/ui/button";
import { Form, Formik } from "formik";
import React from "react";
import { initialValuesGenerator, renderField } from "./render-fields";
import { useToast } from "components/ui/use-toast";
import { LoadingSpinner } from "components/ui/loadingSpinner";
import NetworkError from 'components/ui/networkError';

const Email = () => {
  const { toast } = useToast();
  const cache = useQueryClient();

  const updateEmailMutation = useMutation({
    mutationFn: SETUP_API.UPDATE_EMAIL.FN,
    mutationKey: SETUP_API.UPDATE_EMAIL.KEY,
    onError: (error) => {
      console.log("Error Updateing email: ", error);
      toast({
        title: "Error",
        description: "There was a problem updating the email settings.",
        variant: "destructive",
      });
    },
    onSuccess: (data) => {
      toast({
        title: "Success",
        description: "Your email settings were updated successfully.",
      });
      cache.invalidateQueries({
        queryKey: [SETUP_API.UPDATE_EMAIL.KEY],
      });
    },
  });

  const handleSubmit = async (values) => {
    try {
      await updateEmailMutation.mutateAsync({
        values,
      });
    } catch (error) {
      console.log("Error updateing email: ", error);
    }
  };
  const emailQuery = useQuery({
    queryFn: () => SETUP_API.GET_EMAIL.FN(),
    queryKey: [SETUP_API.GET_EMAIL.KEY],
  });

  const apiData = emailQuery.data || [];

  if (emailQuery.isPending) {
    return <LoadingSpinner />;
  }

  if (emailQuery.error) {
	return 	(
		<NetworkError
			message={ emailQuery.error }
		/>
	)
}

  if (!emailQuery.data) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValuesGenerator(apiData)}
      onSubmit={handleSubmit}
      sss
    >
      {({ isSubmitting }) => (
        <Form className="flex flex-col gap-4 p-4">
          {apiData.map((field) => (
            <div key={field.id}>{renderField(field)}</div>
          ))}
          <div className="flex justify-end">
            <Button
              type="submit"
              className="w-32"
              disabled={isSubmitting || updateEmailMutation.isPending}
              aria-disabled="true"
            >
              {isSubmitting || updateEmailMutation.isPending
                ? "Saving..."
                : "Save"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Email;
