import { Card, CardContent, CardHeader, CardTitle } from 'components/ui/card';
import { Skeleton } from 'components/ui/skeleton';

const StatsCard = ({ title, value, loading }) => (
	<Card className='hover:border-primary hover:bg-primary/5 transition-all duration-300'>
		<CardHeader className='flex flex-row items-center justify-between space-y-0 pt-3 pb-1 pl-3 pr-3'>
			<CardTitle className='text-sm font-medium text-gray-500'>{title}</CardTitle>
		</CardHeader>
		<CardContent className="p-3">
			{loading ? (
				<Skeleton className='h-8 w-24' />
			) : (
				<div className='text-xl font-bold'>{value}</div>
			)}
		</CardContent>
	</Card>
);

export function StatsOverview({ totals, loading }) {
	const formatCurrency = (value) => `€${value?.toFixed(2) ?? '0.00'}`;

	return (
		<div className='grid grid-cols-5 gap-4'>
			<StatsCard
				title='Total sales'
				value={formatCurrency(totals?.total_sales)}
				loading={loading}
			/>
			<StatsCard
				title='Net sales'
				value={formatCurrency(totals?.net_revenue)}
				loading={loading}
			/>
			<StatsCard
				title='Total orders'
				value={totals?.orders_count}
				loading={loading}
			/>
			<StatsCard
				title='Products sold'
				value={totals?.num_items_sold}
				loading={loading}
			/>
			<StatsCard
				title='Customers'
				value={totals?.total_customers}
				loading={loading}
			/>
			<StatsCard
				title='Average order value'
				value={formatCurrency(totals?.avg_order_value)}
				loading={loading}
			/>

			<StatsCard
				title='Average order items'
				value={Math.round(totals?.avg_items_per_order)}
				loading={loading}
			/>
			<StatsCard
				title='Coupons used'
				value={totals?.coupons}
				loading={loading}
			/>
			<StatsCard
				title='Refunds'
				value={formatCurrency(totals?.refunds)}
				loading={loading}
			/>
			<StatsCard
				title='Shipping'
				value={formatCurrency(totals?.shipping)}
				loading={loading}
			/>
		</div>
	);
}
