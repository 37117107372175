import React from 'react';
import { Formik, Form } from 'formik';

import {
	Sheet,
	SheetContent,
	SheetHeader,
	SheetTitle,
	SheetDescription,
} from 'components/ui/sheet';

import { Button } from 'components/ui/button';
import { ScrollArea } from 'components/ui/scroll-area';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { COUPON_API } from 'api/discounts';

import { useToast } from 'components/ui/use-toast';
import { sections } from '../discountSchema';
import CustomTabs from 'components/custom-tabs';
import { renderNormalField } from 'components/renderNormalField';
import ReusableDialogButton from 'components/reusable-dialog-button';
import { GetProducts, GetProductCategories } from '../discountSchema';

const DiscountSheet = ({ isOpen, onClose, discount, page, isEdit }) => {
	const { toast } = useToast();
	const cache = useQueryClient();

	const updateDiscountMutation = useMutation({
		mutationFn: COUPON_API.UPDATE_COUPONS.FN,
		mutationKey: COUPON_API.UPDATE_COUPONS.KEY,
		onError: (error) => {
			console.log('Error updating discount: ', error);
			toast({
				title: 'Error',
				description: 'There was a problem updating the discount.',
				variant: 'destructive',
			});
		},
		onSuccess: (data) => {
			onClose();
			toast({
				title: 'Success',
				description: 'Your discount was updated successfully.',
			});
			cache.invalidateQueries({
				queryKey: [COUPON_API.GET_ALL_COUPONS.KEY, page],
			});
		},
	});

	const addDiscountMutation = useMutation({
		mutationFn: COUPON_API.ADD_COUPONS.FN,
		mutationKey: COUPON_API.ADD_COUPONS.KEY,
		onError: (error) => {
			console.log('Error Adding discount: ', error);
			toast({
				title: 'Error',
				description: 'There was a problem adding the discount.',
				variant: 'destructive',
			});
		},
		onSuccess: (data) => {
			onClose();
			toast({
				title: 'Success',
				description: 'Your discount was Added successfully.',
			});
			cache.invalidateQueries({
				queryKey: [COUPON_API.ADD_COUPONS.KEY, page],
			});
		},
	});

	const deleteDiscountMutation = useMutation({
		mutationFn: (id) => COUPON_API.DELETE_COUPONS.FN({ id }),
		mutationKey: COUPON_API.DELETE_COUPONS.KEY,
		onSuccess: (data) => {
			console.log('Coupon deleted successfully:', data);
			onClose();
			toast({
				title: 'Success',
				description: 'Your discount was deleted successfully.',
			});

			cache.invalidateQueries({
				queryKey: [COUPON_API.GET_ALL_COUPONS.KEY, page],
			});
		},
		onError: (error) => {
			console.log('Error deleting coupon:', error);
			toast({
				title: 'Error',
				description:
					error.message ||
					'There was a problem deleting the discount.',
				variant: 'destructive',
			});
		},
	});

	const confirmDelete = () => {
		if (discount && discount.id) {
			deleteDiscountMutation.mutate(discount.id);
		} else {
			console.error('No discount id provided for deletion');
			toast({
				title: 'Error',
				description: 'Unable to delete. No discount id available.',
				variant: 'destructive',
			});
		}
	};

	const handleSubmit = async (values) => {
		const transformedValues = {
			...values,
			product_ids: Array.isArray(values.product_ids)
				? values.product_ids.map((item) =>
						typeof item === 'object' ? item.value : item
				  )
				: values.product_ids,
			excluded_product_ids: Array.isArray(values.excluded_product_ids)
				? values.excluded_product_ids.map((item) =>
						typeof item === 'object' ? item.value : item
				  )
				: values.excluded_product_ids,
			product_categories: Array.isArray(values.product_categories)
				? values.product_categories.map((item) =>
						typeof item === 'object' ? item.value : item
				  )
				: values.product_categories,
			excluded_product_categories: Array.isArray(
				values.excluded_product_categories
			)
				? values.excluded_product_categories.map((item) =>
						typeof item === 'object' ? item.value : item
				  )
				: values.excluded_product_categories,
		};

		if (isEdit) {
			try {
				await updateDiscountMutation.mutateAsync({
					id: discount.id,
					data: transformedValues,
				});
			} catch (error) {
				console.log('Error updating discount #', discount.id, error);
			}
			return;
		}

		try {
			await addDiscountMutation.mutateAsync({
				data: transformedValues,
			});
		} catch (error) {
			console.log('Error adding discount: ', error);
		}
		console.log('New coupon data:', transformedValues);
	};

	return (
		<Sheet open={isOpen} scrollBehavior='inside' onOpenChange={onClose}>
			<SheetContent side='right' className='min-w-[680px]'>
				<SheetHeader>
					<SheetTitle>
						{isEdit ? 'Edit Discount Code' : 'Add Discount Code'}
					</SheetTitle>
					<SheetDescription className='pb-4 flex justify-between'>
						<span>
							Make changes to your discount code here. Click save
							when you're done.
						</span>
					</SheetDescription>
				</SheetHeader>
				<ScrollArea className='w-full h-full pb-16'>
					<Formik
						initialValues={
							isEdit
								? {
										code: discount?.code || '',
										amount: discount?.amount || '',
										discount_type:
											discount?.discount_type || '',
										description:
											discount?.description || '',
										date_expires: discount?.date_expires
											? new Date(discount.date_expires)
											: null,
										individual_use:
											discount?.individual_use || false,
										exclude_sale_items:
											discount?.exclude_sale_items ||
											false,
										minimum_amount:
											discount?.minimum_amount || '',
										maximum_amount:
											discount?.maximum_amount || '',
										product_ids:
											discount?.product_ids || [],
										excluded_product_ids:
											discount?.excluded_product_ids ||
											[],
										product_categories:
											discount?.product_categories || [],
										excluded_product_categories:
											discount?.excluded_product_categories ||
											[],
										email_restrictions:
											discount?.email_restrictions || [],
										usage_limit: discount?.usage_limit || 0,
										usage_limit_per_user:
											discount?.usage_limit_per_user || 0,
										limit_usage_to_x_items:
											discount?.limit_usage_to_x_items ||
											0,
										free_shipping:
											discount?.free_shipping || false,
								  }
								: {
										code: '',
										amount: '',
										discount_type: '',
										description: '',
										date_expires: null,
										individual_use: false,
										exclude_sale_items: false,
										minimum_amount: '',
										maximum_amount: '',
										product_ids: [],
										excluded_product_ids: [],
										product_categories: [],
										excluded_product_categories: [],
										email_restrictions: [],
										usage_limit: 0,
										usage_limit_per_user: 0,
										limit_usage_to_x_items: 0,
										free_shipping: false,
								  }
						}
						// validationSchema={DiscountSchema}
						onSubmit={(values) => {
							const discount_type = values.discount_type.value;

							handleSubmit({
								...values,
								discount_type,
							});
						}}>
						{() => {
							const productOptions = GetProducts();
							const productCategories = GetProductCategories();
							const tabs = sections.map((section) => ({
								value: section.legend,
								label: section.legend,
								content: (
									<div className='space-y-4 mt-4'>
										{section?.fields.map((field) =>
											renderNormalField({
												...field,
												options:
													field.name === 'product_ids'
														? productOptions
														: field.name ===
														  'excluded_product_ids'
														? productOptions
														: field.name ===
														  'product_categories'
														? productCategories
														: field.name ===
														  'excluded_product_categories'
														? productCategories
														: field.options,
											})
										)}
									</div>
								),
							}));
							return (
								<Form className='flex flex-col gap-8 p-1 mr-4'>
									<CustomTabs
										tabs={tabs}
										defaultValue={sections[0].legend}
									/>
									<div className='flex justify-start gap-4 flex-row mt-4 mb-8 items-center'>
										<Button
											type='submit'
											disabled={
												updateDiscountMutation.isPending
											}>
											{updateDiscountMutation.isPending
												? 'Saving...'
												: 'Save'}
										</Button>
										<Button
											variant='outline'
											onClick={onClose}>
											Cancel
										</Button>
										{isEdit && discount && discount.id && (
											<ReusableDialogButton
												triggerText='Delete'
												dialogTitle='Are you sure you want to delete this discount?'
												dialogDescription='	This action cannot be undone. This will permanently
						delete the coupon and remove it from the database.'
												onConfirm={confirmDelete}
											/>
										)}
										<span className='text-sm text-zinc-500'>
											Changes will not be saved if you
											leave this page.
										</span>
									</div>
								</Form>
							);
						}}
					</Formik>
				</ScrollArea>
			</SheetContent>
		</Sheet>
	);
};

export default DiscountSheet;
