import React from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "components/ui/dialog";
import { Button } from "components/ui/button";
import { Form, Formik } from "formik";
import { renderNormalField } from "components/renderNormalField";
import { useMutation } from "@tanstack/react-query";
import { ORDER_API } from "api/orders";
import { toast } from "components/ui/use-toast";

const RefundModal = ({ isOpen, onClose, orderId }) => {
  const createRefund = useMutation({
    mutationFn: ORDER_API.CREATE_REFUND.FN,
    mutationKey: ORDER_API.CREATE_REFUND.KEY,
    onSuccess: () => {
      toast({
        title: "Refund Successful",
        description: "The refund has been processed successfully.",
        variant: "success",
      });
      onClose();
    },
    onError: (error) => {
      toast({
        title: "Refund Failed",
        description:
          error.message || "An error occurred while processing the refund.",
        variant: "destructive",
      });
    },
  });

  const refundFields = [
    {
      name: "restock_refunded_items",
      label: "Restock refunded items",
      type: "checkbox",
    },
    {
      name: "amount",
      label: "Refund amount (EUR)",
      type: "number",
    },
    {
      name: "reason",
      label: "Reason",
      type: "text",
    },
  ];

  const initialValues = {
    amount: "10.00",
    restock_refunded_items: false,
    reason: "Customer request",
    api_refund: true,
  };

  const onRefund = (values) => {
    createRefund.mutate({ orderId, refundData: values });
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[560px]">
        <DialogHeader>
          <DialogTitle>Refund</DialogTitle>
        </DialogHeader>
        <Formik initialValues={initialValues} onSubmit={onRefund}>
          <Form className="space-y-4">
            {refundFields.map((field) => renderNormalField(field))}
            <div className="flex justify-end space-x-2 pt-4">
              <Button type="button" variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={createRefund.isPending}
              >
                {createRefund.isPending ? "Processing..." : "Refund amount"}
              </Button>
            </div>
          </Form>
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default RefundModal;
