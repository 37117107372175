import React, { useState } from 'react';
import { Button } from "components/ui/button";
import { Separator } from "components/ui/separator";
import ReusableDialogButton from 'components/reusable-dialog-button';
import RefundModal from '../order-summary/RefundModal';

const OrderActions = ({ order, orderId, isEdit, deleteMutate, deleteLoading }) => {
    const [isRefundModalOpen, setIsRefundModalOpen] = useState(false);

    return (
        <>
            <Separator className="my-6" />
            <div className="flex justify-between items-center">
                <Button variant="outline" type="button" onClick={() => setIsRefundModalOpen(true)}>
                    Refund
                </Button>
                {isEdit && order && order.id && (
                    <ReusableDialogButton
                        triggerText='Delete order'
                        onConfirm={() => deleteMutate({ id: order.id })}
                        dialogTitle='Delete Order'
                        dialogDescription='Are you sure you want to delete this order?'
                        cancelText='Cancel'
                        className='text-red-500 hover:text-red-600'
                        confirmText='Confirm'
                        isLoading={deleteLoading}
                    />
                )}
            </div>
            <RefundModal
                orderId={orderId}
                isOpen={isRefundModalOpen}
                onClose={() => setIsRefundModalOpen(false)}
            />
        </>
    );
};

export default OrderActions;