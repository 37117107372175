import React from 'react';
import { Formik, Form } from 'formik';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
} from 'components/ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'components/ui/tabs';
import { Button } from 'components/ui/button';
import { renderNormalField } from 'components/renderNormalField';
import { cn } from "lib/utils";

const ProductVariantDialog = ({ isOpen, onClose, variant }) => {
	const initialValues = {
		sku: variant.sku,
		downloadable: variant.downloadable,
		virtual: variant.virtual,
		manageStock: variant.manage_stock,
		price: variant.price,
		regularPrice: variant.regular_price,
		salePrice: variant.sale_price,
		description: variant.description,
		stockQuantity: variant.stock_quantity,
		allowBackorders: variant.backorders,
		lowStockAmount: variant.low_stock_amount,
		weight: variant.weight,
		length: variant.dimensions.length,
		width: variant.dimensions.width,
		height: variant.dimensions.height,
		downloadLimit: variant.download_limit,
		downloadExpiry: variant.download_expiry,
		files: variant.downloads || [], // Add this line
	};

	const handleSubmit = (values, { setSubmitting }) => {
		// Handle form submission
		console.log(values);
		setSubmitting(false);
		onClose();
	};

	const formFields = {
		details: [
			{ name: 'sku', label: 'SKU', type: 'text' },
			{ name: 'downloadable', label: 'Downloadable', type: 'checkbox' },
			{ name: 'virtual', label: 'Virtual', type: 'checkbox' },
			{ name: 'manageStock', label: 'Manage stock', type: 'checkbox' },
			{ name: 'price', label: 'Price', type: 'text' },
			{ name: 'regularPrice', label: 'Regular price', type: 'text' },
			{ name: 'salePrice', label: 'Sale price', type: 'text' },
			{ name: 'description', label: 'Description', type: 'textarea' },
		],
		stock: [
			{ name: 'stockQuantity', label: 'Stock quantity', type: 'number' },
			{
				name: 'allowBackorders',
				label: 'Allow back-orders?',
				type: 'select',
				options: [
					{ value: 'no', label: 'Do not allow' },
					{ value: 'notify', label: 'Allow, but notify customer' },
					{ value: 'yes', label: 'Allow' },
				],
			},
			{
				name: 'lowStockAmount',
				label: 'Low stock threshold',
				type: 'number',
			},
		],
		dimensions: [
			{ name: 'weight', label: 'Weight', type: 'text' },
			{ name: 'length', label: 'Length', type: 'text' },
			{ name: 'width', label: 'Width', type: 'text' },
			{ name: 'height', label: 'Height', type: 'text' },
		],
		downloads: [
			{ name: 'files', label: 'Downloadable files', type: 'file-array' },
			{ name: 'downloadLimit', label: 'Download limit', type: 'number' },
			{
				name: 'downloadExpiry',
				label: 'Download expiry (days)',
				type: 'number',
			},
		],
	};

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className={cn("min-w-[500px] sm:max-w-[600px]")}>
				<DialogHeader>
					<DialogTitle>Variation #{variant.id}</DialogTitle>
				</DialogHeader>
				<div className='flex items-center space-x-2'>
					<div
						className={`h-2 w-2 rounded-full ${
							variant.stock_status === 'instock'
								? 'bg-green-500'
								: 'bg-red-500'
						}`}
					/>
					<span
						className={`text-sm ${
							variant.stock_status === 'instock'
								? 'text-green-500'
								: 'text-red-500'
						}`}>
						{variant.stock_status === 'instock'
							? 'In stock'
							: 'Out of stock'}
						{variant.stock_quantity !== null &&
							` (${variant.stock_quantity})`}
					</span>
				</div>
				<Formik initialValues={initialValues} onSubmit={handleSubmit}>
					{({ isSubmitting, setFieldValue, values }) => (
						<Form>
							<Tabs defaultValue='details'>
								<TabsList className='grid w-full grid-cols-4'>
									<TabsTrigger value='details'>
										Details
									</TabsTrigger>
									{values.manageStock && (
										<TabsTrigger value='stock'>
											Stock
										</TabsTrigger>
									)}
									{!values.virtual && (
										<TabsTrigger value='dimensions'>
											Shipping
										</TabsTrigger>
									)}
									{values.downloadable && (
										<TabsTrigger value='downloads'>
											Downloads
										</TabsTrigger>
									)}
								</TabsList>
								<TabsContent value='details'>
									<div className='space-y-4'>
										{formFields.details.map((field) =>
											renderNormalField(
												field,
												setFieldValue
											)
										)}
									</div>
								</TabsContent>
								{values.manageStock && (
									<TabsContent value='stock'>
										<div className='space-y-4'>
											{formFields.stock.map((field) =>
												renderNormalField(
													field,
													setFieldValue
												)
											)}
										</div>
									</TabsContent>
								)}
								{!values.virtual && (
									<TabsContent value='dimensions'>
										<div className='space-y-4'>
											{formFields.dimensions.map(
												(field) =>
													renderNormalField(
														field,
														setFieldValue
													)
											)}
										</div>
									</TabsContent>
								)}
								{values.downloadable && (
									<TabsContent value='downloads'>
										<div className='space-y-4'>
											{formFields.downloads.map((field) =>
												renderNormalField(
													field,
													setFieldValue
												)
											)}
										</div>
									</TabsContent>
								)}
							</Tabs>
							<Button
								type='submit'
								className='mt-4'
								disabled={isSubmitting}>
								{isSubmitting ? 'Updating...' : 'Update variation'}
							</Button>
						</Form>
					)}
				</Formik>
			</DialogContent>
		</Dialog>
	);
};

export default ProductVariantDialog;