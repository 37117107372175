import React from 'react';
import { useFormikContext } from 'formik';
import FormInput from './form/form-input';
import FormMultiSelect from './form/form-multi-select';
import FormTabs from './form/form-tabs';
import FormSelect from './form/form-select';
import FormDate from './form/form-date';
import FormTextarea from './form/form-textarea';
import { WYSIWYGEditor } from './form/form-editor';
import { FormDateRangePicker } from './form/form-date-range-picker';
import { CardTitle } from './ui/card';
import { Field } from 'formik';
import { Label } from './ui/label';
import FormDimentions from './form/form-dimentions';
import FormFileUploadArray from './form/form-file-array-upload';

const FormField = ({ field }) => {
	const { setFieldValue } = useFormikContext();
	const { name, label, type, options, value } = field;

	switch (type) {
		case 'heading':
			return <CardTitle className='text-md'>{label}</CardTitle>;
		case 'text':
			return <FormInput type={type} name={name} label={label} />;
		case 'select':
			return <FormSelect options={options} name={name} label={label} />;
		case 'checkbox':
			return <FormTabs name={name} label={label} />;
		case 'multiselect':
			return <FormMultiSelect options={options} name={name} label={label} />;
		case 'color':
			return <FormInput type={type} name={name} label={label} />;

		case 'textarea':
			return <FormTextarea name={name} label={label} />;

		case 'editor':
			return (
				<WYSIWYGEditor
					name={name}
					label={label}
					value={value}
					onChange={(newValue) => setFieldValue(name, newValue)}
				/>
			);

		case 'email':
			return <FormInput type={type} name={name} label={label} />;

		case 'date':
			return <FormDate name={name} label={label} />;

		case 'date-range':
			return <FormDateRangePicker name={name} label={label} />;

		case 'number':
			return <FormInput type={type} name={name} label={label} />;
		case 'dimensions':
			return <FormDimentions type={type} name={name} label={label} />;
		case 'file-array':
			return <FormFileUploadArray name={name} label={label} />;

		case 'custom':
			return (
				<Field name={name}>
					{({ field: formikField }) => (
						<div className='flex flex-col gap-2'>
							<Label htmlFor={name}>{label}</Label>
							<field.component
								id={name}
								{...formikField}
								onChange={(value) => {
									console.log('value', value);
									setFieldValue(name, value);
								}}
							/>
						</div>
					)}
				</Field>
			);

		default:
			return null;
	}
};

export const renderNormalField = (field) => {
	return <FormField field={field} />;
};
