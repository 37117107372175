import React from 'react';
import {
	useReactTable,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	getFilteredRowModel,
	flexRender,
} from '@tanstack/react-table';
import { Checkbox } from 'components/ui/checkbox';
import {
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableHeader,
	TableRow,
} from 'components/ui/table';
import { Button } from 'components/ui/button';
import NetworkError from './ui/networkError';

const GenericTable = ({
	data,
	columns,
	isLoading,
	isError,
	error,
	sorting,
	setSorting,
	columnFilters,
	setColumnFilters,
	columnVisibility,
	setColumnVisibility,
	rowSelection,
	setRowSelection,
	page,
	setPage,
	per_page,
	total,
	handleEditClick,
	selectedRowIds,
	setSelectedRowIds,
	searchValue,
	setSearchValue,
}) => {

	const finalColumns = [
		{
			id: 'select',
			header: ({ table }) => (
				<div className='flex items-center justify-center   h-4'>
					<Checkbox
						checked={
							selectedRowIds.length ===
							table.getRowModel().rows.length
						}
						onCheckedChange={(checked) => {
							if (checked) {
								setSelectedRowIds(
									table
										.getRowModel()
										.rows.map((row) => row.id)
								);
							} else {
								setSelectedRowIds([]);
							}
						}}
						aria-label='Select all'
						className='cursor-pointer border-zinc-300 hover:border-zinc-500 border-opacity-1'
					/>
				</div>
			),
			cell: ({ row }) => (
				<div className='flex items-center justify-center h-4'>
					<Checkbox
						checked={selectedRowIds.includes(row.id)}
						onCheckedChange={(checked) => {
							if (checked) {
								setSelectedRowIds((prev) => [...prev, row.id]);
							} else {
								setSelectedRowIds((prev) =>
									prev.filter((id) => id !== row.id)
								);
							}
						}}
						aria-label='Select row'
						className='cursor-pointer border-zinc-300 hover:border-zinc-500 border-opacity-1 h-4 w-4'
					/>
				</div>
			),
			enableSorting: false,
			enableHiding: false,
		},
		...columns,
	];

	const table = useReactTable({
		data,
		columns: finalColumns,
		onSortingChange: setSorting,
		onColumnFiltersChange: setColumnFilters,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		onColumnVisibilityChange: setColumnVisibility,
		onRowSelectionChange: setRowSelection,
		state: {
			sorting,
			columnFilters,
			columnVisibility,
			rowSelection,
			pagination: { pageIndex: page - 1, pageSize: per_page },
		},
		manualPagination: true,
		pageCount: Math.ceil(total / per_page),
		getRowId: (row) => row.id.toString(),
	});

	if (isError) {
		return (
			<NetworkError
				message={ error.message }
			/>
		);
	}

	const SkeletonRow = () => (
		<TableRow className='border-none'>
			{finalColumns.map((column, index) => (
				<TableCell key={index} className='whitespace-nowrap'>
					<div className='h-4 bg-gray-200 rounded animate-pulse'></div>
				</TableCell>
			))}
		</TableRow>
	);

	return (
		<div className='p-4'>
			<div className='w-full rounded-lg border border-zinc-200'>
				<div className='overflow-hidden'>
					<Table>
						<TableHeader>
							{table.getHeaderGroups().map((headerGroup) => (
								<TableRow key={headerGroup.id}>
									{headerGroup.headers.map((header) => (
										<TableHead
											key={header.id}
											className='text-sm font-normal pl-4 text-zinc-40 sticky top-0 z-10'>
											{header.isPlaceholder
												? null
												: flexRender(
														header.column.columnDef
															.header,
														header.getContext()
												  )}
										</TableHead>
									))}
								</TableRow>
							))}
						</TableHeader>
						<TableBody>
							{isLoading ? (
								Array.from({ length: 15 }).map((_, index) => (
									<SkeletonRow key={index} />
								))
							) : table.getRowModel().rows?.length ? (
								table.getRowModel().rows.map((row) => (
									<TableRow
										key={row.id}
										data-state={
											row.getIsSelected() && 'selected'
										}
										className={`border-none cursor-pointer hover:bg-gray-100  ${
											selectedRowIds.includes(row.id)
												? 'bg-gray-100'
												: ''
										}`}>
										{row
											.getVisibleCells()
											.map((cell, i) => (
												<TableCell
													key={cell.id}
													onClick={
														i === 0
															? undefined
															: () =>
																	handleEditClick(
																		row.original
																	)
													}
													className='whitespace-nowrap'>
													{flexRender(
														cell.column.columnDef
															.cell,
														cell.getContext()
													)}
												</TableCell>
											))}
									</TableRow>
								))
							) : (
								<TableRow>
									<TableCell
										colSpan={finalColumns.length}
										className='h-24 text-center'>
										No results.
									</TableCell>
								</TableRow>
							)}
						</TableBody>
					</Table>
				</div>
			</div>

			<div className='flex items-center justify-end space-x-2 py-4 px-4'>
				<div className='flex-1 text-sm text-muted-foreground'>
					{selectedRowIds.length} of {total} row(s) selected.
				</div>
				<div className='space-x-2'>
					<Button
						variant='outline'
						size='sm'
						onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
						className={`${page === 1 ? 'cursor-not-allowed' : ''}`}
						disabled={page === 1}>
						Previous
					</Button>
					<Button
						variant='outline'
						size='sm'
						onClick={() => setPage((prev) => prev + 1)}
						disabled={total < per_page}>
						Next
					</Button>
				</div>
			</div>
		</div>
	);
};

export default GenericTable;
