import React from "react";
import { Label } from "components/ui/label";
import FormInput from "./form-input";

const FormDimentions = ({ name, label }) => {
  return (
    <div>
      <div className="flex flex-row gap-3 items-center">
        {label && <Label className="w-[300px]">{label}</Label>}
        <div className="flex gap-4 items-start justify-start w-full flex-col lg:flex-row lg:items-center">
          <FormInput name={`${name}.length`} type="number" placeholder="Length" className="w-32" />
          <FormInput name={`${name}.width`} type="number" placeholder="Width" className="w-32" />
          <FormInput name={`${name}.height`} type="number" placeholder="Height" className="w-32" />
		  <span className="text-sm text-muted-foreground">L x W x H</span>
        </div>
      </div>
    </div>
  );
};

export default FormDimentions;
