import React from "react";
import { Button } from "components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { ListFilter } from "lucide-react";
import { Form, Formik } from "formik";

import { CardTitle } from "components/ui/card";

import { renderNormalField } from "components/renderNormalField";
import { FormDateRangePicker } from "components/form/form-date-range-picker";

export default function FilterDropdown({ dropdownState, filters, setFilters }) {
  const filterFields = [
    {
      name: "filters",
      label: "Filters",
      type: "heading",
      component: CardTitle,
    },
    {
      name: "dateRange",
      label: "Date range",
      type: "date-range",
      component: FormDateRangePicker,
    },
    {
      name: "status",
      label: "Status",
      type: "select",
      options: [
        { value: "any", label: "All" },
        { value: "pending", label: "pending" },
        { value: "processing", label: "processing" },
        { value: "on-hold", label: "on-hold" },
        { value: "completed", label: "completed" },
        { value: "cancelled", label: "cancelled" },
        { value: "refunded", label: "refunded" },
        { value: "failed", label: "failed" },
        { value: "trash", label: "trash" },
      ],
    },
    {
      name: "table settings",
      label: "Table settings",
      type: "heading",
      component: CardTitle,
    },
    {
      name: "per_page",
      label: "Per page",
      type: "number",
    },
    {
      name: "order",
      label: "Order",
      type: "select",
      options: [
        { value: "asc", label: "Ascending" },
        { value: "desc", label: "Descending" },
      ],
    },
    {
      name: "orderBy",
      label: "Order by",
      type: "select",

      options: [
        { value: "date", label: "Date" },
        { value: "modified", label: "Modified" },
        { value: "id", label: "ID" },
        { value: "include", label: "Include" },
        { value: "title", label: "Title" },
        { value: "slug", label: "Slug" },
      ],
    },
  ];

  return (
    <DropdownMenu
      defaultOpen={false}
      open={dropdownState.isOpen}
      onOpenChange={dropdownState.onToggle}
    >
      <DropdownMenuTrigger asChild>
        <Button
          variant="outline"
          size="sm"
          className="px-4 h-10 text-sm font-medium gap-1"
        >
          <ListFilter className="h-3.5 w-3.5" />
          <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
            Filter
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        className="w-80 max-h-[80vh] overflow-y-auto"
      >
        <Formik
          initialValues={{
            dateRange: filters.dateRange,
            status: filters.status,
            per_page: filters.per_page,
            order: filters.order,
            orderBy: filters.orderBy,
          }}
          enableReinitialize
          onSubmit={(values) => {
            const { dateRange, status, per_page, order, orderBy } =
              values || {};
            setFilters({
              dateRange: dateRange
                ? {
                    from: dateRange.from || undefined,
                    to: dateRange.to || undefined,
                  }
                : undefined,
              status: status?.value || undefined,
              per_page: per_page || undefined,
              order: order?.value || undefined,
              orderBy: orderBy?.value || undefined,
            });
            dropdownState.onToggle();
          }}
        >
          <Form className="flex flex-col gap-4 p-1 mr-4">
            {filterFields.map((field) => (
              <div key={field.name}>{renderNormalField(field)}</div>
            ))}
            <Button type="submit" className="mt-4">
              Save
            </Button>
          </Form>
        </Formik>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
