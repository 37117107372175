import * as React from "react";
import { components } from "react-select";
import { Cross2Icon as CloseIcon } from "@radix-ui/react-icons";

export const MultiValueRemove = (props) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon className={"h-3 w-3 opacity-50"} />
    </components.MultiValueRemove>
  );
};
