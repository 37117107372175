import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Bell } from 'lucide-react';
import { Button } from 'components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { Badge } from 'components/ui/badge';
import { Skeleton } from 'components/ui/skeleton';
import { ORDER_API } from 'api/orders';
import { PRODUCT_API } from 'api/product';
import { format } from 'date-fns';

export function Notifications() {
	const [isOpen, setIsOpen] = useState(false);

	const { data: recentOrders, isLoading: isOrdersLoading } = useQuery({
		queryKey: [ORDER_API.GET_RECENT_ORDERS.KEY],
		queryFn: () =>
			ORDER_API.GET_RECENT_ORDERS.FN({ params: { per_page: 5 } }),
	});

	const { data: lowStockProducts, isLoading: isLowStockLoading } = useQuery({
		queryKey: [PRODUCT_API.GET_LOW_STOCK_PRODUCTS.KEY],
		queryFn: () => PRODUCT_API.GET_LOW_STOCK_PRODUCTS.FN(),
	});

	const isLoading = isOrdersLoading || isLowStockLoading;

	const notifications = [
		...(recentOrders?.map((order) => ({
			id: `order-${order.id}`,
			title: `New order #${order.number}`,
			message: `${order.billing?.first_name || 'A customer'} ${
				order.billing?.last_name || ''
			} placed an order for ${order.currency} ${order.total}`,
			date: new Date(order.date_created),
		})) || []),
		...(lowStockProducts?.map((product) => ({
			id: `low-stock-${product.id}`,
			title: `Low stock alert: ${product.name}`,
			message: `The product "${product.name}" is running low on stock (${product.stock_quantity} left)`,
			date: new Date(),
		})) || []),
	]
		.sort((a, b) => b.date - a.date)
		.slice(0, 5);

	return (
		<DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
			<DropdownMenuTrigger asChild>
				<Button
					variant='ghost'
					size='icon'
					className='relative'
					aria-label='Notifications'>
					<Bell className='h-5 w-5' />
					{notifications && notifications.length > 0 && (
						<Badge className='absolute -top-1 -right-1 h-5 w-5 rounded-full p-0 text-[10px] flex items-center justify-center'>
							{notifications.length}
						</Badge>
					)}
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent align='end' className='w-80'>
				{isLoading ? (
					<DropdownMenuItem>
						<Skeleton className='h-5 w-full' />
					</DropdownMenuItem>
				) : notifications && notifications.length > 0 ? (
					notifications.map((notification) => (
						<DropdownMenuItem key={notification.id}>
							<div className='flex flex-col'>
								<span className='font-medium'>
									{notification.title}
								</span>
								<span className='text-sm text-muted-foreground'>
									{notification.message}
								</span>
								<span className='text-xs text-muted-foreground'>
									{format(
										notification.date,
										'MMM d, yyyy HH:mm'
									)}
								</span>
							</div>
						</DropdownMenuItem>
					))
				) : (
					<DropdownMenuItem>No new notifications</DropdownMenuItem>
				)}
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
