import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "components/ui/dialog";
import { Button } from "components/ui/button";
import { Form, Formik } from "formik";
import { renderNormalField } from "components/renderNormalField";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ORDER_API } from "api/orders";
import { useToast } from "components/ui/use-toast";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/tabs";

const AddressUpdateDialog = ({ isOpen, onClose, orderId, shippingAddress, billingAddress, onUpdate, addressType }) => {
    const { toast } = useToast();
    const queryClient = useQueryClient();
    const [activeTab, setActiveTab] = useState(addressType);

    useEffect(() => {
        setActiveTab(addressType);
    }, [addressType]);

    const updateOrderMutation = useMutation({
        mutationFn: ORDER_API.UPDATE_ORDER_BY_ID.FN,
        onSuccess: () => {
            queryClient.invalidateQueries([ORDER_API.GET_ORDER_BY_ID.KEY, { id: orderId }]);
            toast({
                title: 'Success',
                description: `${activeTab.charAt(0).toUpperCase() + activeTab.slice(1)} address updated successfully`,
            });
            onUpdate();
            onClose();
        },
        onError: (error) => {
            toast({
                title: 'Error',
                description: `Failed to update ${activeTab} address`,
                variant: 'destructive',
            });
        },
    });

    const handleSubmit = (values) => {
        const updateData = {
            [activeTab]: values,
        };
        updateOrderMutation.mutate({ id: orderId, updateData });
    };

    const fields = [
        { name: 'first_name', label: 'First Name', type: 'text' },
        { name: 'last_name', label: 'Last Name', type: 'text' },
        { name: 'company', label: 'Company', type: 'text' },
        { name: 'address_1', label: 'Address Line 1', type: 'text' },
        { name: 'address_2', label: 'Address Line 2', type: 'text' },
        { name: 'city', label: 'City', type: 'text' },
        { name: 'state', label: 'State', type: 'text' },
        { name: 'postcode', label: 'Postcode', type: 'text' },
        { name: 'country', label: 'Country', type: 'text' },
    ];

    return (
        <Dialog open={isOpen} onOpenChange={onClose}>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Update Address</DialogTitle>
                </DialogHeader>
                <Tabs value={activeTab} onValueChange={setActiveTab}>
                    <TabsList className="grid w-full grid-cols-2">
                        <TabsTrigger value="shipping">Shipping</TabsTrigger>
                        <TabsTrigger value="billing">Billing</TabsTrigger>
                    </TabsList>
                    <TabsContent value="shipping">
                        <AddressForm
                            initialValues={shippingAddress}
                            fields={fields}
                            onSubmit={handleSubmit}
                            isSubmitting={updateOrderMutation.isPending}
                            onCancel={onClose}
                        />
                    </TabsContent>
                    <TabsContent value="billing">
                        <AddressForm
                            initialValues={billingAddress}
                            fields={fields}
                            onSubmit={handleSubmit}
                            isSubmitting={updateOrderMutation.isPending}
                            onCancel={onClose}
                        />
                    </TabsContent>
                </Tabs>
            </DialogContent>
        </Dialog>
    );
};

const AddressForm = ({ initialValues, fields, onSubmit, isSubmitting, onCancel }) => (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
        <Form className="space-y-4">
            {fields.map((field) => renderNormalField(field))}
            <div className="flex justify-end space-x-2">
                <Button type="button" variant="outline" onClick={onCancel}>Cancel</Button>
                <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Updating...' : 'Update Address'}
                </Button>
            </div>
        </Form>
    </Formik>
);

export default AddressUpdateDialog;