import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useDisclosure } from 'hooks/useDisclosure';
import { useSearchParams, useNavigate } from 'react-router-dom';
import SearchAndActions from './_ui/searchAction';
import OrderTable from './_ui/orderTable';
import { ORDER_API } from 'api/orders';
import OrderSheet from './_ui/orderSheet/orderSheet';

export default function Orders() {
	const [sorting, setSorting] = useState([]);
	const [columnFilters, setColumnFilters] = useState([]);
	const [columnVisibility, setColumnVisibility] = useState({});
	const [rowSelection, setRowSelection] = useState({});
	const [loading] = useState(false);
	const [page, setPage] = useState(1);
	const [isSheetOpen, setIsSheetOpen] = useState(false);
	const [selectedOrderId, setSelectedOrderId] = useState(null);
	const [search, setSearch] = useState('');

	const [selectedRowIds, setSelectedRowIds] = useState([]);

	const newOrderState = useDisclosure();
	const dropdownState = useDisclosure();

	const [searchParams, setSearchParams] = useSearchParams(); // eslint-disable-line
	const navigate = useNavigate();

	const [filters, setFilters] = useState({
		orderBy: undefined,
		order: undefined,
		per_page: 10,
		dateRange: {
			from: undefined,
			to: undefined,
		},
		status: 'any',
	});

	const cache = useQueryClient();
	const dateRange = filters.dateRange?.from
		? `${filters.dateRange.from.toISOString()},${filters.dateRange.to.toISOString()}`
		: undefined;

	const ordersQuery = useQuery({
		queryFn: () =>
			ORDER_API.GET_ALL_ORDERS.FN({
				params: { page, search, ...filters, dateRange },
			}),
		queryKey: [ORDER_API.GET_ALL_ORDERS.KEY, page, search, filters],
	});

	useEffect(() => {
		const isUpdate = searchParams.get('isUpdate') === 'true';
		const orderId = searchParams.get('orderId');

		// console.log({
		// 	isUpdate,
		// 	orderId,
		// });
		if (isUpdate && orderId) {
			setSelectedOrderId(orderId);
			setIsSheetOpen(true);
		}
	}, [searchParams]);

	const createOrderMutation = useMutation({
		mutationFn: ORDER_API.ADD_ORDER.FN,
		mutationKey: ORDER_API.ADD_ORDER.KEY,
		onError: (error) => {
			console.log('Error Adding order: ', error);
		},
		onSuccess: (data) => {
			console.log('Order added successfully:', data);
			cache.invalidateQueries({
				queryKey: [ORDER_API.GET_ALL_ORDERS.KEY, page, search, filters],
			});
			setIsSheetOpen(true);
			newOrderState.onClose();
			setSelectedOrderId(data.id);
			navigate(`/orders?isUpdate=true&orderId=${data.id}`);
		},
	});

	const deleteOrderMutation = useMutation({ // eslint-disable-line
		mutationFn: ORDER_API.DELETE_ORDER.FN,
		mutationKey: ORDER_API.DELETE_ORDER.KEY,
		onSuccess: (data) => {
			handleSheetClose();
			console.log('Order deleted successfully:', data);
			cache.invalidateQueries({
				queryKey: [ORDER_API.GET_ALL_ORDERS.KEY, page, search, filters],
			});
		},
		onError: (error) => {
			console.log('Error deleting order:', error);
		},
	});

	const handleEditClick = async (order) => {
		setSelectedOrderId(order.id);
		setIsSheetOpen(true);
		navigate(`/orders?isUpdate=true&orderId=${order.id}`);

		// Set the order data in the query cache
		cache.setQueryData([ORDER_API.GET_ORDER_BY_ID.KEY, order.id], order);
	};

	const handleSheetClose = () => {
		setIsSheetOpen(false);
		setSelectedOrderId(null);
		navigate('/orders');
	};

	return (
		<div className='w-full'>
			<SearchAndActions
				loading={loading}
				selectedRowIds={selectedRowIds}
				setSearch={setSearch}
				dropdownState={dropdownState}
				filters={filters}
				setFilters={setFilters}
				mutate={createOrderMutation.mutate}
				isLoading={createOrderMutation.isPending}
			/>

			<OrderTable
				data={ordersQuery.data || []}
				isLoading={ordersQuery.isLoading}
				isError={ordersQuery.isError}
				error={ordersQuery.error}
				statusFilter={filters.status}
				sorting={sorting}
				setSorting={setSorting}
				columnFilters={columnFilters}
				setColumnFilters={setColumnFilters}
				columnVisibility={columnVisibility}
				setColumnVisibility={setColumnVisibility}
				rowSelection={rowSelection}
				setRowSelection={setRowSelection}
				page={page}
				per_page={filters.per_page}
				handleEditClick={handleEditClick}
				setSelectedRowIds={setSelectedRowIds}
				selectedRowIds={selectedRowIds}
				setPage={setPage}
				total={ordersQuery.data?.length || 0}
			/>

			<OrderSheet
				isOpen={isSheetOpen}
				onClose={handleSheetClose}
				orderId={selectedOrderId}
				isEdit={true}
				initialOrderData={ordersQuery.data?.find(
					(o) => o.id === selectedOrderId
				)}
			/>
		</div>
	);
}
