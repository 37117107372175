import React, { useState, useEffect } from 'react';
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogDescription,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { TAG_API } from 'api/tags';
import { useToast } from 'components/ui/use-toast';
import { ScrollArea } from 'components/ui/scroll-area';
import { Trash2, Edit } from 'lucide-react';
import TagDialog from './TagDialog';
import {
	AlertDialog,
	AlertDialogContent,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogCancel,
	AlertDialogAction,
} from 'components/ui/alert-dialog';

const ManageTagsDialog = ({ isOpen, onClose }) => {
	const { toast } = useToast();
	const queryClient = useQueryClient();
	const [tags, setTags] = useState([]);
	const [editingTag, setEditingTag] = useState(null);
	const [tagToDelete, setTagToDelete] = useState(null);

	const { data, isLoading } = useQuery({
		queryKey: [TAG_API.GET_ALL_TAGS.KEY],
		queryFn: () => TAG_API.GET_ALL_TAGS.FN({ params: { per_page: 100 } }),
		staleTime: 0,
	});

	useEffect(() => {
		if (data) {
			setTags(data);
		}
	}, [data]);

	const deleteTagMutation = useMutation({
		mutationFn: TAG_API.DELETE_TAGS.FN,
		onSuccess: () => {
			queryClient.invalidateQueries([TAG_API.GET_ALL_TAGS.KEY]);
			toast({
				title: 'Success',
				description: 'Tag deleted successfully.',
			});
		},
		onError: (error) => {
			toast({
				title: 'Error',
				description: 'Failed to delete tag.',
				variant: 'destructive',
			});
		},
	});

	const handleDelete = (tag) => {
		setTagToDelete(tag);
	};

	const confirmDelete = () => {
		if (tagToDelete) {
			deleteTagMutation.mutate({ id: tagToDelete.id });
			setTagToDelete(null);
		}
	};

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogContent className='max-w-xl'>
				<DialogHeader>
					<DialogTitle>Manage Tags</DialogTitle>
					<DialogDescription>
						Tags are used to group products. You can edit or delete
						them.
					</DialogDescription>
				</DialogHeader>
				{isLoading ? (
					<div>Loading tags...</div>
				) : (
					<ScrollArea className='h-[400px] pr-4'>
						<ul>
							{tags.map((tag) => (
								<li key={tag.id} className='mb-2'>
									<div className='flex items-center space-x-2 p-2 rounded-md hover:bg-gray-100'>
										<span className='flex-grow'>
											{tag.name}
											<span className='text-sm text-gray-500 ml-2'>
												({tag.count} products)
											</span>
										</span>
										<Button
											onClick={() => setEditingTag(tag)}
											variant='ghost'
											size='icon'
											className='h-8 w-8'>
											<Edit className='h-4 w-4' />
										</Button>
										<Button
											onClick={() => handleDelete(tag)}
											variant='ghost'
											size='icon'
											className='h-8 w-8'>
											<Trash2 className='h-4 w-4' />
										</Button>
									</div>
								</li>
							))}
						</ul>
					</ScrollArea>
				)}
			</DialogContent>
			{editingTag && (
				<TagDialog
					isOpen={!!editingTag}
					onClose={() => setEditingTag(null)}
					tag={editingTag}
					allTags={tags}
				/>
			)}
			{tagToDelete && (
				<AlertDialog
					open={!!tagToDelete}
					onOpenChange={() => setTagToDelete(null)}>
					<AlertDialogContent>
						<AlertDialogHeader>
							<AlertDialogTitle>
								Confirm Tag Deletion?
							</AlertDialogTitle>
							<AlertDialogDescription>
								Deleting the "{tagToDelete.name}" tag cannot be
								undone. This will permanently delete the tag and
								remove it from all associated products.
							</AlertDialogDescription>
						</AlertDialogHeader>
						<AlertDialogFooter>
							<AlertDialogCancel
								onClick={() => setTagToDelete(null)}>
								Cancel
							</AlertDialogCancel>
							<AlertDialogAction onClick={confirmDelete}>
								Delete
							</AlertDialogAction>
						</AlertDialogFooter>
					</AlertDialogContent>
				</AlertDialog>
			)}
		</Dialog>
	);
};

export default ManageTagsDialog;
