import FormHeading from "components/form/form-heading";
import Radio from "components/form/radio";
import ReusableTextarea from "components/reusable-textarea";

const { default: FormInput } = require("components/form/form-input");
const { default: InputWrapper } = require("./InputWrapper");
const { Input } = require("components/ui/input");
const { Field } = require("formik");
const { default: SimpleSelect } = require("components/simple-select");
const { ReusableTabs } = require("components/reusable-tabs");

export const renderField = (field) => {
  const { id, label, description, type, options, tip } = field;

  switch (type) {
    case "heading":
      return <FormHeading key={id} name={id} label={label} />;

    case "text":
      return (
        <InputWrapper description={description} label={label} tip={tip}>
          <FormInput type={type} name={id} className="w-full" />
        </InputWrapper>
      );

    case "select":
      let selectOptions = options
        ? Object.entries(options).map(([value, label]) => ({ value, label }))
        : [];

      function convertToReactSelectOptions(jsonObject) {
        return Object.entries(jsonObject).map(([key, value]) => ({
          value: key,
          label: decodeHTMLEntities(value),
        }));
      }

      // Helper function to decode HTML entities
      function decodeHTMLEntities(text) {
        const textArea = document.createElement("textarea");
        textArea.innerHTML = text;
        return textArea.value;
      }

      selectOptions = convertToReactSelectOptions(options);
      const decodedOptions = Object.fromEntries(
        Object.entries(options).map(([key, value]) => [
          key,
          decodeHTMLEntities(value),
        ])
      );

      return (
        <InputWrapper description={description} label={label} tip={tip}>
          <Field name={id}>
            {({ field, form }) => {
              return (
                <SimpleSelect
                  options={selectOptions}
                  value={
                    field.value
                      ? {
                          value: field.value,
                          label:
                            id === "woocommerce_currency"
                              ? decodedOptions[field.value]
                              : options[field.value],
                        }
                      : null
                  }
                  onChange={(selectedOption) => {
                    form.setFieldValue(
                      id,
                      selectedOption ? selectedOption.value : null
                    );
                  }}
                  isMulti={false}
                />
              );
            }}
          </Field>
        </InputWrapper>
      );
    case "checkbox":
      return (
        <InputWrapper description={description} label={label} tip={tip}>
          <Field name={id}>
            {({ field, form }) => (
              <ReusableTabs
                value={field.value}
                onChange={(value) => form.setFieldValue(id, value)}
              />
            )}
          </Field>
        </InputWrapper>
      );
    case "multiselect":
      const multiSelectOptions = options
        ? Object.entries(options).map(([value, label]) => ({ value, label }))
        : [];

      return (
        <InputWrapper description={description} label={label} tip={tip}>
          <Field name={id}>
            {({ field, form }) => {
              return (
                <SimpleSelect
                  options={multiSelectOptions}
                  value={
                    field.value.length > 0
                      ? field.value.map((item) => {
                          return {
                            value: item,
                            label: options[item],
                          };
                        })
                      : []
                  }
                  onChange={(selectedOptions) => {
                    form.setFieldValue(
                      id,
                      selectedOptions.map((option) => option.value)
                    );
                  }}
                  isMulti={true}
                />
              );
            }}
          </Field>
        </InputWrapper>
      );

    case "color":
      return (
        <InputWrapper description={description} label={label} tip={tip}>
          <Field name={id}>
            {({ field }) => {
              return (
                <div className="flex gap-2">
                  <input type="color" {...field} />
                  <p>{field.value ? ` ${field.value}` : ""}</p>
                </div>
              );
            }}
          </Field>
        </InputWrapper>
      );

    case "radio":
      return (
        <InputWrapper description={description} label={label} tip={tip}>
          <Field name={id}>
            {({ field, form }) => {
              return (
                <Radio
                  options={options}
                  value={field.value}
                  onChange={(value) => form.setFieldValue(id, value)}
                />
              );
            }}
          </Field>
        </InputWrapper>
      );

    case "textarea":
      return (
        <InputWrapper description={description} label={label} tip={tip}>
          <ReusableTextarea
            name={id}
            label={label}
            placeholder={field.placeholder || ""}
          />
        </InputWrapper>
      );

    case "email":
      return (
        <InputWrapper description={description} label={label} tip={tip}>
          <FormInput type="email" name={id} component={Input} />
        </InputWrapper>
      );

    case "number":
      return (
        <InputWrapper description={description} label={label} tip={tip}>
          <FormInput type="number" name={id} component={Input} />
        </InputWrapper>
      );
    default:
      return null;
  }
};

export const initialValuesGenerator = (apiData) => {
  if (!apiData) return {};

  return Object.assign(
    {},
    ...apiData?.map((field) => {
      return {
        [field.id]: field.value,
      };
    })
  );
};
