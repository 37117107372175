import React from "react";
import { Field } from "formik";
import { Label } from "components/ui/label";
import { Input } from "components/ui/input";

const FormInput = ({ name, label, type, placeholder = '', className = '' }) => (
  <Field name={name} id={name}>
    {({ field, meta }) => {
      return (
        <div>
          <div className="flex flex-row gap-3 items-center">
            {label && <Label className="w-[300px]">{label}</Label>}
            <Input {...field} type={type} placeholder={placeholder} className={className} />
          </div>
          {meta.touched && meta.error ? (
            <div className="text-red-500 text-sm">{meta.error}</div>
          ) : null}
        </div>
      );
    }}
  </Field>
);

export default FormInput;
