import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SETUP_API } from 'api/setup';
import { Button } from 'components/ui/button';
import { Form, Formik } from 'formik';
import React from 'react';
import { initialValuesGenerator, renderField } from './render-fields';
import { useToast } from 'components/ui/use-toast';
import { LoadingSpinner } from 'components/ui/loadingSpinner';
import NetworkError from 'components/ui/networkError';

const Product = () => {
	const { toast } = useToast();
	const cache = useQueryClient();

	const updateProductMutation = useMutation({
		mutationFn: SETUP_API.UPDATE_PRODUCT.FN,
		mutationKey: SETUP_API.UPDATE_PRODUCT.KEY,
		onError: (error) => {
			console.log('Error Updateing product: ', error);
			toast({
				title: 'Error',
				description:
					'There was a problem updating the product settings.',
				variant: 'destructive',
			});
		},
		onSuccess: (data) => {
			toast({
				title: 'Success',
				description: 'Your product settings were updated successfully.',
			});
			cache.invalidateQueries({
				queryKey: [SETUP_API.UPDATE_PRODUCT.KEY],
			});
		},
	});

	const handleSubmit = async (values) => {
		try {
			await updateProductMutation.mutateAsync({
				values,
			});
		} catch (error) {
			console.log('Error updating product: ', error);
		}
	};

	const productQuery = useQuery({
		queryFn: () => SETUP_API.GET_PRODUCT.FN(),
		queryKey: [SETUP_API.GET_PRODUCT.KEY],
	});

	const apiData = productQuery?.data ?? [];

	if (productQuery.isPending) {
		return <LoadingSpinner />;
	}

	if (productQuery.error) {
		return 	(
			<NetworkError
				message={ productQuery.error }
			/>
		)
	}

	if (!productQuery.data) {
		return null;
	}

	return (
		<Formik
			initialValues={initialValuesGenerator(apiData)}
			onSubmit={handleSubmit}>
			{({ isSubmitting }) => (
				<Form className='flex flex-col gap-4 p-4'>
					{apiData.map((field) => (
						<div key={field.id}>{renderField(field)}</div>
					))}
					<div className='flex justify-end'>
						<Button
							type='submit'
							className='w-32'
							disabled={
								isSubmitting || updateProductMutation.isPending
							}
							aria-disabled='true'>
							{isSubmitting || updateProductMutation.isPending
								? 'Saving...'
								: 'Save'}
						</Button>
					</div>
				</Form>
			)}
		</Formik>
	);
};

export default Product;
