import React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { SETUP_API } from 'api/setup';
import { Button } from 'components/ui/button';
import { Form, Formik } from 'formik';
import { useToast } from 'components/ui/use-toast';
import { LoadingSpinner } from 'components/ui/loadingSpinner';
import { RadioGroup, RadioGroupItem } from 'components/ui/radio-group';
import { Label } from 'components/ui/label';
import { Switch } from 'components/ui/switch';
import { Input } from 'components/ui/input';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from 'components/ui/tooltip';
import { BASE_URL } from 'api/axios';
import { Clipboard, CircleHelp } from 'lucide-react';
import InputWrapper from './InputWrapper';

const SiteVisibility = () => {
    const { toast } = useToast();
    const cache = useQueryClient();

    const updateSiteVisibilityMutation = useMutation({
        mutationFn: SETUP_API.UPDATE_SITE_VISIBILITY.FN,
        onSuccess: () => {
            toast({
                title: 'Success',
                description: 'Site visibility settings updated successfully.',
            });
            cache.invalidateQueries({ queryKey: [SETUP_API.GET_SITE_VISIBILITY.KEY] });
        },
        onError: (error) => {
            console.log('Error updating site visibility:', error);
            toast({
                title: 'Error',
                description: 'There was a problem updating the site visibility settings.',
                variant: 'destructive',
            });
        },
    });

    const handleSubmit = async (values) => {
        const updates = {
            woocommerce_coming_soon: values.visibility === 'coming_soon' ? 'yes' : 'no',
            woocommerce_store_pages_only: values.restrictStore ? 'yes' : 'no',
            woocommerce_private_link: values.privateLink ? 'yes' : 'no',
        };

        await updateSiteVisibilityMutation.mutateAsync(updates);
    };

    const siteVisibilityQuery = useQuery({
        queryFn: SETUP_API.GET_SITE_VISIBILITY.FN,
        queryKey: [SETUP_API.GET_SITE_VISIBILITY.KEY],
    });

    if (siteVisibilityQuery.isPending) {
        return <LoadingSpinner />;
    }

    if (!siteVisibilityQuery.data) {
        return null;
    }

    const { woocommerce_coming_soon, woocommerce_store_pages_only, woocommerce_private_link, woocommerce_share_key } = siteVisibilityQuery.data;

    const privateLinkUrl = `${BASE_URL}/cart/?woo-share=${woocommerce_share_key}`;

    return (
        <Formik
            initialValues={{
                visibility: woocommerce_coming_soon === 'yes' ? 'coming_soon' : 'live',
                restrictStore: woocommerce_store_pages_only === 'yes',
                privateLink: woocommerce_private_link === 'yes',
                privateLinkUrl: privateLinkUrl,
            }}
            onSubmit={handleSubmit}
        >
            {({ values, setFieldValue, isSubmitting }) => (
                <Form className="flex flex-col gap-4 p-4">
                    <InputWrapper label="Site Visibility" description="Manage how your site appears to visitors.">
                        <RadioGroup
                            value={values.visibility}
                            onValueChange={(value) => setFieldValue('visibility', value)}
                            className="space-y-4"
                        >
                            <div className="flex items-center justify-between space-x-2 py-2">
                                <div className="flex items-center space-x-2">
                                <RadioGroupItem value="live" id="live" />
                                    <Label htmlFor="live">Live</Label>
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger>
                                                <CircleHelp className="w-4 h-4 text-gray-500" />
                                            </TooltipTrigger>
                                            <TooltipContent>
                                                <p>Your entire site is visible to everyone.</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            </div>
                            <div className="flex items-center justify-between space-x-2">
                                <div className="flex items-center space-x-2">
                                <RadioGroupItem value="coming_soon" id="coming_soon" />
                                    <Label htmlFor="coming_soon">Coming soon</Label>
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger>
                                                <CircleHelp className="w-4 h-4 text-gray-500" />
                                            </TooltipTrigger>
                                            <TooltipContent
												className='w-64'
											>
                                                <p>Your site is hidden from visitors behind a "Coming soon" landing page until it's ready for viewing.<br /> You can customize your "Coming soon" landing page via the Editor.</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                </div>
                            </div>
                        </RadioGroup>
                    </InputWrapper>

                    {values.visibility === 'coming_soon' && (
                        <>
                            <InputWrapper label="Restrict to store pages only">
                                <div className="flex items-start justify-start gap-2 py-2">
								<Switch
                                        id="restrict-store"
                                        checked={values.restrictStore}
                                        onCheckedChange={(checked) => setFieldValue('restrictStore', checked)}
                                    />
                                    <p className="text-sm text-muted-foreground">
                                        Display a "coming soon" message on your store pages — the rest of your site will remain visible.
                                    </p>
                                </div>
                            </InputWrapper>

                            <InputWrapper label="Share your site with a private link">
							<div className="flex items-start justify-start gap-2 py-2">
							<Switch
                                        id="private-link"
                                        checked={values.privateLink}
                                        onCheckedChange={(checked) => setFieldValue('privateLink', checked)}
                                    />
                                    <p className="text-sm text-muted-foreground">
                                        Share your site with anyone using a private link.
                                    </p>
                                </div>
                            </InputWrapper>

                            {values.privateLink && (
                                <InputWrapper label="Private Link URL">
                                    <div className="flex items-center space-x-2">
                                        <Input
                                            value={values.privateLinkUrl}
                                            className="flex-grow"
                                            readOnly
                                        />
										<TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger>
												<Clipboard
													className="w-4 h-4 text-gray-500 transition-all hover:cursor-pointer hover:text-primary hover:rotate-[17deg]"
													onClick={() => {
														navigator.clipboard.writeText(values.privateLinkUrl);
														toast({
															title: 'Success',
															description: 'Private link copied to clipboard',
														});
													}}
												/>
											</TooltipTrigger>
											<TooltipContent>Copy private share link</TooltipContent>
										</Tooltip>
									</TooltipProvider>
                                    </div>
                                </InputWrapper>
                            )}
                        </>
                    )}

                    <div className="flex justify-end">
                        <Button
                            type="submit"
                            className="w-32"
                            disabled={isSubmitting || updateSiteVisibilityMutation.isPending}
                        >
                            {isSubmitting || updateSiteVisibilityMutation.isPending ? 'Saving...' : 'Save'}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default SiteVisibility;