import React from 'react';

class SuppressRechartsWarning extends React.Component {
	componentDidMount() {
		const originalError = console.error;
		console.error = (...args) => {
			if (
				typeof args[0] === 'string' &&
				args[0].includes('defaultProps will be removed')
			) {
				return;
			}
			originalError.call(console, ...args);
		};
	}

	componentWillUnmount() {
		console.error = console.error.__originalFunction || console.error;
	}

	render() {
		return this.props.children;
	}
}

export default SuppressRechartsWarning;
