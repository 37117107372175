import { Checkbox } from "components/ui/checkbox";
import React from "react";

const FormCheckbox = ({ name, label, checked, onChange, error, touched }) => (
  <div key={name} className="flex flex-row gap-3 items-center">
    <div className="flex-[1] leading-none">
      <label
        htmlFor={name}
        className="text-sm font-medium leading-none text-gray-900 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {label}
      </label>
    </div>
	<div className="flex-[2] items-start">
		<Checkbox
			id={name}
			checked={checked}
			onCheckedChange={onChange}
		/>
	</div>
    {error && touched ? <div>{error}</div> : null}
  </div>
);

export default FormCheckbox;
