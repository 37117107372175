import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
	CommandDialog,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
	CommandSeparator,
} from 'components/ui/command';
import { Plus, Search, Settings } from 'lucide-react';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import { DialogTitle } from 'components/ui/dialog';

const CommandPalette = () => {
	const [open, setOpen] = useState(false);
	const [search, setSearch] = useState('');
	const [recentSearches, setRecentSearches] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		const down = (e) => {
			if (e.key === 'k' && (e.metaKey || e.ctrlKey)) {
				e.preventDefault();
				setOpen((open) => !open);
			}
		};
		document.addEventListener('keydown', down);
		return () => document.removeEventListener('keydown', down);
	}, []);

	const runCommand = useCallback(
		(command) => {
			setOpen(false);
			if (typeof command === 'string') {
				navigate(command);
			} else if (typeof command === 'function') {
				command();
			}
			// Add to recent searches
			setRecentSearches((prev) => [command, ...prev.slice(0, 3)]);
		},
		[navigate]
	);

	const filteredItems = [
		{
			heading: 'Pages',
			items: [
				{
					name: 'Dashboard',
					shortcut: '',
					onSelect: () => runCommand('/'),
				},
				{
					name: 'Orders',
					shortcut: '',
					onSelect: () => runCommand('/orders'),
				},
				{
					name: 'Customers',
					shortcut: '',
					onSelect: () => runCommand('/customers'),
				},
				{
					name: 'Discounts',
					shortcut: '',
					onSelect: () => runCommand('/discounts'),
				},
				{
					name: 'Products',
					shortcut: '',
					onSelect: () => runCommand('/products'),
				},
				{
					name: 'Setup',
					shortcut: '',
					onSelect: () => runCommand('/setup'),
				},
			],
		},
		{
			heading: 'Actions',
			items: [
				{
					name: 'New Order',
					icon: Plus,
					onSelect: () =>
						runCommand(() => console.log('Create new order')),
				},
				{
					name: 'New Product',
					icon: Plus,
					onSelect: () => runCommand('/products/new'),
				},
				{
					name: 'Settings',
					icon: Settings,
					onSelect: () => runCommand('/settings'),
				},
			],
		},
	].map((group) => ({
		...group,
		items: group.items.filter((item) =>
			item.name.toLowerCase().includes(search.toLowerCase())
		),
	}));

	return (
		<CommandDialog open={open} onOpenChange={setOpen}>
			<VisuallyHidden>
				<DialogTitle>Command Palette</DialogTitle>
			</VisuallyHidden>
			<CommandInput
				placeholder='Type a command or search...'
				value={search}
				onValueChange={setSearch}
			/>
			<CommandList>
				<CommandEmpty>No results found.</CommandEmpty>
				{recentSearches.length > 0 && (
					<>
						<CommandGroup heading='Recent Searches'>
							{recentSearches.map((item, index) => (
								<CommandItem
									key={index}
									onSelect={() => runCommand(item)}>
									<Search className='mr-2 h-4 w-4' />
									<span>
										{typeof item === 'string'
											? item
											: item.name}
									</span>
								</CommandItem>
							))}
						</CommandGroup>
						<CommandSeparator />
					</>
				)}
				{filteredItems.map((group, i) => (
					<CommandGroup key={i} heading={group.heading}>
						{group.items.map((item) => (
							<CommandItem
								key={item.name}
								onSelect={item.onSelect}>
								{item.icon && (
									<item.icon className='mr-2 h-4 w-4' />
								)}
								<span>{item.name}</span>
								{item.shortcut && (
									<span className='ml-auto text-xs text-gray-400'>
										{item.shortcut}
									</span>
								)}
							</CommandItem>
						))}
					</CommandGroup>
				))}
			</CommandList>
		</CommandDialog>
	);
};

export default CommandPalette;
